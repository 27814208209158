export const generateTimeOptions = (
  format: number,
  initial: number,
  startFromOne?: boolean,
) => {
  const result = [];
  for (let i = format + 1; i <= initial; i++) {
    result.push(i);
  }

  if (startFromOne) {
    return [0, ...result];
  }

  return result;
};

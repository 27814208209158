export enum CurrencyEnum {
  ETH = 'ETH',
  TRX = 'TRX',
  BTC = 'BTC',
  FIAT = 'FIAT',
  ERC20 = 'USDT ERC20',
  TRC20 = 'USDT TRC20',
  USD = 'USD',
  BNB = 'BNB',
  BUSD = 'BUSD',
  DAI = 'DAI',
  USDT = 'USDT',
  USDC = 'USDC',
}
export enum POCurrencyEnum {
  ETH = 'ETH',
  TRX = 'TRX',
  BTC = 'BTC',
  FIAT = 'FIAT',
  ERC20 = 'USDT',
  TRC20 = 'USDT TRC20',
  USDC = 'USDC',
  USDC20 = 'USDC',
  BNB = 'BNB',
}

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IGroup } from 'interfaces/IGroup';
import { Filter } from 'types/Filter';
import { GroupSortField } from '../types';
import { Sort } from 'hooks';
import { adaptSort } from '../utils';

export interface UseGroupsProps {
  page?: number;
  perPage?: number;
  sort?: Sort<GroupSortField> | null;
  filter?: Filter<IGroup>;
  withoutPaging?: boolean;
}

export interface UseGroupsResult {
  isGroupsFetching: boolean;
  refetch: () => void;
  totalCount: number;
  groups: IGroup[];
}

const useGroups = ({
  sort = { field: 'updatedAt', order: 'descend' },
  filter,
  page = 1,
  perPage = 25,
  withoutPaging,
}: UseGroupsProps = {}): UseGroupsResult => {
  const adaptedSort = { sorting: adaptSort(sort?.field || 'updatedAt', sort?.order || 'descend') };

  const getGroups = async () => {
    const { data } = await axios.get(process.env.REACT_APP_AUTH_URI + '/groups', {
      params: withoutPaging ? {
        ...adaptedSort,
        filter,
      } : {
        paging: { limit: perPage, offset: (page - 1) * perPage },
        ...adaptedSort,
        filter,
      },
    });
    return data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['admin-groups'],
    queryFn: getGroups,
  });

  return {
    totalCount: data?.totalCount || 0,
    groups: data?.nodes || [],
    isGroupsFetching: isLoading,
    refetch,
  };
};

export default useGroups;

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { ITeamTransaction } from 'interfaces/ITeamTransaction';
import { Filter } from 'types/Filter';
import { TransactionsSorting } from 'pages/Processing/Transactions/types';

type TransactionsPayload = {
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset: number };
  sorting?: TransactionsSorting[];
};

export const useTransactions = (unitId: string | number, params?: TransactionsPayload) => {
  const getUnitTransactions = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/transactions/manager`, {
      params: {
        ...params,
        filter: { and: [{ unitId: { eq: unitId } }] },
      },
    });
    return data;
  };

  return useQuery<{ nodes: ITeamTransaction[], totalCount: number }>({
    queryFn: getUnitTransactions,
    queryKey: ['transactions', [unitId, params]],
    enabled: Boolean(unitId),
  });
};

import React, { ReactElement } from 'react';
import { Button, Empty } from 'antd';
import clsx, { ClassValue } from 'clsx';
import { useTranslate } from '../../hooks';
import styles from './NoDataPlaceholder.module.scss';

export interface INoDataPlaceholderProps {
  refetch?: () => void;
  emptyCls?: ClassValue,
  buttonText?: string,
  buttonCls?: ClassValue,
  wrapperCls?: ClassValue,
  emptyPlaceholder?: string;
}

const NoDataPlaceholder = ({
  refetch,
  emptyCls,
  buttonCls,
  wrapperCls,
  buttonText,
  emptyPlaceholder,
}: INoDataPlaceholderProps): ReactElement => {
  const { t } = useTranslate();

  return (
    <div className={clsx(styles.wrapper, wrapperCls)}>
      <Empty
        className={clsx(styles.empty, emptyCls)}
        description={emptyPlaceholder}
      />

      {refetch && (
        <Button
          id={styles.button}
          className={clsx(styles.button, buttonCls)}
          onClick={() => refetch()}
        >
          {buttonText || t('refresh')}
        </Button>
      )}
    </div>
  );
};

export default NoDataPlaceholder;

import { IFiatTransaction } from '../../../../interfaces/IFiatTransaction';
import { Sort } from '../../../../types/Sort';

export enum TransactionTypeEnum {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  TRANSFER = 'transfer',
  SINGLE_AGGREGATION = 'single_aggregation',
  LITTLE_AGGREGATION = 'little_aggregation',
}

export type FiatTransactionsSortField = keyof IFiatTransaction;
export type FiatTransactionsSorting = Sort<IFiatTransaction>;

import { useContext } from 'react';
import { TranslateContext } from '../contexts';
import type { ITranslateOptions, TranslateFunc, TranslateKey } from '../contexts/TranslateContext/TranslateContext';

export type UseTranslateResult = {
  t: TranslateFunc;
};

export type UseTranslateKeys = (
  TranslateKey | { key: TranslateKey, options?: ITranslateOptions }
)[];

const useTranslate = (): UseTranslateResult => {
  const { translate } = useContext(TranslateContext);

  return { t: translate };
};

export default useTranslate;

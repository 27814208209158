import { useCallback, useState } from 'react';
import axios from 'libs/axios';
import WAValidator from 'multicoin-address-validator';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';

export const useWalletValidator = (currency?: null | string, network?: null | string) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<string | undefined>();
  const { t } = useTranslate();

  const walletValidator = useCallback(async (_: UnknownType, value: string, setWalletValid?: UnknownType) => {
    if (!value) {
      return Promise.reject(t('processing.destinationAddressisRequired'));
    }
    switch (network as string) {
      case 'TRC20':
        if (WAValidator.validate(value, 'TRX')) {
          try {
            setLoading(true);
            const { data } = await axios.post(`${process.env.REACT_APP_TRON_API_URL}/wallet/getaccountresource`, {
              address: value,
              visible: true,
            }, {
              withoutAuthorizationToken: true,
            } as UnknownType);
            setLoading(false);

            if (!Object.keys(data).length) {
              setWalletValid(true);
              return setWarning(
                t('walletAddressValidate.isNotActivated', { variables: { currency: currency || '' } }),
              );
            }
            setWarning(undefined);
          } catch (e: UnknownType) {
            setWarning(undefined);
            setWalletValid(false);
            return Promise.reject(e?.message || t('somethingWrong'));
          }
          setWarning(undefined);
          setWalletValid(true);
          return Promise.resolve();
        }
        setWarning(undefined);
        return Promise.reject(
          t('walletAddressValidate.isNotValidate',
            { variables: { currency: currency || '' } },
          ),
        );
      case 'BEP20':
      case 'ERC20':
        if (!WAValidator.validate(value, 'ETH', process.env.REACT_APP_NETWORK_ID)) {
          setWalletValid(false);

          return Promise.reject(
            t('walletAddressValidate.isNotValidate',
              { variables: { currency: currency || '' } },
            ),
          );
        }
        setWarning(undefined);
        setWalletValid(true);
        return Promise.resolve();
      case 'Bitcoin':
        if (!WAValidator.validate(value, 'BTC', process.env.REACT_APP_NETWORK_ID)) {
          setWarning(undefined);
          setWalletValid(false);

          return Promise.reject(
            t('walletAddressValidate.isNotValidate',
              { variables: { currency: currency || '' } },
            ),
          );
        }
        setWarning(undefined);
        setWalletValid(true);
        return Promise.resolve();
      default:
        setWarning(undefined);
        setWalletValid(false);
        return Promise.reject(t('processing.destinationAddressisRequired'));
    }
  }, [currency, network, t]);

  return {
    isLoading,
    warning,
    walletValidator,
  };
};

import React, { PropsWithChildren, ReactElement } from 'react';
import { Space } from 'antd';
import styles from './ActionsSpaceTd.module.scss';

export const ActionsSpaceTd = ({ children }: PropsWithChildren): ReactElement => (
  <Space
    size="small"
    direction="vertical"
    className={styles.expandableTd}
  >
    {children}
  </Space>
);

import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { SocketContext } from 'contexts';

const useSocketStatus = (callback: () => void) => {
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const debouncedCallback = _.debounce(callback, 1000);

    if (socket) {
      socket.on('update_user_status', debouncedCallback);
    }
  }, [socket]); // eslint-disable-line
};

export default useSocketStatus;

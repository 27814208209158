import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';

const Balance = lazy(() => import('../pages/Processing/Balance/Balance'));
const Transactions = lazy(() => import('../pages/Processing/Transactions/Transactions'));
const PaymentOrders = lazy(() => import('../pages/Processing/PaymentOrders/PaymentOrders'));
const Resources = lazy(() => import('../pages/Processing/Resources/Resources'));

const processingRoutes: IRoute[] = [
  {
    path: `${Path.PROCESSING_BALANCE}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.PROCESSING_BALANCE_READ],
    component: () => <Balance />,
  },
  {
    path: `${Path.PROCESSING_PAYMENT_ORDERS_CURRENCY}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.PROCESSING_PAYMENT_ORDER_READ],
    component: () => <PaymentOrders />,
  },
  {
    path: `${Path.PROCESSING_TRANSACTIONS_CURRENCY}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.PROCESSING_TRANSACTION_READ],
    component: () => <Transactions />,
  },
  {
    path: `${Path.PROCESSING_RESOURCES}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.PROCESSING_RESOURCE_READ],
    component: () => <Resources />,
  },
];

export default processingRoutes;

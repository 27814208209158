import { ReactElement, useMemo } from 'react';
import { Empty, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IRiskScoreSignals, IRiskScoreValue } from 'interfaces';
import { generateColor, getHighestSignal, prepareSignals, truncateDecimals } from './utils';
import { ReactComponent as Speed } from 'assets/icons/Speeder.svg';
import styles from './RiskScore.module.scss';

type RiskScoreProps = {
  riskScore: IRiskScoreValue;
  signals?: IRiskScoreSignals;
};

export const RiskScore = ({ riskScore, signals = {} }: RiskScoreProps): ReactElement => {
  const preparedSignals = useMemo(() => prepareSignals(signals), [signals]);
  const highestSignal = useMemo(() => getHighestSignal(preparedSignals), [preparedSignals]);
  const normalizedRiskScoreValue = truncateDecimals(riskScore);
  const color = generateColor(normalizedRiskScoreValue);
  const shouldShowTooltip = riskScore !== 0;

  const tooltip = (
    <div className={styles.tooltip}>
      <div className={styles.tooltipHeader}>
        Risk score <span style={{ color }}>{normalizedRiskScoreValue}%</span>
      </div>

      <div>
        {preparedSignals.length !== 0 ? (
          <ul className={styles.list}>
            {preparedSignals.map(({ key, value }) => (
              <li
                key={key}
                className={clsx(styles.item, {
                  [styles.highest]: highestSignal.key === key || highestSignal.value === value,
                })}
              >
                <Typography.Title className={styles.label}>{key}</Typography.Title>
                <Typography.Text className={styles.value}>{value}%</Typography.Text>
              </li>
            ))}
          </ul>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );

  return (
    <Tooltip title={shouldShowTooltip && tooltip} placement="rightTop" overlayClassName={styles.overlay}>
      <div className={styles.wrapper}>
        <Speed style={{ fill: color }} />
        <span style={{ color }}>{normalizedRiskScoreValue}%</span>
      </div>
    </Tooltip>
  );
};

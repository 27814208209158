import { Dispatch, SetStateAction, useEffect } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { CheckPermissionsFunction } from '../AuthorisationContext';

type MathMyRouteArgs = {
  user: IUser | null,
  setPath: Dispatch<SetStateAction<string | null>>
  checkPermissions: CheckPermissionsFunction,
};

export const useMatchMyRoute = ({ user, setPath, checkPermissions }: MathMyRouteArgs) => {
  useEffect(() => {
    if (user) {
      const isRoot = !user.rootUnitId;
      const adminUnits = user.units;

      if (isRoot && user.groupId === 1) {
        return setPath(Path.ADMINISTRATION_BUSINESSES);
      } else if (checkPermissions([Permission.CLIENT_UNIT_CREATE])) {
        return setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user?.rootUnitId}/units`);
      } else if (adminUnits) {
        if (adminUnits.length === 1) {
          return setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user?.rootUnitId}/units/${adminUnits[0].uuid}`);
        } else if (
          adminUnits.length > 1
          && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_READ])
          && checkPermissions([Permission.CLIENT_UNIT_READ])
        ) {
          return setPath(`${Path.ADMINISTRATION_BUSINESSES}/${user?.rootUnitId}/units`);
        } else {
          return setPath(Path.MY_ACCOUNT);
        }
      } else {
        return setPath(Path.MY_ACCOUNT);
      }
    }
  }, [user, checkPermissions, setPath]);
};

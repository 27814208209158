import axios from 'libs/axios';
import { queryClient, useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { IWallet } from 'types/IWallet';
import { useTranslate } from 'hooks';

export type CreateWalletDTO = Pick<IWallet, 'network' | 'currency'>;

const createWallet = (unitId: number) => async (body: CreateWalletDTO) => {
  const { data } = await axios.post(`${process.env.REACT_APP_CLIENT_HOST}/units/add-wallet/${unitId}`, body);
  return data;
};

type CreateWalletParams = {
  unitId: number;
  uuid: string;
};

const useCreateWallet = ({ unitId, uuid }: CreateWalletParams) => {
  const { t } = useTranslate();

  const mutation = useMutation({
    mutationFn: createWallet(unitId),
    mutationKey: ['addWallet'],
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['unit', uuid],
      });

      notification.success({
        message: t('coldStorage.successfullyAdded'),
        key: 'wallet-created',
      });
    },
    onError: () => {
      notification.error({
        message: t('coldStorage.cannotAdded'),
        key: 'wallet-created',
      });
    },
  });

  return {
    isLoading: mutation.isPending,
    createWallet: mutation.mutate,
  };
};

export default useCreateWallet;

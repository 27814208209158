import { SortOrder } from 'antd/es/table/interface';
import { GroupSortField, GroupsSorting } from '../types';
import { adaptSortOrder } from '../../../../utils';

const adaptSort = (sortKey: GroupSortField | string, sortOrder: SortOrder): GroupsSorting[] => {
  const direction = adaptSortOrder(sortOrder);

  switch (sortKey) {
    default: {
      return [{ field: sortKey as GroupSortField, direction }];
    }
  }
};

export default adaptSort;

import React, { ReactElement } from 'react';
import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
} from 'antd';
import clsx, { ClassValue } from 'clsx';
import styles from './Button.module.scss';

export type BaseButtonProps = Omit<AntdButtonProps, 'icon'> & {
  loading?: boolean;
  textSize?: string;
  color?: 'primary' | 'error' | 'success';
  withoutWaveEffect?: boolean;
  prefixIcon?: React.ReactNode,
  suffixIcon?: React.ReactNode,
  className?: ClassValue;
};

type LinkButtonProps = { type: 'link', withoutBgShadow?: boolean };
type PrimaryButtonProps = { type?: 'primary', withoutBgShadow?: never };
export type ButtonProps = BaseButtonProps & (PrimaryButtonProps | LinkButtonProps);

const Button = ({
  loading,
  textSize,
  className,
  prefixIcon,
  suffixIcon,
  withoutBgShadow,
  withoutWaveEffect,
  type = 'primary',
  color = 'primary',
  ...props
}: ButtonProps): ReactElement => {

  return (
    <AntdButton {...props} loading={loading}
      className={clsx(styles.button, styles[`color-${color}`], styles[`type-${type}`], {
        [styles.withoutBgShadow]: withoutBgShadow,
        [styles.withoutWaveEffect]: withoutWaveEffect,
      }, className)}
    >
      {!loading && prefixIcon}
      {props.children && (
        <div className={styles.text} style={{ fontSize: textSize }}>{props.children}</div>
      )}
      {!loading && suffixIcon}
    </AntdButton>
  );
};

export default Button;

import axios from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { IUser } from 'interfaces/IUser';
import { DefaultError, UseQueryHandlers } from 'types/UseQueryHandlers';

export enum Status {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
  DO_NOT_DISTURB = 'do_not_disturb',
}

export enum Type {
  AUTO = 'auto', // system update
  MANUAL = 'manual', // manual update by manager
}

type UpdateStatusBody = {
  status: Status;
  type?: Type;
};

type OptionType = UseQueryHandlers<IUser, DefaultError, UpdateStatusBody>;
const useUpdateStatus = (options?: OptionType) => {
  const update = async (body: UpdateStatusBody) => {
    const { data } = await axios.put(`${process.env.REACT_APP_AUTH_URI}/users/update-status`, body);
    return data;
  };

  const {
    mutate: updateStatus,
    isPending: isLoading,
  } = useMutation({ mutationFn: update, ...options });

  return {
    updateStatus,
    isLoading,
  };
};

export default useUpdateStatus;

import React from 'react';
import { IWallet } from '../../../../../../../../types/IWallet';
import { calculateAverageRiskScore } from 'utils/calculateAvarageRiskScore';
import { RiskScore } from 'components';
import styles from './RiskScoreTd.module.scss';

export type RiskScoreTdProps = {
  name: string;
  wallets: IWallet[];
};

export const RiskScoreTd = ({ name, wallets }: RiskScoreTdProps) => {
  const score = calculateAverageRiskScore(wallets || []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{name}</span>
      <div className={styles.numerous}>
        <RiskScore riskScore={score} />
      </div>
    </div>
  );
};

import { Locale } from 'contexts/TranslateContext/interfaces/Locale';

export enum LocalStorageKey {
  LOCALE = 'LOCALE',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  LAST_ACTIVITY = 'LAST_ACTIVITY',
  BUSINESS_LOGO = 'BUSINESS_LOGO',

  UNIT_COLUMNS = 'UNIT_COLUMNS',
  FINANCIAL_STATEMENT_COLUMNS = 'FINANCIAL_STATEMENT_COLUMNS',
  BREADCRUMBS_FROM_UNIT = 'BREADCRUMBS_FROM_UNIT',

  USERS_EMPLOYEES_CLIENTS_COLUMNS = 'USERS_EMPLOYEES_CLIENTS_COLUMNS',
  USERS_USERS_TRANSACTIONS = 'USERS_USERS_TRANSACTIONS',
  USERS_INVOICES_COLUMNS = 'USERS_INVOICES_COLUMNS',

  PROCESSING_PAYMENT_ORDERS_COLUMNS = 'PROCESSING_PAYMENT_ORDERS_COLUMNS',
  PROCESSING_TRANSACTIONS_COLUMNS = 'PROCESSING_TRANSACTIONS_COLUMNS',
  PROCESSING_TRANSACTIONS_FIAT_COLUMNS = 'PROCESSING_TRANSACTIONS_FIAT_COLUMNS',

  SYSTEM_TASKS_COLUMNS = 'SYSTEM_TASKS_COLUMNS',
  SYSTEM_FIREWALL_COLUMNS = 'SYSTEM_FIREWALL_COLUMNS',
  SYSTEM_ACTIVITY_LOG_COLUMNS = 'SYSTEM_ACTIVITY_LOG_COLUMNS',

  WALLETS_FAVORITES = 'WALLETS_FAVORITES',
  WALLETS_SELECTED = 'WALLETS_SELECTED',

  EXCHANGE_MODULE_CONFIRM_RATE = 'EXCHANGE_MODULE_CONFIRM_RATE',
}

interface KeyValueMap {
  readonly [LocalStorageKey.SYSTEM_TASKS_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_FIREWALL_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS]: string[];
  readonly [LocalStorageKey.FINANCIAL_STATEMENT_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS]: string[];
  readonly [LocalStorageKey.USERS_EMPLOYEES_CLIENTS_COLUMNS]: string[];
  readonly [LocalStorageKey.USERS_USERS_TRANSACTIONS]: string[];
  readonly [LocalStorageKey.USERS_INVOICES_COLUMNS]: string[];
  readonly [LocalStorageKey.BREADCRUMBS_FROM_UNIT]: string[];
  readonly [LocalStorageKey.UNIT_COLUMNS]: string[];
  readonly [LocalStorageKey.LAST_ACTIVITY]: string;
  readonly [LocalStorageKey.ACCESS_TOKEN]: string;
  readonly [LocalStorageKey.REFRESH_TOKEN]: string;
  readonly [LocalStorageKey.LOCALE]: Locale;
  readonly [LocalStorageKey.BUSINESS_LOGO]: string;
  readonly [LocalStorageKey.WALLETS_FAVORITES]: string[];
  readonly [LocalStorageKey.WALLETS_SELECTED]: string[];
  readonly [LocalStorageKey.EXCHANGE_MODULE_CONFIRM_RATE]: string;
}

const parsableKeys = [
  LocalStorageKey.USERS_EMPLOYEES_CLIENTS_COLUMNS,
  LocalStorageKey.SYSTEM_TASKS_COLUMNS,
  LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS,
  LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS,
  LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS,
  LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS,
  LocalStorageKey.USERS_USERS_TRANSACTIONS,
  LocalStorageKey.USERS_INVOICES_COLUMNS,
  LocalStorageKey.BREADCRUMBS_FROM_UNIT,
  LocalStorageKey.UNIT_COLUMNS,
  LocalStorageKey.LAST_ACTIVITY,
  LocalStorageKey.WALLETS_FAVORITES,
  LocalStorageKey.WALLETS_SELECTED,
];

class LocalStorage {
  static get<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T): R | null {
    const value = localStorage.getItem(key);

    if (value === null) {
      return null;
    }

    return LocalStorage.parseValue(key, value) as R;
  }

  static set<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T, value: R): void {
    localStorage.setItem(key, LocalStorage.stringifyValue(key, value));
  }

  static remove<T extends LocalStorageKey>(key: T): void {
    localStorage.removeItem(key);
  }

  private static stringifyValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: T, value: R): string {
    if (parsableKeys.includes(key)) {
      return JSON.stringify(value);
    }
    return value.toString();
  }

  private static parseValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: LocalStorageKey, value: string): R | string {
    if (parsableKeys.includes(key)) {
      return LocalStorage.parseJSON(key, value) as R;
    }
    return value;
  }

  private static parseJSON<T extends LocalStorageKey, R = KeyValueMap[T]>(
    key: T,
    value: string,
  ): R | null {
    try {
      return JSON.parse(value);
    } catch {
      localStorage.removeItem(key);

      return null;
    }
  }
}

export default LocalStorage;

import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { AxiosError } from 'libs/axios';
import { notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AppState } from 'init/rootReducer';
import { IUnit } from 'interfaces/IUnit';
import { IUnitVariables, UnitFormData } from '../../types';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useTranslate } from 'hooks';
import { useUnitMutations, useUnits } from '../../hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts/AuthorisationContext';
import { SocketContext } from 'contexts/SocketContext';
import { LocalStorage, LocalStorageKey } from 'utils';
import { setRootUnitId, setUnitPageTitle } from '../../actions';
import { Breadcrumbs } from '../Breadcrumbs';
import { FormFreezeUnit } from '../FormFreezeUnit';
import { FormUnit } from '../FormUnit';
import { FreezeButton } from '../FreezeButton';
import { Modal, TotalBalance } from 'components';
import { ChildsSection, Tabs } from './components';
import { WalletList } from './components/Wallets';
import { ApartmentOutlined } from '@ant-design/icons';
import styles from './NestedUnit.module.scss';

export const NestedUnit = (): ReactElement => {
  const { t } = useTranslate();
  const param = useParams();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isNeedRefetchClients, setNeedRefetchClients] = useState(false);
  const { rootUnitId: businessId } = useSelector((state: AppState) => state.unitReducer);

  const uuid = useMemo(() => param['*']?.split('/').pop(), [param]);
  const { checkPermissions } = useContext(AuthorisationContext);
  const { socket } = useContext(SocketContext);

  const {
    isOpen,
    isEdit,
    payload,
    isCreate,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
    isUpload: isModalFreeze,
  } = useModal('units');

  const { data, isLoading, refetch } = useUnits({
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  }, uuid);

  const onRefresh = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (data) {
      batch(() => {
        dispatch(setUnitPageTitle(data.name.toUpperCase()));

        if (businessId !== data?.rootUnitId) {
          dispatch(setRootUnitId(data?.rootUnitId));
        }
      });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setUnitPageTitle(null));
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('unit_balance_changed', async () => onRefresh());
    }
  }, [socket, onRefresh]);

  const {
    isCreating, createUnit: mutationCreateUnit,
    isUpdating, updateUnit: mutationUpdateUnit,
  } = useUnitMutations({
    onSuccess: async (_, variables) => {
      await onRefresh();
      onModalClose();

      switch (variables.type) {
        case 'create':
          return notification.success({
            message: t('users.units.unitWithNameCreated', {
              variables: { unitName: variables.body.name },
            }),
          });
        case 'update':
          setNeedRefetchClients(true);
          return notification.success({
            message: t('users.units.unitWithNameUpdated', {
              variables: { unitName: variables.name },
            }),
          });
        default:
          return notification.success({
            message: 'UNKNOWN SUCCESS OPERATION',
          });
      }
    },
    onError: async (e: AxiosError) => {
      const { message, error } = e.response?.data as { message: string; error: string };
      notification.error({ message: error, description: message });
    },
  });

  const resetFormFields = useCallback((unit?: IUnit) => {
    form.setFieldsValue({
      name: unit?.name || '',
      description: unit?.description || '',
      clients: unit?.clients || [],
      adminIds: unit?.adminIds || [],
      rootUnitId: unit?.rootUnitId || null,
      createWallet: false,
    });
  }, [form]);

  const createUnit = () => {
    resetFormFields();
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
      onSetModalPayload({ rootUnitId: businessId });
    });
  };

  const updateUnit = useCallback(
    (unit: IUnit, modalType?: 'managers' | 'clients' | 'wallets' | 'default') => {
      resetFormFields(unit);
      batch(() => {
        onSetModalPayload({
          id: unit.id,
          uuid: unit.uuid,
          name: unit.name,
          description: unit.description,
          adminIds: unit.adminIds,
          rootUnitId: unit.rootUnitId,
          wallets: unit.wallets,
          ...(modalType && { modalType }),
        });
        onModalOpen();
        onSetModalType(ActionModalType.EDIT);
      });
    },
    [onModalOpen, onSetModalPayload, onSetModalType, resetFormFields],
  );

  const freezeUnit = useCallback((unit: IUnit) => {
    batch(() => {
      onSetModalPayload({
        id: unit.id,
        name: unit.name,
        isActive: unit.isActive,
      });
      onModalOpen();
      onSetModalType(ActionModalType.UPLOAD);
    });
  }, [onModalOpen, onSetModalPayload, onSetModalType]);

  const onSubmit = useCallback(
    async (formData: UnitFormData) => {
      const { id } = payload || {};
      const {
        name,
        isActive,
        adminIds,
        clientIds,
        rootUnitId,
        description,
        createWallet,
      } = formData;

      const body: IUnitVariables = {
        ...(isActive !== undefined && { isActive }),
        ...(adminIds && { adminIds }),
        ...(createWallet && { createWallet }),
        ...(uuid && !id && { parent: data?.id }),
        ...(name && name !== payload.name && { name: name.trim() }),
        ...(description && description !== payload.description && { description: description.trim() }),
        ...(clientIds && { clients: (clientIds || []).map(clientId => Number(clientId)) }),
        ...(rootUnitId ? { rootUnitId } : data?.rootUnitId && { rootUnitId: data.rootUnitId }),
      };

      if (id) {
        await mutationUpdateUnit({ body, id, name: (name || payload.name as string), type: 'update' });
      } else {
        await mutationCreateUnit({ body, type: 'create' });
      }
    },
    [
      uuid,
      payload,
      data?.id,
      data?.rootUnitId,
      mutationUpdateUnit,
      mutationCreateUnit,
    ],
  );

  const breadcrumbs = useMemo(
    () => data?.breadcrumbs
      ? [...data.breadcrumbs, { name: data?.name, uuid: data?.uuid }]
      : [],
    [data?.breadcrumbs, data?.name, data?.uuid]);

  const onSetBreadcrumbs = useCallback(() => {
    LocalStorage.set(LocalStorageKey.BREADCRUMBS_FROM_UNIT, breadcrumbs);
  }, [breadcrumbs]);

  useEffect(() => {

  }, [breadcrumbs]);

  const modalTitle = isCreate
    ? t('users.units.createUnit')
    : isEdit
      ? t('users.units.updateUnit')
      : isModalFreeze
        ? (payload.isActive
          ? t('users.units.deactivateUnit')
          : t('users.units.activateUnit'))
        : t('users.units.removeUnit');

  return (
    <section className={styles.wrapper}>
      <Breadcrumbs items={breadcrumbs} businessId={businessId} />

      {checkPermissions([Permission.CLIENT_UNIT_CREATE]) && (
        <div className={styles.freezeBtn}>
          <FreezeButton
            isLoading={isLoading}
            isActive={data?.isActive}
            onFreeze={() => freezeUnit(data)}
          />
        </div>
      )}

      <section className={styles.layout}>
        <div className={styles.content}>
          <TotalBalance unit={data} />

          <WalletList
            unit={data}
            isLoading={isLoading}
            onRefresh={onRefresh}
          />

          <Tabs
            unit={data}
            isLoading={isLoading}
            onUpdateUnit={updateUnit}
            onRefresh={onRefresh}
            onSetBreadcrumbs={onSetBreadcrumbs}
            isNeedRefetchClients={isNeedRefetchClients}
            setNeedRefetchClients={setNeedRefetchClients}
          />

          <ChildsSection
            isActive={!data?.isActive}
            childUnits={data?.children}
            onFreezeUnit={freezeUnit}
            onCreateUnit={createUnit}
            onUpdateUnit={updateUnit}
            isLoading={isLoading}
          />
        </div>
      </section>
      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen}
        title={modalTitle}
        titleIcon={<ApartmentOutlined style={{ fontSize: 20 }} />}
        onClose={() => onModalClose(false)}
        afterClose={() => onSetModalPayload({})}
      >
        {!isModalFreeze ? (
          <FormUnit
            form={form}
            isParent={false}
            isCreate={isCreate}
            onSubmit={onSubmit}
            rootUnitId={data?.rootUnitId}
            loading={isCreating || isUpdating}
            onClose={() => onModalClose(false)}
            initialValues={payload as UnitFormData}
          />
        ) : (
          <FormFreezeUnit
            form={form}
            onSubmit={onSubmit}
            loading={isUpdating}
            onClose={() => onModalClose(false)}
            initialValues={payload as UnitFormData}
          />
        )}
      </Modal>
    </section>
  );
};

import { useCallback, useState } from 'react';

type UseHandleExpandRowReturn = {
  expandedRowKeys: number[];
  handleExpand: (key: number) => void
};

export const useHandleExpandRow = (): UseHandleExpandRowReturn => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const handleExpand = useCallback((key: number) => {
    setExpandedRowKeys(prevKeys => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter(k => k !== key);
      } else {
        return [...prevKeys, key];
      }
    });
  }, []);

  return { expandedRowKeys, handleExpand };
};

import { useMemo } from 'react';
import { IUnit } from 'interfaces';
import { useCurrencyRate } from 'hooks';
import { convertArrayToHierarchy, CURRENCY, useUnitTreeFlow } from '..';

export const useUnitTree = (parent: IUnit) => {
  const { walletBalance } = useCurrencyRate();
  const { data, isLoading, isError } = useUnitTreeFlow(parent.id);
  // const hierarchyWithParent = {
  //   id: parent?.id,
  //   name: parent?.name,
  //   currency: CURRENCY,
  //   children: ,
  // };

  const tree = useMemo(() => convertArrayToHierarchy(
    data,
    walletBalance as (wallets: any[], currency: string) => number,
  ), [data, walletBalance]);

  return {
    tree,
    ...(parent?.wallets?.length > 0 ? { balance: walletBalance(parent?.wallets as any[], CURRENCY) } : {}),
    ...(parent?.uuid ? { uuid: parent?.uuid } : {}),
    isLoading,
    isError: isError || !tree?.length,
  };
};

export const ADD_PO_TO_APPROVE = 'ADD_PO_TO_APPROVE';
export type AddPoToApproveType = {
  type: typeof ADD_PO_TO_APPROVE;
  payload: number;
};

export const ADD_PO_TO_APPROVE_MASS = 'ADD_PO_TO_APPROVE_MASS';
export type AddPoToApproveMassType = {
  type: typeof ADD_PO_TO_APPROVE_MASS;
  payload: number[];
};

export const REMOVE_PO_FROM_APPROVE = 'REMOVE_PO_FROM_APPROVE';
export type RemovePoFromApproveType = {
  type: typeof REMOVE_PO_FROM_APPROVE;
  payload: number;
};

export type PoActionType = AddPoToApproveType | AddPoToApproveMassType | RemovePoFromApproveType;

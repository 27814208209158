import React, { ReactElement, useState } from 'react';
import { Divider, Switch, Typography } from 'antd';
import { IUnit } from 'interfaces/IUnit';
import {
  currencyOptions,
} from 'pages/Administration/Units/components/Tables/UnitsTable/components/BalanceTd/BalanceTd';
import { useCurrencyRate, useTranslate } from 'hooks';
import { Wallet } from 'hooks/useCurrencyRate';
import { Button, CountUp, CurrencySelect, StatisticsRelationLink } from 'components';
import { LineChartOutlined } from '@ant-design/icons';
import styles from './TotalBalance.module.scss';

export type TotalBalancePropsType = {
  unit: IUnit,
};

export const TotalBalance = ({ unit }: TotalBalancePropsType): ReactElement => {
  const [isGroupBalance, setGroupBalance] = useState(true);
  const [currency, setCurrency] = useState(currencyOptions[0]?.value);
  const { walletBalance, totalBalance } = useCurrencyRate();
  const { t } = useTranslate();

  const disabled = !unit?.isActive;
  const walletsBalance = walletBalance((unit?.wallets || []) as Wallet[], currency);
  const groupBalance = totalBalance((unit?.totalBalances || []) as { [key: string]: number }, currency);

  return (
    <section className={styles.wrapper}>
      <div className={styles.groupBalance}>
        <Typography.Title className={styles.title}>
          {isGroupBalance ? t('users.units.groupBalance') : t('users.units.unitBalance')}
        </Typography.Title>

        <Switch
          defaultChecked={!isGroupBalance}
          onChange={(checked) => setGroupBalance(!checked)}
          checkedChildren={t('users.units.one')}
          unCheckedChildren={t('group')}
          className={styles.switch}
        />
      </div>

      <div className={styles.info}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {(unit?.rootUnitId || unit?.id) && (
            <StatisticsRelationLink rootUnitId={unit.rootUnitId} unitId={unit.id} className={styles.link}>
              <Button
                type="link"
                withoutBgShadow
                withoutWaveEffect
                style={{ padding: 0 }}
                disabled={disabled}
                textSize="17px"
                suffixIcon={<LineChartOutlined style={{ fontSize: 16 }} />}
              >
                {t('viewStatistics')}
              </Button>
            </StatisticsRelationLink>
          )}

          <Divider type="vertical" className={styles.divider} />
        </div>

        {currency && !!currencyOptions.length ? (
          <div className={styles.balanceWrapper}>
            <CountUp
              start={0}
              end={isGroupBalance ? Number(groupBalance) : Number(walletsBalance)}
              currency={currency}
              className={styles.balance}
              isRoundDown
            />
            <CurrencySelect
              wrapperWidth={100}
              currency={currency}
              onSelect={setCurrency}
              options={currencyOptions}
            />
          </div>
        ) : (
          <span className={styles.balance}>0,00</span>
        )}
      </div>
    </section>
  );
};

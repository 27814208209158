import React, { ReactElement } from 'react';
import { Modal } from 'components';
import { ModalType } from 'components/Modal/Modal';
import { FormApproveAction } from './components';
import { FormApproveActionPropsType } from './components/FormApproveAction/FormApproveAction';

export type ApproveActionModalProps = {
  modalProps: Omit<ModalType, 'children'>;
  formProps: FormApproveActionPropsType;
};

export const ApproveActionModal = ({
  modalProps, formProps,
}: ApproveActionModalProps): ReactElement => {
  return (
    <Modal {...modalProps}>
      <FormApproveAction {...formProps} />
    </Modal>
  );
};

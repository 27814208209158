import React, { memo, PropsWithChildren, useMemo } from 'react';
import Tree, { CustomNodeElementProps } from 'react-d3-tree';
import { Spin } from 'antd';
import { IUnit } from 'interfaces';
import { IBusiness } from 'interfaces/IBusiness';
import { useTranslate } from 'hooks';
import { useBusinessTree, useUnitTree } from './hooks';
import { MemoTreeFlowItem } from './components/TreeFlowItem/TreeFlowItem';
import styles from './TreeFlow.module.scss';

type Parent = IBusiness | IUnit;

export type TreeFlowProps = PropsWithChildren & {
  parent?: any;
  width?: number;
};

export const TreeFlow = ({ parent, width = 0 }: TreeFlowProps) => {
  const { t } = useTranslate();
  const nodeSize = useMemo(() => ({ x: 250, y: 100 }), []);
  const foreignObjectProps = useMemo(() => ({
    width: nodeSize.x,
    height: nodeSize.y,
    x: '-125px',
    y: '-25px',
  }), [nodeSize]);
  const hookToUse = parent?.rootUnitId ? useUnitTree : useBusinessTree;
  const { tree, isError, isLoading } = hookToUse(parent);
  const conditionWidth = useMemo(() => width / 2 + width / 4, [width]);
  const treeWrapperWadth = useMemo(() => (width - 250) / 2, [width]);

  if (isLoading || isError) {
    return (
      <div className={styles.placeholder} style={{ width: conditionWidth }}>
        {isLoading ? <Spin /> : <span>{t('error')}</span>}
      </div>
    );
  }

  return (
    <div className={styles.treeContainer} style={{ height: '100%' }}>
      <Tree
        data={tree}
        pathFunc="step"
        orientation="vertical"
        depthFactor={150}
        separation={{ siblings: 2, nonSiblings: 3 }}
        translate={{ x: treeWrapperWadth, y: 50 }}
        pathClassFunc={() => styles.line}
        renderCustomNodeElement={(rd3tProps: CustomNodeElementProps) => {
          return (
            <MemoTreeFlowItem {...rd3tProps} parent={parent} foreignObjectProps={foreignObjectProps} />
          );
        }}
      />
    </div>
  );
};

export const MemoTreeFlow = memo(TreeFlow);

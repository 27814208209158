import React, { ReactElement } from 'react';
import { CopyToClipboard as CopyAntd } from 'react-copy-to-clipboard';
import { Button, notification, Tooltip } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { truncateString } from 'utils';
import { CopyOutlined } from '@ant-design/icons';
import styles from './WalletAddress.module.scss';

type WalletAddressType = {
  walletAddress: string;
  className?: string;
  endLength?: number;
  startLength?: number;
};

const WalletAddress = ({ walletAddress, className, endLength = 8, startLength = 8 }: WalletAddressType): ReactElement => {
  const { t } = useTranslate();

  if (!walletAddress) {
    return (
      <span className={styles.null}>
        {String(null)}
      </span>
    );
  }

  const truncatedAddress = truncateString(walletAddress, { type: 'center', endLength, startLength });

  return (
    <div className={clsx(styles.address, className)}>
      <CopyAntd text={walletAddress} onCopy={() => notification.success({ message: t('coppied') })}>
        <Tooltip title={t('clickToCopy')}>
          <Button
            type="text"
            size="middle"
            icon={<CopyOutlined className={styles.copyIcon} />}
            className={styles.copyBtn}
          />
        </Tooltip>
      </CopyAntd>

      <Tooltip title={walletAddress}>
        <span>{truncatedAddress}</span>
      </Tooltip>
    </div>
  );
};

export default WalletAddress;

import React, { lazy } from 'react';
import type { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';
import administrationRoutes from './administration';
import processingRoutes from './processing';
import systemRoutes from './system';
import usersRoutes from './users';

const LoginPage = lazy(() => import('../pages/Login/Login'));
const InvoicesPage = lazy(() => import('../pages/Invoices/Invoices'));
const MyAccount = lazy(() => import('../pages/Administration/MyAccount/MyAccount'));

const routes: IRoute[] = [
  {
    path: Path.LOGIN,
    isPrivate: false,
    component: () => <LoginPage />,
  },
  {
    path: Path.INVOICES_UUID,
    isPrivate: false,
    component: () => <InvoicesPage />,
  },
  {
    path: Path.MY_ACCOUNT,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    component: () => <MyAccount />,
  },
  ...usersRoutes,
  ...administrationRoutes,
  ...systemRoutes,
  ...processingRoutes,
];

export default routes;

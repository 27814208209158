import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import styles from './EditButton.module.scss';

export interface IEditButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const EditButton = ({ onClick, disabled }: IEditButtonProps): ReactElement => (
  <Button
    onClick={onClick}
    disabled={disabled}
    icon={<ToolOutlined />}
    className={styles.button}
  />
);

export default EditButton;

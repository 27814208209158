export enum TransactionDirectionEnum {
  FEE = 'FEE',
  PAYOUT = 'payout',
  PAYIN = 'payin',
  REFUND = 'refund',
  REFILL = 'refill',
  AGGREGATE = 'aggregate',
  ACC_ACTIVATE = 'acc_activate',
  UNFREEZE_BALANCE_ENERGY = 'unfreezeBalance_energy',
  FREEZE_BALANCE_ENERGY = 'freezeBalance_energy',
  FREEZE_BALANCE_BANDWIDTH = 'freezeBalance_bandwidth',
  UNFREEZE_BALANCE_BANDWIDTH = 'unfreezeBalance_bandwidth',
  UNDELEGATED_BALANCE_ENERGY = 'undelegateBalance_energy',
  UNDELEGATED_BALANCE_BANDWIDTH = 'undelegateBalance_bandwidth',
  DELEGATED_BALANCE_BANDWIDTH = 'delegateBalance_bandwidth',
  DELEGATED_BALANCE_ENERGY = 'delegateBalance_energy',
}

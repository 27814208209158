import React, { ReactElement } from 'react';

export interface IIconProps {
  isActive?: boolean;
}

function FolderIcon({ isActive }: IIconProps): ReactElement {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M19 4H5a3 3 0 00-3 3v5a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3z"
        />
        <path
          fill="#1DA1F2"
          d="M12 12.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          opacity="0.8"
        />
        <path
          fill="#E8F2FE"
          d="M19 15H5a3 3 0 01-3-3v6a3 3 0 003 3h14a3 3 0 003-3v-6a3 3 0 01-3 3zM13 2h-2a2 2 0 00-2 2h6a2 2 0 00-2-2z"
          opacity="0.5"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1DA1F2"
        d="M19 4H5a3 3 0 00-3 3v5a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3z"
        opacity="0.8"
      />
      <path fill="#E8F2FE" d="M12 12.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
      <path
        fill="#E8F2FE"
        d="M19 15H5a3 3 0 01-3-3v6a3 3 0 003 3h14a3 3 0 003-3v-6a3 3 0 01-3 3zM13 2h-2a2 2 0 00-2 2h6a2 2 0 00-2-2z"
      />
    </svg>
  );
}

export default FolderIcon;

import React, { ReactElement, useState } from 'react';
import { Tabs as AntdTabs } from 'antd';
import { isEmpty } from 'lodash';
import { IUnit } from 'interfaces/IUnit';
import { useTranslate } from 'hooks';
import { ClientsTable } from '../../../Tables/ClientsTable/ClientsTable';
import { InvoicesTable } from '../../../Tables/InvoicesTable/InvoicesTable';
import { ManagersTable } from '../../../Tables/ManagersTable/ManagersTable';
import { PendingApprovalsTable } from '../../../Tables/PendingApprovalTable/PendingApprovalTable';
import { TransactionsTable } from '../../../Tables/Transactions/Transactions';
import { Button, CreateClientButton, CreateInvoice } from 'components';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import styles from './Tabs.module.scss';

type TabType = 'managers' | 'clients' | 'transactions' | 'pendingApprovals' | 'invoices';
export type TabsPropsType = {
  unit: IUnit,
  isLoading: boolean;
  isNeedRefetchClients: boolean;
  setNeedRefetchClients: React.Dispatch<React.SetStateAction<boolean>>,
  onRefresh: () => Promise<void>;
  onSetBreadcrumbs: () => void;
  onUpdateUnit: (
    unit: IUnit,
    modalType?: 'managers' | 'clients' | 'wallets',
  ) => void;
};

export const Tabs = ({
  unit,
  isLoading,
  onUpdateUnit,
  onRefresh,
  isNeedRefetchClients,
  setNeedRefetchClients,
  onSetBreadcrumbs,
}: TabsPropsType): ReactElement => {
  const [tab, setTab] = useState<TabType>('transactions');
  const [isTransactionsRefetch, setTransactionsRefetch] = useState(false);
  const [isInvoicesRefetch, setInvoicesRefetch] = useState(false);
  const { t } = useTranslate();

  const disabled = !unit?.isActive;
  const managerIds = unit?.adminIds || [];

  const tabItems = [
    {
      key: 'transactions',
      label: t('transactions'),
      children: (
        <TransactionsTable
          unitId={unit?.id}
          loading={isLoading}
          isRefetch={isTransactionsRefetch}
          onSetRefetch={setTransactionsRefetch}
        />
      ),
    },
    {
      key: 'invoices',
      label: t('invoice.title'),
      children: (
        <InvoicesTable
          loading={isLoading}
          isRefetch={isInvoicesRefetch}
          onSetRefetch={setInvoicesRefetch}
          unitUuid={unit?.uuid}
        />
      ),
    },
    {
      key: 'pendingApprovals',
      label: t('pendingApprovals'),
      children: (
        <PendingApprovalsTable
          unit={unit}
          loading={isLoading}
          isTabActive={tab === 'pendingApprovals'}
        />
      ),
    },
    {
      key: 'managers',
      label: t('administration.managers.title'),
      children: (
        <ManagersTable loading={isLoading} managerIds={managerIds} />
      ),
    },
    {
      key: 'clients',
      label: t('clients'),
      children: (
        <ClientsTable
          unitId={unit?.id}
          loading={isLoading}
          onSetBreadcrumbs={onSetBreadcrumbs}
          isRefetch={isNeedRefetchClients}
          onSetRefetch={setNeedRefetchClients}
        />
      ),
    },
  ];

  const addNewPersonButton = (tab === 'managers' || tab === 'clients') && (
    <Button
      type="link"
      withoutBgShadow
      withoutWaveEffect
      onClick={() => onUpdateUnit(unit, tab)}
      disabled={isLoading || disabled}
      suffixIcon={<PlusOutlined />}
    >
      {tab === 'managers' ? t('users.teams.addNewManager') : t('users.teams.addNewClient')}
    </Button>
  );

  const refetchTransactions = tab === 'transactions' && (
    <Button
      type="link"
      withoutBgShadow
      withoutWaveEffect
      disabled={isLoading || isTransactionsRefetch || disabled}
      onClick={() => setTransactionsRefetch((prevState) => !prevState)}
      suffixIcon={<ReloadOutlined />}
    >
      {t('users.transactions.refresh')}
    </Button>
  );

  const createInvoice = (tab === 'invoices' && !isEmpty(unit)) && (
    <CreateInvoice
      unitId={unit.id}
      onRefresh={() => setInvoicesRefetch(prevState => !prevState)}
      disabledButton={isLoading || isInvoicesRefetch || disabled}
      wallets={unit?.wallets || []}
    />
  );

  const createClientButton = tab === 'clients' && (
    <CreateClientButton
      unitId={unit?.id}
      disabled={isLoading || disabled}
      rootUnitId={unit?.rootUnitId as number}
      onRefresh={() => {
        onRefresh();
        setNeedRefetchClients(true);
      }}
    />
  );

  const extraButtons = (
    <div className={styles.extraButtons}>
      {refetchTransactions}
      {createClientButton}
      {addNewPersonButton}
      {createInvoice}
    </div>
  );

  return (
    <AntdTabs
      type="card"
      items={tabItems}
      className={styles.tabs}
      tabBarExtraContent={extraButtons}
      onChange={(tabTitle) => setTab(tabTitle as TabType)}
    />
  );
};

import type { Location } from 'history';
import _isString from 'lodash/isString';
import qs from 'qs';
import type { Sort } from '..';

export type ParseSortFromQueryParamsProps<Field> = {
  location: Location;
  allowedFields: Field[];
};

const parseSortFromQueryParams = <Field extends string>({
  location,
  allowedFields,
}: ParseSortFromQueryParamsProps<Field>): Sort<Field> | null => {
  const { sort, order } = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (
    _isString(sort) && _isString(order)
      && (order === 'descend' || order === 'ascend')
      && allowedFields.includes(sort as Field)
  ) {
    return { field: sort as Field, order };
  }

  return null;
};

export default parseSortFromQueryParams;

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { IExternalUser } from '../../../../../interfaces/IExternalUser';
import { Path } from '../../../../../routes/interfaces/Path';
import { EyeOutlined } from '@ant-design/icons';

type UserLinkPropsType = {
  user: IExternalUser;
};

const UserLink = ({ user }: UserLinkPropsType): ReactElement => {
  return (
    <Link to={`${Path.USERS_EMPLOYEES_CLIENTS}/${user.uuid}`}>
      <EyeOutlined style={{ fontSize: '17px' }} />
    </Link>);
};

export default UserLink;

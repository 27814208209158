import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import clsx from 'clsx';
import { Path } from 'routes/interfaces/Path';
import { useTranslate } from '../../../../../hooks';
import { useBusinessById } from '../../../Businesses/hooks/useBusinessById';
import { LockOutlined } from '@ant-design/icons';
import styles from './Breadcrumbs.module.scss';

type BreadcrumbsPropsType = {
  items: { name: string, uuid?: string }[] | [];
  businessId: number | null,
};

export const Breadcrumbs = ({ items, businessId }: BreadcrumbsPropsType): ReactElement => {
  const [crumbs, setCrumbs] = useState(items);
  const { pathname } = useLocation();
  const { t } = useTranslate();

  const { data, isLoading } = useBusinessById(businessId);

  const lastElement = crumbs.length - 1;

  useEffect(() => {
    if (items.length) {
      setCrumbs(items);
    }
  }, [items]);

  return (
    <Breadcrumb className={styles.breadcrumb}>
      <Breadcrumb.Item className={styles.item}>{process.env.REACT_APP_TITLE}</Breadcrumb.Item>
      <Breadcrumb.Item className={styles.item}>{t('administration.title')}</Breadcrumb.Item>

      {pathname === Path.ADMINISTRATION_STATISTICS && (
        <Breadcrumb.Item className={styles.item}>{t('statistics.title')}</Breadcrumb.Item>
      )}

      {businessId && (
        <Breadcrumb.Item className={styles.item}>
          <Link to={Path.ADMINISTRATION_BUSINESSES}>{t('business.plur')}</Link>
        </Breadcrumb.Item>
      )}

      {!isLoading && (
        <>
          {businessId && (
            <Breadcrumb.Item className={styles.item}>
              <Link to={`${Path.ADMINISTRATION_BUSINESSES}/${data?.id}/units`}>{data?.name}</Link>
            </Breadcrumb.Item>
          )}

          {crumbs.map((item, i) => (i === lastElement ? (
            <Breadcrumb.Item
              key={`${item.uuid}_${i}`}
              className={clsx(styles.item, styles.activeItem)}
            >
              {item.name}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item className={styles.item} key={item.uuid}>
              {!item.uuid ? <LockOutlined /> : (
                <Link to={`${Path.ADMINISTRATION_BUSINESSES}/${data?.id}/units/${item.uuid}`}>
                  {item.name}
                </Link>
              )}
            </Breadcrumb.Item>
          )))}
        </>
      )}
    </Breadcrumb>
  );
};

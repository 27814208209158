import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IWallet } from 'interfaces';
import { IExternalUser } from 'interfaces/IExternalUser';
import { IInvoice } from 'interfaces/IInvoice';
import { Path } from 'routes/interfaces/Path';
import { InvoiceSortField } from 'pages/Users/Invoices/types';
import { Sort } from 'hooks';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount';
import {
  CopyToClipboard,
  CurrencyNetworkTd,
  DateTime,
  Txns,
} from 'components';
import { StatusBadge } from 'components/StatusBadge';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/ExternalLink.svg';
import { ReactComponent as SwapIcon } from 'assets/icons/Swap.svg';
import styles from '../InvoicesTable.module.scss';

export const showEmptyValue = (value: IExternalUser | string | null) => {
  const isNull = value === null;
  const isEmpryString = value === '';

  return (
    <span className={isNull || isEmpryString ? styles.null : ''}>
      {isNull || isEmpryString ? String(null) : String(value)}
    </span>
  );
};

const renderAmount = (invoice: IInvoice) => {
  const { amount, currency } = invoice;
  const { Text } = Typography;

  return (
    <Text className={styles.amount}>
      {formatCurrencyAmount(amount, currency)}
    </Text>
  );
};

const renderButtons = (
  invoice: IInvoice,
  onView: (invoice: IInvoice) => void,
  onManuallyTransfer: (uuid: string) => void,
  isRoot: boolean,
) => {
  return (
    <div className={styles.buttons}>
      {['partially_paid', 'overpaid', 'completed'].includes(
        invoice.status,
      ) ? (
          <Button
            type="link"
            className={styles.button}
            onClick={() => onManuallyTransfer(invoice.uuid)}
            icon={<SwapIcon className={styles.icon} style={{ width: 20 }} />}
          />
        ) : <></>}

      <Button
        type="link"
        className={styles.button}
        onClick={() => onView(invoice)}
        icon={<InfoCircleOutlined className={styles.icon} />}
      />

      {isRoot && (
        <Link
          target="_blank"
          className={styles.link}
          to={`${Path.INVOICES}/${invoice.uuid}`}
        >
          <ExternalLinkIcon className={styles.icon} />
        </Link>
      )}
    </div>
  );
};

export type ColumnsArgsType = {
  t: TranslateFunc;
  isRoot: boolean;
  withSort?: boolean;
  sort?: Sort<InvoiceSortField> | null;
  onViewInvoice: (invoice: IInvoice) => void;
  onManuallyTransfer: (uuid: string) => void;
};

export const getColumns = ({
  t,
  sort,
  isRoot,
  withSort,
  onViewInvoice,
  onManuallyTransfer,
}: ColumnsArgsType): ColumnsType<IInvoice> => {
  const columns: ColumnsType<IInvoice> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: '110px',
      sorter: withSort,
      sortOrder: sort && sort.field === 'id' ? sort.order : undefined,
    },
    {
      key: 'customerEmail',
      title: t('invoice.fields.emailCustomer'),
      dataIndex: 'customerEmail',
      sorter: withSort,
      sortOrder: sort && sort.field === 'customerEmail' ? sort.order : undefined,
      render: showEmptyValue,
    },
    /*
    * Removed fields: destinationCurrency, destinationNetwork, clientFee;
    */
    // {
    //   key: 'invoiceAmount',
    //   title: t('invoicePage.invoiceAmount'),
    //   dataIndex: 'invoiceAmount',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'amount' ? sort.order : undefined,
    //   render: (_, invoice) => calcInvoiceAmount(invoice),
    // },
    {
      key: 'amount',
      title: t('processing.amount'),
      dataIndex: 'amount',
      sorter: withSort,
      sortOrder: sort && sort.field === 'amount' ? sort.order : undefined,
      render: (_, invoice) => renderAmount(invoice),
    },
    {
      key: 'currency',
      title: t('currency'),
      dataIndex: 'currency',
      sorter: withSort,
      sortOrder: sort && sort.field === 'currency' ? sort.order : undefined,
      render: (_, invoice: IInvoice) => <CurrencyNetworkTd currency={invoice.currency} network={invoice.network} />,
    },
  ];

  if (withSort) {
    columns.push({
      key: 'network',
      title: t('processing.payway'),
      dataIndex: 'network',
      sorter: withSort,
      sortOrder: sort && sort.field === 'network' ? sort.order : undefined,
    });
  }

  columns.push(
    // {
    //   key: 'swapTo',
    //   title: t('invoice.columns.swapTo'),
    //   dataIndex: 'swapTo',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'destinationNetwork' ? sort.order : undefined,
    //   render: (_, invoice: IInvoice) => invoice.destinationCurrency && invoice.destinationNetwork
    //     ? <CurrencyNetworkTd currency={invoice.destinationCurrency} network={invoice.destinationNetwork} />
    //     : showEmptyValue(null),
    // },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      sorter: withSort,
      sortOrder: sort && sort.field === 'status' ? sort.order : undefined,
      render: (status) => <StatusBadge status={status} textTransform="capitalize" />,
    },
    // {
    //   key: 'fee',
    //   title: t('invoice.columns.fee'),
    //   dataIndex: 'fee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'merchantFeePercent' ? sort.order : undefined,
    //   render: (_, invoice: IInvoice) => t('invoice.select.fee', {
    //     variables: {
    //       businessFee: invoice.merchantFeePercent,
    //       clientFee: invoice.clientFeePercent,
    //     },
    //   }),
    // },
    // {
    //   key: 'merchantFee',
    //   title: t('invoicePage.businessFee'),
    //   dataIndex: 'merchantFee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'merchantFee' ? sort.order : undefined,
    // },
    // {
    //   key: 'clientFee',
    //   title: t('invoicePage.clientFee'),
    //   dataIndex: 'clientFee',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'clientFee' ? sort.order : undefined,
    // },
    // {
    //   key: 'feeCurrency',
    //   title: t('invoice.columns.feeCurrency'),
    //   dataIndex: 'feeCurrency',
    //   sorter: withSort,
    //   sortOrder: sort && sort.field === 'feeCurrency' ? sort.order : undefined,
    //   render: (feeCurrency) => feeCurrency ? <CurrencyBadge currency={feeCurrency} /> : showEmptyValue(feeCurrency),
    // },
    {
      key: 'totalFee',
      title: t('invoice.columns.totalFee'),
      dataIndex: 'totalFee',
      sorter: withSort,
      sortOrder: sort && sort.field === 'totalFee' ? sort.order : undefined,
    },
    {
      key: 'total_fee_paid',
      title: t('invoice.columns.totalFeePaid'),
      dataIndex: 'total_fee_paid',
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_fee_paid' ? sort.order : undefined,
    },
    {
      key: 'received_amount',
      title: t('transaction.receivedAmount'),
      dataIndex: 'received_amount',
      sorter: withSort,
      sortOrder: sort && sort.field === 'received_amount' ? sort.order : undefined,
    },
    {
      key: 'total_received_amount',
      title: t('invoice.columns.totalReceivedAmount'),
      dataIndex: 'total_received_amount',
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_received_amount' ? sort.order : undefined,
    },
    {
      key: 'availableAmount',
      title: t('transaction.availableAmount'),
      dataIndex: 'availableAmount',
      sorter: withSort,
      sortOrder: sort && sort.field === 'availableAmount' ? sort.order : undefined,
    },
    {
      key: 'total_available_amount',
      title: t('invoice.columns.totalAvailableAmount'),
      dataIndex: 'total_available_amount',
      sorter: withSort,
      sortOrder: sort && sort.field === 'total_available_amount' ? sort.order : undefined,
    },
    {
      key: 'wallets',
      title: t('wallet'),
      dataIndex: 'wallets',
      // sorter: withSort,
      // sortOrder: sort && sort.field === 'wallets.address.walletAddress' ? sort.order : undefined,
      render: (wallets: IWallet[], invoice) => {
        const targetWallet = wallets.find(wallet => wallet.currency === invoice.currency);
        return !targetWallet?.walletAddress
          ? showEmptyValue(null)
          : (
            <CopyToClipboard
              walletAddress={targetWallet.walletAddress}
              iconColor="#1da1f2"
              type="center"
              revers
            />
          );
      },
    },
    {
      key: 'txId',
      title: 'txId',
      dataIndex: 'txId',
      sorter: withSort,
      sortOrder: sort && sort.field === 'txId' ? sort.order : undefined,
      render: (txId, item) => <Txns transactions={item.transactions} />,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      sorter: withSort,
      sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'expirationAt',
      title: t('invoice.fields.expiryTime'),
      dataIndex: 'expirationAt',
      sorter: withSort,
      sortOrder: sort && sort.field === 'expirationAt' ? sort.order : undefined,
      render: (expirationAt) => <DateTime value={expirationAt} />,
    });

  columns.push({
    dataIndex: '',
    width: 66,
    fixed: 'right',
    align: 'center',
    className: styles.userLink,
    render: (invoice) => renderButtons(invoice, onViewInvoice, onManuallyTransfer, isRoot),
  });

  return columns;
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import clsx from 'clsx';
import { MassWithdrawBodyType } from './hooks/types';
import { useUploadFile } from './hooks';
import { useTranslate } from '../../hooks';
import { Button } from 'components';
import { FilesList } from './components/FilesList';
import { PaymentOrderList } from './components/PaymentOrderList';
import { UploadFile } from './components/UploadFile';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './UploadPOForm.module.scss';

type POCreateType = {
  isLoading: boolean;
  onClose: () => void;
  onRefresh: () => Promise<void>;
  onSubmit: (body: MassWithdrawBodyType) => void;
};

export const UploadPOForm = ({
  onClose,
  onSubmit,
  onRefresh,
  isLoading,
}: POCreateType): ReactElement => {
  const { t } = useTranslate();
  const {
    fileList,
    payments,
    onCloseModal,
    onUpload,
    onDrop,
    onClearFiles,
    removeCurrentPO,
  } = useUploadFile(onClose);

  const allowedPayments = useMemo(() => {
    return payments.filter(payment => payment?.walletValid && payment?.amountValid);
  }, [payments]);

  // const {
  //   fileUploadHandler,
  //   isLoading,
  // } = useUploadPO(validPayments, onMassWithdraw);

  const onPOUpload = useCallback(() => {
    onSubmit({
      data: allowedPayments.map((item: any) => ({
        currency: item?.Currency?.toUpperCase(),
        payway: item?.Network?.toUpperCase(),
        walletAddress: item?.Wallet,
        amount: `${item?.Payout}`,
        category: 1,
        group: 1,
      })),
    });

    // fileUploadHandler( async () => {
    //   await onRefresh();
    //   onClearFiles();
    //   onClose();
    // });
  }, [allowedPayments, onSubmit]);

  const downloadExampleFileJSX = (
    <a
      download
      className={styles.example}
      href={`${process.env.PUBLIC_URL}/mass-po-example.xlsx`}
    >
      <span className={styles.text}>{t('downloadExample')}</span>
      <DownloadOutlined className={styles.icon} />
    </a>
  );

  return (
    <div className={styles.createTask}>
      <Row gutter={[20, 20]} style={{ height: '500px' }}>
        <Col span={15} className={styles.col}>
          {payments.length === 0 ? (
            <UploadFile onUpload={onUpload} />
          ) : (
            <Card className={clsx(styles.card, styles.payments)}>
              <PaymentOrderList payments={payments} onDrop={removeCurrentPO} />
              <div className={styles.footer}>
                <Button
                  loading={isLoading}
                  disabled={allowedPayments.length === 0}
                  onClick={onPOUpload}
                  suffixIcon={<UploadOutlined style={{ fontSize: 17 }} />}
                >
                  {t('file.upload')}
                </Button>
                <span className={styles.info}>
                  {t('loaded')} <span>{allowedPayments.length} {t('of')} {payments.length}</span>
                </span>
              </div>
            </Card>
          )}
        </Col>
        <Col span={9} className={styles.col}>
          <Card className={clsx(styles.card, styles.fileList)}>
            {downloadExampleFileJSX}

            <Tabs
              defaultActiveKey="1"
              className={styles.table}
              items={[{
                key: '1',
                label: t('file.uploaded'),
                className: styles.tab,
                children: (
                  <>
                    <FilesList list={fileList} onDrop={onDrop} />
                    <div>
                      <Button
                        block
                        type="link"
                        color="error"
                        onClick={onCloseModal}
                      >
                        {t('cancel')}
                      </Button>
                    </div>
                  </>
                ),
              }]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

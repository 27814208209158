import axios, { AxiosError } from 'libs/axios';
import { UseMutateFunction, useMutation } from 'libs/reactQuery';

export type VariablesType = { id: number, type: 'approve' | 'decline', otpCode?: string; };

export type OptionType = {
  onSuccess?: (e: any, variables?: VariablesType) => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>, variables?: VariablesType) => void;
};

type ReturnedType = {
  approveRequest: UseMutateFunction<void, AxiosError<{ message: string }>, VariablesType>,
  declineRequest: UseMutateFunction<void, AxiosError<{ message: string }>, VariablesType>,
};

export const useReplenishmentMutations = (options: OptionType): ReturnedType => {
  const approveReplenishment = async ({ id, otpCode }: VariablesType) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CLIENT_HOST}/replenishment-requests/approve/${id}`,
      { otpCode },
    );
    return data;
  };

  const declineReplenishment = async ({ id, otpCode }: VariablesType) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CLIENT_HOST}/replenishment-requests/reject/${id}`,
      { otpCode },
    );
    return data;
  };

  const { mutate: approveRequest } = useMutation({ mutationFn: approveReplenishment, ...options });
  const { mutate: declineRequest } = useMutation({ mutationFn: declineReplenishment, ...options });

  return {
    approveRequest,
    declineRequest,
  };
};

import {
  SET_REPLENISHMENT_REQUEST,
  SET_ROOT_UNIT_ID,
  SET_UNIT_PAGE_TITLE,
  UnitActionType,
} from './types/Actions';
import { UnitType } from './types/Reducer';

const initialState: UnitType = {
  title: null,
  isRequestCreated: false,
  rootUnitId: null,
};

export const unitReducer = <P>(
  state = initialState,
  action: UnitActionType,
): UnitType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case SET_UNIT_PAGE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_REPLENISHMENT_REQUEST:
      return {
        ...state,
        isRequestCreated: action.payload,
      };
    case SET_ROOT_UNIT_ID:
      return {
        ...state,
        rootUnitId: action.payload,
      };
    default:
      return state;
  }
};

import React, { ReactElement, useCallback } from 'react';
import type { Moment } from 'moment';
import { FilterField, FilterFieldType } from '../..';
import {
  DateFilterInput, NumberFilterInput,
  SelectFilterInput, TextFilterInput,
} from './components';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './FilterInput.module.scss';

export type FilterInputProps = {
  value: unknown;
  filterKey: string;
  filterField: FilterField;
  filters: Record<string, unknown>;
  onDeleteClick: (fieldKey: string) => void;
  onChange: (fieldKey: string, value: unknown) => void;
};

const FilterInput = ({
  filterKey, filterField, filters, value, onChange, onDeleteClick,
}: FilterInputProps): ReactElement | null => {
  const onChangeHandler = useCallback((key: string) => (newValue: unknown) => {
    onChange(key, newValue);
  }, [onChange]);

  const onDelete = useCallback(() => {
    onDeleteClick(filterKey);
  }, [filterKey, onDeleteClick]);

  const { label, alwaysVisible, monthOnly } = filterField;

  const deleteButton = !alwaysVisible
    ? <DeleteOutlined className={styles.deleteIcon} onClick={onDelete} />
    : undefined;

  switch (filterField.type) {
    case FilterFieldType.TEXT: {
      const { maxLength, regex, trimOnBlur } = filterField;

      return (
        <TextFilterInput
          label={label}
          regex={regex}
          maxLength={maxLength}
          trimOnBlur={trimOnBlur}
          value={value as string}
          deleteButton={deleteButton}
          onChange={onChangeHandler(filterKey)}
        />
      );
    }
    case FilterFieldType.DATE: {
      const {
        minDate, maxDate, showDateTime, betweenComparisonType,
      } = filterField;

      return (
        <DateFilterInput
          label={label}
          filters={filters}
          minDate={minDate}
          maxDate={maxDate}
          value={value as Moment}
          showDateTime={showDateTime}
          deleteButton={deleteButton}
          onChange={onChangeHandler(filterKey)}
          betweenComparisonType={betweenComparisonType}
          monthOnly={monthOnly}
        />
      );
    }
    case FilterFieldType.SELECT: {
      const { options, mode } = filterField;

      return (
        <SelectFilterInput
          mode={mode}
          label={label}
          options={options}
          value={value as string}
          deleteButton={deleteButton}
          onChange={onChangeHandler(filterKey)}
        />
      );
    }
    case FilterFieldType.NUMBER: {
      const {
        float, maxLength, minValue, maxValue, regex,
      } = filterField;

      return (
        <NumberFilterInput
          float={float}
          label={label}
          regex={regex}
          filters={filters}
          minValue={minValue}
          maxValue={maxValue}
          maxLength={maxLength}
          value={value as number}
          deleteButton={deleteButton}
          onChange={onChangeHandler(filterKey)}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default FilterInput;

export enum WalletNetworkEnum {
  BITCOIN = 'Bitcoin',
  ERC20 = 'ERC20',
  TRC20 = 'TRC20',
  BEP20 = 'BEP20',
  SWIFT = 'SWIFT',
  SEPA = 'SEPA',
  CARD = 'Card',
  BNB = 'BEP20',
  LTC = 'Litecoin',
  DOGE = 'Dogecoin',
}

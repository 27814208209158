import React from 'react';
import { PDFDownloadLink, UsePDFInstance } from '@react-pdf/renderer';
import { UnknownType } from 'types/Unknown';
import generateFileName from './utils/generateFileName';

type PDFGeneratorProps<TData = UnknownType> = {
  children: React.ReactNode;
  data: TData,
  fileName: string;
  document: (props: { data: TData }) => JSX.Element;
  onSuccess?: () => void;
  onError?: (error: string) => void;
};

const PDFGenerator = <TData = UnknownType>({
  document: PDFDocument,
  fileName,
  data,
  children,
  onSuccess,
  onError,
}: PDFGeneratorProps<TData>) => {
  const handleDownload = (_: UnknownType, instance: UsePDFInstance) => {
    if (instance.error) {
      return onError && onError(instance.error);
    }
    return onSuccess && onSuccess();
  };

  return (
    <PDFDownloadLink
      document={<PDFDocument data={data} />}
      onClick={handleDownload}
      fileName={generateFileName(fileName, (data as UnknownType)?.createdAt)}
      style={{ display: 'inline-block' }}
    >
      {children}
    </PDFDownloadLink>
  );
};

export default PDFGenerator;

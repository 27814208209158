import React, { useCallback, useContext, useId, useMemo } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Dropdown } from 'antd';
import { IWallet } from 'types/IWallet';
import { CreateWalletDTO } from 'pages/Administration/Units/components/NestedUnit/components/Wallets/hooks/useCreateWallet';
import { useTranslate } from 'hooks';
import { AuthorisationContext } from 'contexts';
import { AddWallet, Button } from 'components';
import { MoreOutlined, UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import styles from './ActionDropdown.module.scss';

enum KeysVariants {
  massPayout = '0',
  requestReplanishment = '2',
  addWallet = '3',
}

type ActionDropdownProps = {
  onMassPayout: () => void;
  onRequestReplanishment: () => void;
  disabled?: boolean;
  wallets: IWallet[];
  createWallet: (args: CreateWalletDTO) => void;
  isLoading?: boolean;
  isCreatingWallet?: boolean;
};

const ActionDropdown = ({ onRequestReplanishment, onMassPayout, disabled, createWallet, wallets, isCreatingWallet }: ActionDropdownProps) => {
  const { t } = useTranslate();
  const buttonId = useId();
  const { checkPermissions } = useContext(AuthorisationContext);

  const isEmptyDropdown = useMemo(() => {
    return !checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]) && !checkPermissions([Permission.CLIENT_REPLENISHMENT_REQUEST_CREATE]);
  }, [checkPermissions]);

  const items = useMemo(() => [
    {
      label: (
        <span className={styles.clearSpan} onClick={(e) => e.stopPropagation()}>
          <AddWallet
            simpleButton
            prefixIcon
            wallets={wallets}
            createWallet={createWallet}
            isLoading={isCreatingWallet}
            disabled={!checkPermissions([Permission.CLIENT_UNIT_UPDATE])}
          />
        </span>
      ),
      key: KeysVariants.addWallet,
      disabled: !checkPermissions([Permission.CLIENT_UNIT_UPDATE]),
    },
    {
      label: (
        <span><UploadOutlined />{t('processing.paymentOrders.massPayout')}</span>
      ),
      key: KeysVariants.massPayout,
      disabled: !checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]),
    },
    {
      label: (
        <span><VerticalAlignBottomOutlined />{t('replanishment.requestReplanishment')}</span>
      ),
      key: KeysVariants.requestReplanishment,
      disabled: !checkPermissions([Permission.CLIENT_REPLENISHMENT_REQUEST_CREATE]),
    },
  ].filter(item => !item.disabled), [checkPermissions, createWallet, isCreatingWallet, t, wallets]);

  const handleClick = useCallback(({ key }: { key: string }) => {
    if (key === KeysVariants.massPayout) {
      onMassPayout();
    } else if (key === KeysVariants.requestReplanishment) {
      onRequestReplanishment();
    }
  }, [onMassPayout, onRequestReplanishment]);

  if (!wallets.length) {
    return (
      <AddWallet
        wallets={wallets}
        createWallet={createWallet}
        isLoading={isCreatingWallet}
        disabled={!checkPermissions([Permission.CLIENT_UNIT_UPDATE])}
      />
    );
  }

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      overlayClassName={styles.dropdown}
      menu={{
        items,
        onClick: handleClick,
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow
      disabled={disabled || isEmptyDropdown}
    >
      <Button
        id={buttonId}
        withoutBgShadow
        withoutWaveEffect
        type="link"
        suffixIcon={<MoreOutlined />}
      >
        {t('actions')}
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;

import React, { ReactElement, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { Collapse, Typography } from 'antd';
import clsx from 'clsx';
import { AppState } from '../../../../../../init/rootReducer';
import { AuthorisationContext } from '../../../../../../contexts';
import styles from './MenuItem.module.scss';

export interface IMenuItemProps {
  to: string;
  title: string;
  hide?: boolean;
  submenu?: ReactElement[];
  permissions?: Array<Permission>;
}
const { Panel } = Collapse;

const MenuItem = (props: IMenuItemProps): ReactElement | null => {
  const { to, title, hide, submenu, permissions } = props;
  const location = useLocation();
  const { checkPermissions } = useContext(AuthorisationContext);
  const { isCollapsed } = useSelector((state: AppState) => state.sidebarReducer);
  const isActive = location.pathname.includes(to) || `${location.pathname}${location.search}`.includes(to);

  if (hide || (permissions && !checkPermissions(permissions))) {
    return null;
  }

  const containerCX = clsx(styles.container, {
    [styles.containerCollapsed]: isCollapsed,
    [styles.containerWithSubmenu]: !!submenu,
  });

  return (
    <>
      {submenu ? (
        <>
          <Collapse accordion ghost className={containerCX} expandIconPosition="end">
            <Panel header={(
              <>
                {!isCollapsed && <div className={clsx(styles.line, { [styles.activeLine]: isActive })} />}
                <Typography.Text className={clsx(styles.text, { [styles.activeText]: isActive })}>
                  {title}
                </Typography.Text>
              </>
            )} key="1">
              {submenu}
            </Panel>
          </Collapse>
        </>
      ) : (
        <Link to={to} className={clsx(styles.container, { [styles.containerCollapsed]: isCollapsed })}>
          {!isCollapsed && <div className={clsx(styles.line, { [styles.activeLine]: isActive })} />}
          <Typography.Text className={clsx(styles.text, { [styles.activeText]: isActive })}>
            {title}
          </Typography.Text>
        </Link>
      )}
    </>
  );
};

export default MenuItem;

import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';

const ExternalUsers = lazy(() => import('../pages/Users/ExternalUsers/ExternalUsers'));
const UserInfo = lazy(() => import('../pages/Users/UserInfo/UserInfo'));
const TransactionsTeam = lazy(() => import('../pages/Users/Transactions/Transactions'));
const Invoices = lazy(() => import('../pages/Users/Invoices/Invoices'));

const usersRoutes: IRoute[] = [
  {
    path: Path.USERS_EMPLOYEES_CLIENTS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_USER_READ],
    component: () => <ExternalUsers />,
  },
  {
    path: Path.USERS_EMPLOYEES_CLIENTS_USER_ID,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_USER_READ],
    component: () => <UserInfo />,
  },
  {
    path: Path.USERS_TRANSACTIONS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_TRANSACTION_READ],
    component: () => <TransactionsTeam />,
  },
  {
    path: Path.USERS_INVOICES,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_TRANSACTION_READ],
    component: () => <Invoices />,
  },
  // {
  //   path: `${Path.USERS_FINANCIAL_STATEMENT}`,
  //   isPrivate: true,
  //   withHeader: true,
  //   withSideBar: true,
  //   permissions: [Permission.ADMIN_IAM_FINANCIAL_STATEMENT_READ],
  //   component: () => <FinancialStatement />,
  // },
  // {
  //   path: `${Path.VERIFICATION_OF_ACTIONS}`,
  //   isPrivate: true,
  //   withHeader: true,
  //   withSideBar: true,
  //   permissions: [Permission.ADMIN_IAM_USER_CREATE],
  //   component: () => <Verification />,
  // },
];

export default usersRoutes;

import React, { CSSProperties, useCallback, useId, useMemo } from 'react';
import { Dropdown, notification } from 'antd';
import clsx from 'clsx';
import { IWallet } from 'types/IWallet';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { useTranslate } from 'hooks';
import { currencyByNetworkMap, disabledNetworks, networkByTypeMap } from 'constants/wallet';
import { CurrencyIcon } from 'utils/currencyIcon';
import { filterExistingNetworks } from './utils';
import { Button } from 'components';
import { PlusOutlined } from '@ant-design/icons';
import styles from './AddWallet.module.scss';

type AddWalletProps = {
  wallets: IWallet[];
  disabled?: boolean;
  createWallet: (args: { network: string, currency: string }) => void;
  isLoading?: boolean;
  simpleButton?: boolean;
  prefixIcon?: boolean;
  style?: CSSProperties;
};

const AddWallet = ({ wallets, disabled, createWallet, isLoading, simpleButton, prefixIcon, style }: AddWalletProps) => {
  const { t } = useTranslate();
  const buttonId = useId();

  const handleClick = useCallback((network: WalletNetworkEnum) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (disabledNetworks.includes(network)) {
      event.stopPropagation();
      return;
    }
    const currency = currencyByNetworkMap.get(network);
    if (!currency) {
      return notification.error({ message: t('coldStorage.cannotAdded') });
    }

    createWallet({
      network,
      currency,
    });
  }, [createWallet, t]);

  const availableNetwork = useMemo(() => filterExistingNetworks(wallets), [wallets]);
  const isDisabled = useMemo(
    () => availableNetwork.filter(network => !disabledNetworks.includes(network)).length === 0,
    [availableNetwork],
  );
  const items = availableNetwork.map((network) => {
    const name = networkByTypeMap.get(network);
    return ({
      label: (
        <span
          onClick={handleClick(network)}
          className={clsx({ [styles.disabled]: disabledNetworks.includes(network) })}
        >
          <img src={CurrencyIcon[network]} alt={name} />
          {name}
        </span>
      ),
      key: network,
    });
  });

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      overlayClassName={styles.dropdown}
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
      arrow
      disabled={isDisabled || disabled}
    >
      <Button
        style={style}
        loading={isLoading}
        withoutBgShadow
        withoutWaveEffect
        id={buttonId}
        type="link"
        suffixIcon={!prefixIcon && <PlusOutlined />}
        prefixIcon={prefixIcon && <PlusOutlined />}
        className={clsx(styles.button, { [styles.simpleButton]: simpleButton })}
      >
        {t('coldStorage.addWallet')}
      </Button>
    </Dropdown>
  );
};

export default AddWallet;

import React from 'react';
import { Radio } from 'antd';
import { NotificationStatus, Totals } from 'components/Notifications/types';
import { useTranslate } from 'hooks';
import styles from './QuickStatusSegmented.module.scss';

// THIS IS CUSTOM "SEGMENTED" COMPONENT
const QuickStatusSegmented = ({
  value,
  totals,
  onChange,
}: {
  value: NotificationStatus,
  onChange: (newStatus: NotificationStatus) => void,
  totals: Totals
}) => {
  const { t } = useTranslate();

  return (
    <Radio.Group
      value={value}
      onChange={e => onChange(e.target.value as NotificationStatus)}
      buttonStyle="solid"
      className={styles.radioGroup}
    >
      <Radio.Button value={NotificationStatus.ALL}>
        {t('all', { variables: { value: '7' } })} ({totals.all})
      </Radio.Button>
      <Radio.Button value={NotificationStatus.READ}>
        {t('notifications.read', { variables: { value: '30' } })} ({totals.read})
      </Radio.Button>
      <Radio.Button value={NotificationStatus.UNREAD}>
        {t('notifications.unread', { variables: { value: '1' } })} ({totals.unread})
      </Radio.Button>
      <Radio.Button value={NotificationStatus.ARCHIVE}>
        {t('notifications.archive', { variables: { value: '1' } })} ({totals.archive})
      </Radio.Button>
    </Radio.Group>
  );
};

export default QuickStatusSegmented;

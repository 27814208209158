export enum LogActionEnum {
  // PROCESSING PO
  CREATE_PO_LVL_2 = 'createPaymentOrderLvl2',
  APPROVE_PO_LVL_2 = 'approvePaymentOrderLvl2',
  DECLINE_PO_LVL_2 = 'declinePaymentOrderLvl2',

  // USER PO
  CREATE_PAYMENT_ORDER = 'createPaymentOrder',
  MASS_PAYOUT_INITIALIZATION = 'massPayoutInitialization',
  REQUEST_REPLENISHMENT = 'requestReplenishment',

  DEPOSIT_CHECKOUT = 'depositCheckout',
  RESEND_TRANSACTION = 'resendTransaction',

  // UNIT
  CREATE_UNIT = 'createUnit',
  UPDATE_UNIT = 'updateUnit',
  ADD_WALLET_TO_UNIT = 'addWalletToUnit',

  // INVOICE
  CREATE_INVOICE = 'createInvoice',

  // EXCHANGE
  CREATE_EXCHANGE = 'createExchange',

  // INTERNAL USER
  CREATE_USER = 'createUser',
  UPDATE_USER = 'updateUser',
  UPDATE_MANY_USERS = 'updateManyUsers',

  // GROUP/ROLES
  CREATE_GROUP = 'createGroup',
  UPDATE_GROUP = 'updateGroup',

  // TRANSACTION CATEGORIZATION
  CREATE_TRANSACTION_GROUP = 'createTransactionGroup',
  CREATE_TRANSACTION_CATEGORY = 'createTransactionCategory',
  UPDATE_TRANSACTION_GROUP = 'updateTransactionGroup',
  UPDATE_TRANSACTION_CATEGORY = 'updateTransactionCategory',

  // MY ACCOUNT
  UPDATE_PASSWORD = 'updatePassword',
}

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';

const URL = '/units/root-tree?rootUnitId=';

export const useBusinessTreeFlow = (parent: any) => {
  const getBusinessTree = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}${URL}${parent.id}`);
    return data;
  };

  return useQuery({
    queryKey: [`root-unit-tree-${parent.id}`],
    queryFn: getBusinessTree,
  });
};

import { IRiskScoreSignals } from 'interfaces';
import truncateNumber from 'utils/truncateNumber';

interface ReturnType {
  key: string;
  value: number | string;
}

const prepareSignals = (signals: IRiskScoreSignals) => {
  const result: ReturnType[] = [];

  Object.entries(signals).forEach(([key, value]) => {
    if (typeof value !== 'number' || value <= 0) {
      return null;
    }
    result.push({
      key: key.replaceAll('_', ' '),
      value: truncateNumber({
        number: value * 100,
        decimals: 2,
      }),
    });
  });

  return result;
};

export default prepareSignals;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { IUser } from 'interfaces/IUser';
import { UnknownType } from 'types/Unknown';
import { useTranslate } from 'hooks';
import useUpdateStatus, { Status, Type } from 'hooks/useUpdateStatus';
import { AuthorisationContext } from 'contexts';
import { LocalStorage, LocalStorageKey } from 'utils';
import { InactivityModal } from './components';

interface IWithInactivityCheck {
  inactivityDuration: number;
  popupDuration: number;
  awayDuration: number;
}

export const withInactivityCheck = <P extends unknown>(WrappedComponent: React.FC<P>, {
  inactivityDuration,
  popupDuration,
  awayDuration,
}: IWithInactivityCheck) => {
  const WithInactivityCheck: React.FC<P> = (props) => {
    const { logout, user, setUser } = useContext(AuthorisationContext);
    const intervalId = useRef<NodeJS.Timeout>();
    const { t } = useTranslate();

    const { updateStatus } = useUpdateStatus({
      onSuccess: (response) => setUser({
        ...user as IUser,
        status: response.status,
        isManualStatus: response.isManualStatus,
      }),
    });

    const awayDurationAsMs = moment.duration(awayDuration, 'minutes').asMilliseconds();
    const inactivityDurationAsMs = moment.duration(inactivityDuration, 'minutes').asMilliseconds();
    const popupDurationAsMs = moment.duration(popupDuration, 'seconds').asMilliseconds();
    const [timeLeft, setTimeLeft] = useState(inactivityDurationAsMs + popupDurationAsMs);

    const onLogout = useCallback(() => {
      logout({ message: t('afkSessionExpired') });
    }, [logout, t]);

    const onActivity = () => {
      LocalStorage.set(LocalStorageKey.LAST_ACTIVITY, Date.now().toString());

      if (user?.status === 'away' && !user.isManualStatus) {
        updateStatus({
          status: Status.ONLINE,
          type: Type.AUTO,
        });
      }
    };

    const onBackToTab = (e: UnknownType) => {
      if (e.target.visibilityState === 'hidden') {
        onActivity();
      }
    };

    useEffect(() => {
      document.addEventListener('visibilitychange', onBackToTab);
      document.addEventListener('click', onActivity);

      intervalId.current = setInterval(() => {
        const lastActivity = LocalStorage.get(LocalStorageKey.LAST_ACTIVITY);
        const elapsedTime = Date.now() - (lastActivity ? +lastActivity : 0);

        setTimeLeft((inactivityDurationAsMs + popupDurationAsMs) - elapsedTime);

        if (user?.status === Status.ONLINE) {
          if (elapsedTime >= awayDurationAsMs) {
            updateStatus({
              status: Status.AWAY,
              type: Type.AUTO,
            });
          }
        }

        if (elapsedTime > (inactivityDurationAsMs + popupDurationAsMs)) {
          onLogout();
        }
      }, 1000);

      return () => {
        document.removeEventListener('visibilitychange', onBackToTab);
        document.removeEventListener('click', onActivity);

        clearInterval(intervalId.current);
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
      <>
        <WrappedComponent {...props as UnknownType} />
        {timeLeft <= popupDurationAsMs && <InactivityModal timeLeft={timeLeft} />}
      </>
    );
  };

  return WithInactivityCheck;
};

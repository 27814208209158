import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import { IRiskScorePayload } from 'interfaces';
import { IManager, ITeamTransaction } from 'interfaces/ITeamTransaction';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { truncateNumber } from 'utils';
import { CurrencyNetworkTd, DateTime, OwnerIdTd, RelationLink, RiskScore, StatusBadge, WalletAddress } from 'components';
import { ScanLink } from 'components/ScanLink';
import { CategoryTd } from '../components/GroupCategoryTd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from '../Transactions.module.scss';

export const showEmptyValue = (value: UnknownType) => {
  const isNull = value === null;
  const isEmptyString = value === '';

  return (
    <span className={isNull || isEmptyString ? styles.null : ''}>
      {isNull || isEmptyString ? String(null) : String(value)}
    </span>
  );
};

type GetColumnsProps = {
  t: TranslateFunc;
  refetchTransactions: () => void;
  onView: (transaction: ITeamTransaction) => void;
};

export const getColumns = ({ t, refetchTransactions, onView }: GetColumnsProps): ColumnsType<ITeamTransaction> => {
  const handleView = (transaction: ITeamTransaction) => () => {
    onView(transaction);
  };

  return [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: '110px',
    },
    {
      key: 'ownerId',
      title: t('transaction.source'),
      dataIndex: '',
      render: (tx: ITeamTransaction) => <OwnerIdTd invoice={tx.invoice} client={tx.client} unit={tx.unit} />,
    },
    {
      key: 'manager',
      title: t('transaction.manager'),
      dataIndex: 'manager',
      render: (manager: IManager | null) => isEmpty(manager) ? showEmptyValue(null) : (
        <RelationLink
          path={Path.ADMINISTRATION_MANAGERS}
          filters={{ email: manager.email }}
          permissions={[Permission.ADMIN_IAM_USER_READ]}
        >
          {manager.name}
        </RelationLink>
      ),
    },
    {
      key: 'direction',
      title: t('transaction.direction'),
      dataIndex: 'direction',
      render: (direction, { type }) => (
        <StatusBadge status={type === 'FEE' ? type : direction} textTransform="uppercase" />
      ),
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: (status) => <StatusBadge status={status} textTransform="capitalize" />,
    },
    {
      key: 'currency',
      title: t('currency'),
      dataIndex: '',
      render: (tx: ITeamTransaction) => <CurrencyNetworkTd currency={tx.currency} network={tx.network} />,
    },
    {
      key: 'amount',
      title: t('transaction.amount'),
      dataIndex: 'amount',
    },
    {
      key: 'riskScore',
      title: t('riskScore'),
      dataIndex: '',
      sorter: true,
      render: ({
        riskScore,
        payload,
      }: {
        riskScore: number,
        payload: IRiskScorePayload
      }) => <RiskScore riskScore={riskScore} signals={payload.signals} />,
    },
    {
      key: 'fee',
      title: t('transaction.networkFee'),
      dataIndex: 'fee',
      render: (el, { currency }) => el === null ? 'null' : truncateNumber({ number: el, currency }),
    },
    {
      key: 'serviceFee',
      title: t('transaction.serviceFee'),
      dataIndex: 'serviceFee',
      render: (el, { currency }) => el === null ? 'null' : truncateNumber({ number: el, currency }),
    },
    {
      key: 'availableAmount',
      title: t('transaction.availableAmount'),
      dataIndex: 'availableAmount',
      render: (el, { currency }) => el === null ? 'null' : truncateNumber({ number: el, currency }),
    },
    {
      key: 'category',
      title: t('transaction.groupsAndCategories'),
      dataIndex: 'category',
      render: (category, transaction) => (
        <CategoryTd
          category={category}
          transaction={transaction}
          onRefresh={refetchTransactions}
        />
      ),
    },
    {
      key: 'addressFrom',
      title: t('users.transactions.addressFrom'),
      dataIndex: 'addressFrom',
      render: (address) => <WalletAddress walletAddress={address} />,
    },
    {
      key: 'addressTo',
      title: t('users.transactions.addressTo'),
      dataIndex: 'addressTo',
      render: (address) => <WalletAddress walletAddress={address} />,
    },
    {
      key: 'txId',
      title: t('transaction.txId'),
      dataIndex: 'txId',
      render: (txId, item) => txId
        ? <ScanLink txId={txId} network={item.network} withCopy />
        : showEmptyValue(txId),
    },
    {
      key: 'origin',
      title: t('transaction.origin'),
      dataIndex: 'origin',
      render: showEmptyValue,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
    {
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      render: (transaction: ITeamTransaction) => (
        transaction.status === 'completed' && ['payin', 'payout'].includes(transaction.direction) && (
          <Tooltip title={t('transaction.transactionReceipt')}>
            <Button
              type="link"
              onClick={handleView(transaction)}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
        )
      ),
    },
  ];
};

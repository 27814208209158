import React, { ReactElement, useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { IUnit, IUnitChildren } from 'interfaces/IUnit';
import { useTranslate } from 'hooks';
import { UnitsTable } from '../../../Tables/UnitsTable';
import { PlusOutlined } from '@ant-design/icons';
import styles from './ChildsSection.module.scss';

interface ChildsSectionProps {
  childUnits: IUnitChildren[];
  isLoading: boolean;
  isActive: boolean;
  onCreateUnit: () => void;
  onUpdateUnit: (unit: IUnit, modalType?: 'managers' | 'clients' | 'wallets' | 'default') => void;
  onFreezeUnit: (unit: IUnit) => void;
}

export const ChildsSection = ({
  isLoading,
  isActive,
  childUnits,
  onCreateUnit,
  onUpdateUnit,
  onFreezeUnit,
}: ChildsSectionProps): ReactElement => {
  const [isShowTable, setShowTable] = useState(false);
  const { t } = useTranslate();

  const toggleButtonText = isShowTable
    ? t('users.units.hide')
    : t('users.units.show');

  return (
    <section className={styles.unitChildren}>
      <Space className={styles.header}>
        <Space>
          <Typography.Text className={styles.title}>
            {t('users.units.child')}
          </Typography.Text>
          <Button
            type="link"
            className={styles.toggleShowBtn}
            onClick={() => setShowTable(prev => !prev)}
          >
            {toggleButtonText}
          </Button>
        </Space>
        <Space>
          <Button
            type="link"
            disabled={isLoading || isActive}
            onClick={onCreateUnit}
            className={styles.button}
            icon={<PlusOutlined className={styles.icon} />}
          >
            {t('users.units.createChild')}
          </Button>
        </Space>
      </Space>
      {isShowTable && (
        <UnitsTable
          childMode
          units={childUnits}
          loading={isLoading}
          onEditUnit={onUpdateUnit}
          onFreezeUnit={onFreezeUnit}
        />
      )}
    </section>
  );
};

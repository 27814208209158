import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { IUser } from 'interfaces/IUser';
import { useTranslate } from 'hooks';
import useUpdateStatus, { Status, Type } from 'hooks/useUpdateStatus';
import { AuthorisationContext } from 'contexts';
import { CheckOutlined } from '@ant-design/icons';
import styles from './ActivityStatus.module.scss';

export const StatusOption = ({
  size = 13,
  status,
  checked,
  absolute,
  withLabel,
}: {
  size?: number,
  status: Status;
  checked?: boolean;
  absolute?: boolean;
  withLabel?: boolean,
}) => {
  const { t } = useTranslate();

  return (
    <div className={styles.status}>
      <div className={styles.space}>
        <div
          style={{
            width: size,
            height: size,
            position: absolute ? 'absolute' : 'static',
          }}
          className={clsx(styles.ellipse, {
            [styles.online]: status === Status.ONLINE,
            [styles.offline]: status === Status.OFFLINE,
            [styles.away]: status === Status.AWAY,
            [styles.doNotDisturb]: status === Status.DO_NOT_DISTURB,
          })}
        />

        {withLabel && <span className={styles.label}>{t(`activityStatus.${status}`)}</span>}
      </div>

      {checked && <CheckOutlined className={styles.icon} />}
    </div>
  );
};

const ActivityStatus = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, setUser } = useContext(AuthorisationContext);
  const [status, setStatus] = useState<Status>((user as IUser).status);

  const { updateStatus: update } = useUpdateStatus({
    onSuccess: (response) => setUser({
      ...user as IUser,
      status: response.status,
      isManualStatus: response.isManualStatus,
    }),
  });

  useEffect(() => {
    setStatus((user as IUser).status);
  }, [user]);

  const updateStatus = useCallback((newStatus: Status) => {
    update({
      status: newStatus,
      type: Type.MANUAL,
    });
  }, [update]);

  const items = useMemo(() => [
    Status.ONLINE,
    Status.OFFLINE,
    Status.AWAY,
    Status.DO_NOT_DISTURB,
  ]
    .map(item => ({
      key: item,
      label: <StatusOption status={item} checked={item === status} withLabel />,
      className: styles.dropdownMenuItem,
      onClick: () => updateStatus(item),
    })),
  [status, updateStatus]);

  return (
    <Dropdown
      placement="bottomLeft"
      menu={{ items, className: styles.dropdownMenu }}
    >
      <div className={styles.wrapper}>
        <StatusOption status={status} absolute />
        <div>{children}</div>
      </div>
    </Dropdown>
  );
};

export default ActivityStatus;

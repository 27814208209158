export interface Permission {
  id: number;
  name: string;
}

export interface CategorySetting {
  [key: string]: string[];
}

export interface FormattedPermission {
  id: number;
  name: string;
}

export interface FormattedCategory {
  name: string;
  permissions: FormattedPermission[];
}

type FormattedData = Record<string, FormattedCategory[]>;

export function formatPermissions(
  permissions: Permission[],
  categorySettings: CategorySetting[],
) {
  const formattedData: FormattedData = {};

  permissions.forEach((permission) => {
    const parts = permission.name.split('.');
    const group = parts[0];
    const category = parts[1];
    const setting = categorySettings.find((s) => s[1].includes(category));
    const combinedCategory = setting ? setting[0] : category;

    const permissionObject = {
      id: permission.id,
      name: permission.name,
    };

    if (formattedData[group]) {
      const existingCategory = formattedData[group].find(
        (cat) => cat.name === combinedCategory,
      );
      if (existingCategory) {
        existingCategory.permissions.push(permissionObject);
      } else {
        formattedData[group].push({
          name: combinedCategory as any,
          permissions: [permissionObject],
        });
      }
    } else {
      formattedData[group] = [
        {
          name: combinedCategory as any,
          permissions: [permissionObject],
        },
      ];
    }
  });

  const formattedDataArray = Object.keys(formattedData).map((group) => ({
    group,
    category: formattedData[group],
  }));

  return formattedDataArray;
}

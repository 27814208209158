import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IInvoice } from 'interfaces';
import { InvoiceSorting } from 'pages/Users/Invoices/types';

type InvoiceResultType = {
  data: { nodes: IInvoice[], totalCount: number };
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
};

type UseInvoiceParamsType = {
  paging: { limit: number; offset: number };
  sorting: InvoiceSorting[];
};

export const useInvoicesByUnit = (
  uuid: string,
  params?: UseInvoiceParamsType,
): InvoiceResultType => {
  const getInvoicesByUnit = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CLIENT_HOST}/invoices/unit/${uuid}`, { params },
    );
    return data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['invoices', [uuid, params]],
    queryFn: getInvoicesByUnit,
    enabled: Boolean(uuid),
  });

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};

import React, { ReactElement } from 'react';

export function SupportIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill="#1DA1F2"
        d="M7.5.5A7.5 7.5 0 000 8v7.031c0 .26.209.469.469.469h3.594c.689 0 1.25-.56 1.25-1.25v-4.063c0-.689-.561-1.25-1.25-1.25H1.405V8a6.094 6.094
        0 0112.188 0v.938h-2.656c-.69 0-1.25.56-1.25 1.25v4.062c0 .69.56 1.25 1.25 1.25h3.593c.26 0 .469-.209.469-.469V8A7.5 7.5 0 007.5.5zm-3.594
        9.844v3.75h-2.5v-3.75h2.5zm9.688 3.75h-2.5v-3.75h2.5v3.75z"
      />
    </svg>
  );
}

export const roundDown = (number: number, decimals: number): number => {
  const isNegative = number < 0;

  const absNumber = Math.abs(number);

  const factor = Math.pow(10, decimals);
  const result = Math.floor(absNumber * factor) / factor;

  return isNegative ? -result : result;
};

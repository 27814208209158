import { notification } from 'antd';
import Axios, { InternalAxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import { UnknownType } from 'types/Unknown';
import { getFingerprint } from 'contexts/AuthorisationContext/hooks/useFingerprint/utils/getFingerprint';
import {
  debounceRefreshTokens,
  isAccessTokenExpired,
  isAccessTokenExpiring,
  refreshTokens,
} from 'contexts/AuthorisationContext/hooks/useRefreshToken/utils/refreshToken';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';

export { AxiosError } from 'axios';

const PROCESSING_URLS = [
  `${process.env.REACT_APP_BTC_HOST}`,
  `${process.env.REACT_APP_TRON_HOST}`,
  `${process.env.REACT_APP_ETH_HOST}`,
];

export const axiosExcludedUrls: string[] = [
  `${process.env.REACT_APP_AUTH_URI}/auth/refresh-tokens`,
  `${process.env.REACT_APP_AUTH_URI}/auth/logout`,
  `${process.env.REACT_APP_AUTH_URI}/auth/login`,
  `${process.env.REACT_APP_RATE_HOST}`,
  ...PROCESSING_URLS,
];

const axios = Axios.create({
  headers: {
    Authorization: `Bearer ${LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)}`,
  },
});

const maybeRefreshTokens = async (config: InternalAxiosRequestConfig<UnknownType>) => {
  const isExpiring = isAccessTokenExpiring();
  const isExpired = isAccessTokenExpired();

  if ((isExpiring && !isExpired) || isExpiring || isExpired) {
    const isAuthURL = axiosExcludedUrls.some((address) => (config.url as string).startsWith(address));
    const isProcessingURL = PROCESSING_URLS.some((address) => (config.url || '').startsWith(address));

    if ((!isAuthURL && !isExpired) || isProcessingURL) {
      const refreshToken = LocalStorage.get(LocalStorageKey.REFRESH_TOKEN);

      if (refreshToken) {
        try {
          const fingerprint = await getFingerprint();
          debounceRefreshTokens(fingerprint);
        } catch (e) {
          notification.warn({ message: 'Something went wrong. Please try again' });
        }
      }
    }
  }
};

axios.interceptors.request.use(async (config) => {
  if ((config as UnknownType).withoutAuthorizationToken) {
    config.headers.Authorization = null;
  } else {
    // idk cases when this line will be needed in production mode, it's more for testing
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
  }

  maybeRefreshTokens(config);

  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, async (error) => {
  const hasErrorResponse = error.response;
  if (!hasErrorResponse) return Promise.reject(error);

  const refreshToken = LocalStorage.get(LocalStorageKey.REFRESH_TOKEN);
  if (!refreshToken) return Promise.reject(error);

  const originalConfig = error.config || {};
  const isAuthURL = axiosExcludedUrls.some((address) => (originalConfig.url as string)?.startsWith(address));
  const isRefreshedRequest = originalConfig._isRefreshedRequest; // this is a flag to know if a token refresh was attempted
  const isTokenExpiryError = error.response.status === 401;

  if (isTokenExpiryError && !isRefreshedRequest && !isAuthURL) {
    originalConfig._isRefreshedRequest = true; // set the flag so that we don't end up in an infinite loop
    try {
      const fingerprint = await getFingerprint();
      const refreshTokenResponse = await refreshTokens(fingerprint);
      if (isEmpty(refreshTokenResponse)) {
        await Promise.reject({ message: 401 });
      }
    } catch (e) {
      LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
      LocalStorage.remove(LocalStorageKey.REFRESH_TOKEN);
      return Promise.reject(error);
    }

    return axios(originalConfig); // retry the original request
  }

  // TODO: Implement notification system here (using blacklist or whitelist for error codes and messages)
  // notification.error({
  //   message: get(error, ['response', 'data', 'message'], 'Oops! Something went wrong.'),
  //   description: get(error, ['message'], 'Please try again later.'),
  // });

  return Promise.reject(error);
});

export default axios;

import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useTranslate } from '../../../../hooks';
import { bytesToSize } from '../../../../utils/bytesToSize';
import { CloseCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type FilesListType = {
  list: RcFile[];
  onDrop: (key: string) => void;
};
export const FilesList = ({ list, onDrop }: FilesListType): ReactElement => {
  const { t } = useTranslate();

  return (
    <div className={styles.list}>
      {list.length === 0 && (
        <p className={styles.notFound}>{t('file.filesNotFound')}</p>
      )}
      {list.map((item) => (
        <div key={item.uid} className={styles.field}>
          <FileExcelOutlined className={styles.icon} />
          <div className={styles.text}>
            <h4>{item.name}</h4>
            <p>
              {t('fileSize')}
              <span>{bytesToSize(list[0]?.size)}</span>
            </p>
          </div>

          <Button
            type="text"
            danger
            className={styles.button}
            icon={<CloseCircleOutlined />}
            onClick={() => onDrop(item.name)}
          />
        </div>
      ))}
    </div>
  );
};

import React from 'react';
import { ILocaleSettings } from './interfaces/ILocaleSettings';
import { Locale } from './interfaces/Locale';
import locales, { ILocales } from './locales';

export type TranslateVariables = Record<string, string>;

export type TranslateKey = string;

export interface ITranslateOptions {
  variables?: TranslateVariables,
  capitalize?: boolean,
}

export type TranslateFunc = (
  (key: TranslateKey, options?: ITranslateOptions) => string
);

export type ChangeLocaleFunc = (locale: Locale) => void;

export interface ITranslateContext {
  locales: Partial<ILocales>,
  translate: TranslateFunc;
  changeLocale: ChangeLocaleFunc;
  localeSettings: ILocaleSettings & { locale: Locale };
}

export const defaultLocale = Locale.EN;

export const TranslateContext = React.createContext<ITranslateContext>({
  locales: {},
  translate: () => '',
  changeLocale: () => undefined,
  localeSettings: { ...locales[defaultLocale], locale: defaultLocale },
});

import React, { useContext, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { AxiosError } from 'libs/axios';
import { Divider, notification } from 'antd';
import { isEmpty } from 'lodash';
import { IUnit } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { ActionModalType } from 'components/Modal/types/Modal';
import { MassWithdrawBodyType } from 'components/UploadPOForm/hooks/types';
import { setReplenishmentRequest } from 'pages/Administration/Units/actions';
import { FormWithdraw } from 'pages/Administration/Units/components/FormWithdraw';
import { WithdrawFormDataReturned } from 'pages/Administration/Units/components/FormWithdraw/FormWithdraw';
import { CreateWalletDTO } from 'pages/Administration/Units/components/NestedUnit/components/Wallets/hooks/useCreateWallet';
import { useCreatePayment } from 'pages/Administration/Units/hooks/useCreatePayment';
import { useRequestReplen } from 'pages/Administration/Units/hooks/useRequestReplen';
import { useTranslate } from 'hooks';
import { StageEnum, use2FA } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { useMassWithdraw } from 'components/UploadPOForm/hooks';
import { AuthorisationContext } from 'contexts';
import { Form2FA, FormScanQR, Modal, UploadPOForm } from 'components';
import { ActionDropdown } from './components/ActionDropdown';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as PaymentsIcon } from 'assets/icons/Payments.svg';
import styles from './Actions.module.scss';

type ActionsProps = {
  disabled?: boolean;
  onRefresh: () => Promise<void>;
  unit: IUnit;
  createWallet: (args: CreateWalletDTO) => void;
  isCreatingWallet?: boolean;
};

const Actions = ({ disabled, onRefresh, unit, createWallet, isCreatingWallet }: ActionsProps) => {
  const { refreshUser, checkPermissions } = useContext(AuthorisationContext);
  const [
    formData,
    setFormData,
  ] = useState<WithdrawFormDataReturned | MassWithdrawBodyType | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const {
    isOpen,
    isEdit,
    isCreate,
    isUpload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('unitWallets');

  const {
    qrcode,
    stage, setStage,
    isDisabled, setDisabled,
    prevStage, setPrevStage,
    onError, onSuccess,
  } = use2FA();

  const createPayment = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
    });
  };

  const uploadPayments = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.UPLOAD);
    });
  };

  const requestReplen = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.EDIT);
    });
  };

  const {
    massWithdrawMutate,
    isMassWithdrawLoading,
  } = useMassWithdraw({
    onSuccess: async (response, variables) => {
      setFormData(variables.body);

      onSuccess(response, () => {
        onModalClose();
        if (isUpload) {
          onSetModalType(ActionModalType.UPLOAD);
        }
        refreshUser();
        onRefresh();

        if (response.status === 'ERROR') {
          response.data.forEach(item => {
            notification.error({
              message: item.error,
              description: (
                <div className={styles.notifyDesc}>
                  <span className={styles.address}>{item.walletAddress}</span>

                  <div className={styles.amountPayway}>
                    <span className={styles.amount}>{item.amount}</span>
                    <span className={styles.payway}>{item.payway}</span>
                  </div>
                </div>
              ),
              duration: 10,
            });
          });
        } else {
          notification.success({
            message: t('processing.paymentOrders.allPaymentsCreated'),
          });
        }
      });
    },
    onError: async (error, variables) => {
      setFormData(variables.body);

      onError(error, () => {
        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.statusText,
        });
      });
    },
  });

  const { mutateCreatePayment, isCreating } = useCreatePayment({
    onSuccess: async (data) => {
      setPrevStage(StageEnum.DEFAULT);

      onSuccess(data, () => {
        onModalClose();
        refreshUser();
        onRefresh();

        notification.success({
          message: t('processing.paymentOrders.paymentCreated'),
        });
      });
    },
    onError: async (data) => {
      onError(data, () => {
        notification.error({
          description: data?.response?.data?.message,
          message: data?.response?.statusText,
        });
      });
    },
  });

  const { mutateRequestReplen, isRequesting } = useRequestReplen({
    onSuccess: async () => {
      onModalClose();
      dispatch(setReplenishmentRequest(true));

      notification.success({
        message: t('replanishment.requestSent'),
      });
    },
    onError: (e: AxiosError) => {
      const { message, error } = e.response?.data as { message: string; error: string };
      notification.error({ message: error, description: message });
    },
  });

  const wallets = useMemo(() => unit?.wallets || [], [unit]);

  const handleSubmit = (data: WithdrawFormDataReturned & MassWithdrawBodyType) => {
    if (isEdit) {
      mutateRequestReplen({
        body: {
          unitId: unit.id,
          group: data.group,
          network: data.network,
          currency: data.currency,
          category: data.category,
          amount: Number(data.amount),
          description: data.description,
          walletAddress: data.walletAddress,
        },
      });
    } else if (isCreate) {
      setFormData(data);

      mutateCreatePayment({
        uuid: unit.uuid,
        body: {
          group: data.group,
          feeValue: data.feeValue,
          totalFee: data.totalFee,
          gasPrice: data.gasPrice,
          currency: data.currency,
          amount: data.amount,
          otpCode: data.otpCode,
          category: data.category,
          walletAddress: data.walletAddress,
          payway: data.network === 'Bitcoin' ? 'BTC' : data.network,
        },
      });
    } else {
      massWithdrawMutate({
        uuid: unit.uuid,
        body: {
          data: data.data,
          otpCode: data.otpCode,
        },
      });
    }
  };

  const handleSubmitCode = (code: string) => {
    if (!isEmpty(formData)) {
      setDisabled(true);
      handleSubmit({ ...formData as UnknownType, otpCode: code });
    }
  };

  const modalTitle = isEdit
    ? t('replanishment.requestReplanishment')
    : t('processing.paymentOrders.createPayment');

  return (
    <>
      {checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]) && (
        <>
          <span className={styles.headerButton} onClick={createPayment}>
            {t('processing.paymentOrders.createPayment')}
            <PlusOutlined />
          </span>

          <Divider type="vertical" className={styles.divider} />
        </>
      )}
      <ActionDropdown
        wallets={wallets}
        onRequestReplanishment={requestReplen}
        onMassPayout={uploadPayments}
        disabled={disabled}
        createWallet={createWallet}
        isCreatingWallet={isCreatingWallet}
      />
      <Modal
        width={isUpload && stage === StageEnum.DEFAULT ? 1200 : 450}
        destroyOnClose
        isOpen={isOpen}
        title={isUpload ? null : modalTitle}
        titleIcon={isUpload ? null : <PaymentsIcon width={26} />}
        onClose={() => {
          onModalClose(false);
          setStage(StageEnum.DEFAULT);
          setPrevStage(StageEnum.DEFAULT);

          if (isUpload) {
            onSetModalType(ActionModalType.UPLOAD);
          }
        }}
        afterClose={() => {
          onSetModalType(ActionModalType.DEFAULT);
          onSetModalPayload({});
          setFormData(null);
        }}
        className={isUpload ? styles.modal : undefined}
      >
        <>
          {stage === StageEnum.DEFAULT && (
            isUpload ? (
              <UploadPOForm
                onRefresh={onRefresh}
                onClose={() => {
                  onModalClose(false);
                  if (isUpload) {
                    onSetModalType(ActionModalType.UPLOAD);
                  }
                }}
                onSubmit={handleSubmit as UnknownType}
                isLoading={isMassWithdrawLoading}
              />
            ) : (
              <FormWithdraw
                {...formData && { initialValues: formData } as UnknownType}
                unitId={unit?.uuid}
                isWithdraw={isCreate}
                onSubmit={handleSubmit}
                isLoading={isCreating || isRequesting}
                onClose={onModalClose}
                wallets={wallets}
              />
            )
          )}

          {(stage === StageEnum.QR_CODE && !!qrcode) && (
            <FormScanQR
              qrCode={qrcode}
              onBack={() => setStage(StageEnum.DEFAULT)}
              onContinue={() => {
                setPrevStage(StageEnum.QR_CODE);
                setStage(StageEnum.OTP_CODE);
              }}
            />
          )}

          {stage === StageEnum.OTP_CODE && (
            <Form2FA
              isLoading={isCreating || isDisabled}
              onSubmit={handleSubmitCode}
              onBack={() => setStage(prevStage)}
            />
          )}
        </>
      </Modal>
    </>
  );
};

export default Actions;

import { IStatus } from '../../interfaces/IStatus';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { getGasPriceIndicators } from './getGasPriceIndicators';
import { getInvoiceStatuses } from './getInvoiceStatuses';
import { getLogActions } from './getLogActions';
import { getPoStatuses } from './getPoStatuses';
import { getSMTPStatuses } from './getSMTPStatuses';
import { getTxDirections } from './getTxDirections';
import { getTxStatuses } from './getTxStatuses';
import { getTxTypes } from './getTxTypes';
import { getUserStatuses } from './getUserStatuses';

export const getStatuses = (t: TranslateFunc): IStatus[] => {
  const logActions = getLogActions(t);
  const poStatuses = getPoStatuses(t);
  const txTypes = getTxTypes(t);
  const txStatuses = getTxStatuses(t);
  const txDirections = getTxDirections(t);
  const userStatuses = getUserStatuses(t);
  const invoiceStatuses = getInvoiceStatuses(t);
  const SMTPStatuses = getSMTPStatuses(t);
  const gasPriceIndicators = getGasPriceIndicators(t);

  return [
    ...logActions,
    ...poStatuses,
    ...txTypes,
    ...txStatuses,
    ...txDirections,
    ...userStatuses,
    ...invoiceStatuses,
    ...SMTPStatuses,
    ...gasPriceIndicators,
  ];
};

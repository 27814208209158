import moment from 'moment';

export const formatExpiryTime = (createdAt: string, expirationAt: string) => {
  const createDate = moment(createdAt);
  const expiryDate = moment(expirationAt);
  const duration = moment.duration(expiryDate.diff(createDate));

  const totalHours = Math.ceil(duration.asHours());

  const daysLeft = Math.floor(totalHours / 24);
  const hoursLeft = totalHours % 24;

  return JSON.stringify({ days: daysLeft, hours: hoursLeft });
};

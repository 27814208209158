import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Col, Typography } from 'antd';
import clsx from 'clsx';
import { POFileType } from '../../../../hooks/types';
import { useTranslate } from '../../../../../../hooks';
import { CountUp, CurrencyNetworkTd } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type PropsType = {
  item: POFileType;
  onDrop: () => void;
};

type ErrorType = {
  message?: string;
  type?: 'error' | 'warn' | 'success';
};

const { Text } = Typography;

export const PaymentOrderItem = ({ item, onDrop }: PropsType): ReactElement => {
  const [error, setError] = useState<ErrorType>({ message: '', type: 'success' });
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const [isConfirm, setConfirm] = useState(false);
  const { t } = useTranslate();

  useEffect(() => {
    if (!item?.Network) {
      return setError({
        type: 'error',
        message: t('processing.paymentOrders.networkIsRequired'),
      });
    }
    if (!item?.Wallet) {
      return setError({
        type: 'error',
        message: t('processing.paymentOrders.walletIsRequired'),
      });
    }
    if (!item?.Payout) {
      return setError({
        type: 'error',
        message: t('processing.paymentOrders.payoffIsRequired'),
      });
    }
    if (item?.Payout && !item?.amountValid) {
      return setError({
        type: 'error',
        message: t('processing.paymentOrders.amountInvalid'),
      });
    }
    if (item?.Wallet && !item?.walletValid) {
      return setError({
        type: 'error',
        message: t('walletAddressValidate.isNotValidate', {
          variables: { currency: item?.Currency?.toUpperCase() },
        }),
      });
    }
    if (!item?.Username) {
      return setError({
        type: 'warn',
        message: t('processing.paymentOrders.usernameNotFound'),
      });
    }
  }, [t, item]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setConfirm(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const removePOHandler = () => {
    setConfirm(true);
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  const onDelete = () => {
    onDrop();
    closeConfirm();
  };

  const validateCX = {
    [styles.isError]: error?.type === 'error',
    [styles.isWarn]: error?.type === 'warn',
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={clsx(styles.item, validateCX)} ref={ref}>
        <Col xxl={16} xl={16} lg={16} md={16} sm={16} xs={16} style={{ padding: 0 }}>
          <div className={styles.wrapperName}>
            <Text className={clsx(styles.username, { [styles.isMissing]: !item?.Username })}>
              {item?.Username || t('notFound')}
            </Text>
            <Text className={clsx(styles.wallet, { [styles.isMissing]: !item?.Wallet })}>
              {item?.Wallet || t('notFound')}
            </Text>
          </div>
        </Col>

        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ padding: 0, textAlign: 'right' }}>
          <Text className={clsx(styles.network, { [styles.isMissing]: !item?.Network })}>
            {(item?.Network && item?.Currency) ? (
              <CurrencyNetworkTd
                currency={item.Currency.toUpperCase()}
                network={item.Network.toUpperCase()}
              />
            ) : t('notFound')}
          </Text>
        </Col>

        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ padding: 0, textAlign: 'right' }}>
          <Text className={clsx(styles.Payout, { [styles.isMissing]: !item?.Payout })}>
            {item?.Payout && item?.Currency ? (
              <CountUp end={Number(item.Payout)} currency={item.Currency?.toUpperCase()} decimal="." isRoundDown />
            ) : t('notFound')}
          </Text>
        </Col>

        <Button
          danger
          type="text"
          className={styles.button}
          icon={<DeleteOutlined />}
          onClick={removePOHandler}
        />

        <div className={clsx(styles.confirm, { [styles.open]: isConfirm })}>
          <Button type="primary" danger onClick={onDelete}>
            {t('delete')}
          </Button>

          <Button type="link" onClick={closeConfirm}>
            {t('cancel')}
          </Button>
        </div>
      </div>

      <Text className={clsx(styles.errorMsg, validateCX)}>{error?.message}</Text>
    </div>
  );
};

import axios, { AxiosError } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';

export type OptionType = {
  onSuccess: (response: CommisionType) => void;
  onError: (error: AxiosError<{ message: string }>) => void;
};
export type CommissionQuery = {
  network: string;
  currency: string;
  amount: string | number;
  addressFrom: string;
  destinationAddress: string;
};
export type CommisionType = {
  feeValue: number;
  serviceFee: number;
  gasPrice?: string;
};

export const useCommission = (query: CommissionQuery | null, enabled: boolean, options?: OptionType): any => {
  const getCommission = async (context: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CLIENT_HOST}/balance/withdraw/service-fee`,
      {
        params: context.queryKey[1] || query,
      },
    );

    return data;
  };

  const queryResponse = useQuery<CommisionType>({
    queryKey: [`commission-${JSON.stringify(query)}`, query],
    queryFn: getCommission,
    enabled,
    ...options,
  });

  const refresh = (newParams?: any) => {
    return getCommission({
      queryKey: [`commission-${JSON.stringify(newParams)}`, { ...newParams }],
    });
  };

  return {
    ...(queryResponse),
    refresh,
  };
};

export enum PaymentStatusEnum {
  CREATED = 'created',
  UNDER_REVIEW = 'under_review',
  PENDING_APPROVAL = 'pending_approval',
  CANCELED = 'canceled',
  APPROVED = 'approved',
  SUSPENDED = 'suspended',
  PENDING = 'pending',
  IN_PROCESS = 'in_process',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
}

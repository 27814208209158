import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IReplenishment } from 'interfaces/IReplenishment';
import { Filter } from 'types/Filter';
import { ReplenishmentSorting } from '../types';

type ReplenishmentParams = {
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset: number };
  sorting?: ReplenishmentSorting[];
};

export const useReplenishments = (params: ReplenishmentParams) => {
  const getRequests = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/replenishment-requests`, {
      params,
    });
    return data;
  };

  return useQuery<{ nodes: IReplenishment[], totalCount: number }>({
    queryFn: getRequests,
    queryKey: ['replenishment-requests'],
  });
};

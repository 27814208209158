import React from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './ExpandRowButton.module.scss';

export type ExpandRowButtonProps = {
  handleExpand: () => void;
  isExpanded: boolean;
};

export const ExpandRowButton = ({ handleExpand, isExpanded }: ExpandRowButtonProps) => {
  return (
    <Button
      type="link"
      onClick={handleExpand}
      className={clsx(styles.button, styles.toggleExpand)}
    >
      {isExpanded ? <UpOutlined style={{ fontSize: '16px' }} /> : <DownOutlined style={{ fontSize: '16px' }} /> }
    </Button>
  );
};

import axios from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { AxiosError } from 'axios';
import { UnknownType } from 'types/Unknown';

type Options = {
  onSuccess?: (data: UnknownType) => void;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

const useNotificationRead = (options?: Options) => {
  const read = async (id: number) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_NOTIFICATION_URI}/notifications/${id}`,
    );

    return data;
  };

  return useMutation({ mutationFn: read, ...options });
};

export default useNotificationRead;

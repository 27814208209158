import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { Table } from 'antd';
import clsx from 'clsx';
import { IInvoice } from 'interfaces/IInvoice';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useTablePagination, useTranslate } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useManualTransfer } from 'hooks/useManualTransfer';
import { useInvoicesByUnit } from './hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { formatExpiryTime, getColumns } from './utils';
import { Modal, Pagination } from 'components';
import { LinkForPayment } from 'components/CreateInvoice/components';
import { InvoiceView } from 'components/CreateInvoice/components/InvoiceView';
import styles from './InvoicesTable.module.scss';

type InvoicesTableProps = {
  loading: boolean;
  isRefetch: boolean;
  unitUuid: string;
  onSetRefetch: (state: boolean) => void;
};

export const InvoicesTable = ({ unitUuid, isRefetch, onSetRefetch, loading }: InvoicesTableProps): ReactElement => {
  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });
  const { isRoot } = useConfirmRootAdmin();
  const { t } = useTranslate();

  const {
    data,
    refetch,
    isFetching,
    isLoading: isInvoicesLoading,
  } = useInvoicesByUnit(unitUuid, {
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  const { mutate: onManuallyTransfer } = useManualTransfer();

  useEffect(() => {
    if (isRefetch) {
      refetch();
    }
  }, [isRefetch, refetch]);

  useEffect(() => {
    if (!isFetching) {
      onSetRefetch(false);
    }
  }, [isFetching, onSetRefetch]);

  const {
    isOpen,
    payload,
    isCreate,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('invoice');

  const onViewInvoice = useCallback(
    (invoice: IInvoice) => {
      batch(() => {
        onModalOpen();
        onSetModalPayload({ ...invoice });
      });
    },
    [onModalOpen, onSetModalPayload],
  );

  const invoices = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const columns = useMemo(() => getColumns({
    t,
    isRoot,
    onViewInvoice,
    onManuallyTransfer,
  }), [t, isRoot, onViewInvoice, onManuallyTransfer]);

  const wallets: UnknownType = payload.wallets;
  return (
    <div
      className={clsx(styles.wrapper, { [styles.empty]: !totalCount })}
      style={{ height: 'calc(250px + 54px + 40px + 20px)' }} // table-body + table-header + pagination + gap height
    >
      <Table
        rowKey="id"
        tableLayout="auto"
        scroll={{
          x: totalCount && !isInvoicesLoading ? 1800 : undefined,
          y: totalCount && !isInvoicesLoading ? 250 : 'calc(250px + 40px + 20px)', // table-body + pagination + gap height
        }}
        columns={columns}
        dataSource={invoices}
        pagination={false}
        loading={isInvoicesLoading || loading}
        showHeader={!isInvoicesLoading && !loading && !!totalCount}
        rowClassName={styles.row}
      />

      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isInvoicesLoading || !totalCount}
        disablePerPageHandler={isInvoicesLoading || !totalCount}
        elementsSize="small"
      />

      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen && !isCreate}
        onClose={onModalClose}
        title={t('invoice.information')}
      >
        <div className={styles.infoForm}>
          {isRoot && wallets?.length > 0 && (
            <LinkForPayment
              path={Path.INVOICES}
              uuid={payload.uuid as string}
              wallet={wallets?.length && wallets[0]?.walletAddress as string}
            />
          )}

          <InvoiceView
            invoice={{
              ...payload,
              fee: `${payload.merchantFeePercent}/${payload.clientFeePercent}`,
              expirationAt: formatExpiryTime(payload.createdAt as string, payload.expirationAt as string),
            } as InvoiceFormDataType}
          />
        </div>
      </Modal>
    </div>
  );
};

import React, { ReactElement } from 'react';
import { Button, Typography } from 'antd';
import { useTranslate } from '../../../../hooks';
import packageJson from '../../../../../package.json';
import { SupportIcon } from '../../icons/SupportIcon';
import { CustomerServiceOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type SidebarSupportType = {
  collapsed: boolean;
};

export const SidebarSupport = ({ collapsed }: SidebarSupportType): ReactElement => {
  const { t } = useTranslate();

  if (collapsed) {
    return (
      <div className={styles.containerCollapsed}>
        <Button
          type="text"
          size="large"
          shape="circle"
          icon={<SupportIcon />}
          className={styles.supportCollapsed}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.actionButtons}>
        <Button className={styles.support} icon={<CustomerServiceOutlined />} type="link" disabled>
          {t('help')}
        </Button>
        <Button className={styles.support} icon={<SettingOutlined />} type="link" disabled>
          {t('settings')}
        </Button>
      </div>
      <div className={styles.information}>
        <Typography.Text className={styles.copyright}>Copyright 2023</Typography.Text>
        <Typography.Text className={styles.copyright}>v{packageJson.version}</Typography.Text>
      </div>
    </div>
  );
};

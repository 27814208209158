import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { AxiosError } from 'libs/axios';
import { Form, Input, notification } from 'antd';
import { Rule } from 'antd/es/form';
import clsx from 'clsx';
import { FormDatatype } from 'pages/Administration/Managers/components/FormCreateUser/hooks/useCreateInternalUser/types';
import { useTranslate } from 'hooks';
import { useCreateClient } from 'components/CreateClientButton/hooks/useCreateClient';
import { useModal } from 'components/Modal/hooks/useModal';
import { generatePassword } from 'utils';
import { emailValidator } from 'utils/emailValidator';
import { BusinessSelect } from './BusinessSelect';
import { Button } from 'components';
import { LockOutlined, MailOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import styles from './FormCreateClient.module.scss';

type FormCreateClientProps = {
  unitId?: number;
  rootUnitId?: number;
  onRefresh: () => void;
};

export type ICreateClient = {
  unitId?: number | undefined;
  merchantId: string;
  name: string;
  password: string;
  email: string;
};

export const FormCreateClient = ({ unitId, rootUnitId, onRefresh }: FormCreateClientProps): ReactElement => {
  const { onModalClose, isOpen } = useModal('createClient');
  const { t } = useTranslate();
  const [rootUnitID, setRootUnitID] = useState(rootUnitId);

  const { mutation, loading: isCreating } = useCreateClient({
    onSuccess: async () => {
      notification.success({
        message: t('client.createdSuccessfully'),
      });

      await onModalClose();
      await onRefresh();
    },
    onError: (e: AxiosError<{ message: string }>) => {
      if (e.response?.status === 403) {
        notification.error({
          message: t('userWithThisEmailAlreadyExists'),
        });
      } else {
        notification.error({
          message: e.response?.data?.message,
        });
      }
    },
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      return () => {
        form.resetFields();
      };
    }

    return undefined;
  }, [form, isOpen]);

  const onSubmit = async (values: Omit<FormDatatype, 'groupId'>) => {
    const createdClient = {
      ...values,
      merchantId: values.name.split(' ').join(''),
      name: values.name?.trim(),
      ...(rootUnitID && { rootUnitId: rootUnitID }),
      ...(unitId && { unitId }),
    };

    await mutation(createdClient as ICreateClient);
  };

  const inputEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      email: e.target.value.trim(),
    });
  };

  const inputNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      name: e.target.value.trim(),
    });
  };

  const inputPasswordHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      password: e.target.value.trim(),
    });
  };

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword });
  };

  const emailRules: Rule[] = [
    () => ({
      validator(_, value: string) {
        return emailValidator(t, value);
      },
    }),
  ];

  return (
    (<Form
      name="createClient"
      className={styles.form}
      form={form}
      onFinish={onSubmit}
    >
      <div>
        <Form.Item
          name="name"
          label={t('nameAndSurname')}
          className={styles.formItem}
          rules={[
            { max: 100, message: t('emailRulesMax') },
            { required: true, type: 'string', message: t('pleaseInputName') },
            {
              pattern: /^([a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+ [a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+)$/,
              message: t('namePattern'),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className={styles.icon} />}
            placeholder={t('nameAndSurname')}
            onBlur={inputNameHandler}
            className={styles.input}
          />
        </Form.Item>
        <Form.Item
          required
          name="email"
          label={t('email')}
          className={styles.formItem}
          rules={emailRules}
        >
          <Input
            prefix={<MailOutlined className={styles.icon} />}
            placeholder={t('email')}
            onChange={inputEmailHandler}
            className={styles.input}
          />
        </Form.Item>
        <div className={clsx(styles.formItem, styles.withAddonBtn)}>
          <Form.Item
            name="password"
            label={t('password')}
            rules={[
              { required: true, message: t('pleaseInputPassword') },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\dA-Za-z]{8,48}$/,
                message: t('passwordRules'),
              },
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              prefix={<LockOutlined className={styles.icon} />}
              onChange={inputPasswordHandler}
              placeholder={t('password')}
              className={styles.input}
            />
          </Form.Item>

          <Button
            type="link"
            textSize="14px"
            withoutBgShadow
            withoutWaveEffect
            prefixIcon={<SyncOutlined />}
            className={styles.genereatePassBtn}
            onClick={onGeneratePassword}
          >
            {t('myAccount.generatePassword')}
          </Button>
        </div>
        {!unitId && !rootUnitId && (
          <Form.Item
            label={t('client.selectBusiness')}
            className={styles.formItem}
          >
            <BusinessSelect
              onChange={setRootUnitID}
              className={styles.select}
              inputPlaceholder={t('client.selectBusiness')}
            />
          </Form.Item>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={isCreating}
        >
          {t('create')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          htmlType="button"
          onClick={onModalClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>)
  );
};

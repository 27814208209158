import moment from 'moment';

const generateFileName = (prefix: string, date?: string, fileFormat?: string) => {
  let dateString;
  if (date) {
    dateString = moment(date).format('YYYY-MM-DD_HH-mm-ss');
  } else {
    dateString = moment().format('YYYY-MM-DD_HH-mm-ss');
  }

  const randomNumber = Math.floor(Math.random() * 1000);

  const fileName = `${prefix}_${dateString}_${randomNumber}.${fileFormat || 'pdf'}`;

  return fileName;
};

export default generateFileName;

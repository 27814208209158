import { IWallet } from 'types/IWallet';

export const calculateAverageRiskScore = (wallets: IWallet[]) => {
  if (wallets.length === 0) return 0;

  const walletsWithRiskScore = wallets.filter((wallet) => wallet.riskScore > 0);
  if (walletsWithRiskScore.length === 0) return 0;

  const totalRiskScore = walletsWithRiskScore.reduce((acc, wallet) => acc + wallet.riskScore, 0);

  return (totalRiskScore / walletsWithRiskScore.length) || 0;
};

import React, {
  ChangeEvent, KeyboardEvent,
  ReactElement, useCallback } from 'react';
import { Input } from 'antd';
import _isFunction from 'lodash/isFunction';
import _isNumber from 'lodash/isNumber';
import { FilterInputProps } from '../types/FilterInputProps';
import { NumberFilterFieldParams } from '../../../types/NumberFilterFieldParams';
import styles from '../FilterInput.module.scss';

type ParamsFields = 'label' | 'float' | 'minValue' | 'maxValue' | 'maxLength' | 'regex';
export type NumberFilterInputProps = Pick<NumberFilterFieldParams, ParamsFields>
& FilterInputProps<number | string | null> & {
  filters: Record<string, unknown>;
};

const POSITIVE_NUMBER_REGEX = /[0-9(.,)+]/;
const NUMBER_REGEX = /[0-9(.,\-)+]/;

const NumberFilterInput = ({
  label, value, onChange, deleteButton, float,
  minValue, maxValue, filters, maxLength, regex,
}: NumberFilterInputProps): ReactElement | null => {
  const onNumberInputBLur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = float ? parseFloat(e.target.value.replace(',', '.')) : Math.round(parseInt(e.target.value, 10));

    const min = _isFunction(minValue) ? minValue(filters) : minValue;
    const max = _isFunction(maxValue) ? maxValue(filters) : maxValue;

    if (_isNumber(min) && parsedValue < min) {
      onChange(min);
      return;
    }

    if (_isNumber(max) && parsedValue > max) {
      onChange(max);
      return;
    }

    if (Number.isNaN(parsedValue)) {
      onChange('');
      return;
    }

    if (e.target.value && regex && !regex.test(e.target.value)) {
      return;
    }

    onChange(parsedValue);
  }, [filters, float, maxValue, minValue, onChange, regex]);

  const onNumberInputKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const min = _isFunction(minValue) ? minValue(filters) : minValue;

    if (_isNumber(min) && min >= 0 && !POSITIVE_NUMBER_REGEX.test(e.key)) {
      return e.preventDefault();
    }

    if (!NUMBER_REGEX.test(e.key)) {
      return e.preventDefault();
    }

    if (e.key === '-' && String(value).includes('-')) {
      return e.preventDefault();
    }

    if (regex && !regex.test(e.key)) {
      return e.preventDefault();
    }

    return true;
  }, [filters, minValue, value, regex]);

  const onNumberInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const min = _isFunction(minValue) ? minValue(filters) : minValue;

    if ((!min || min < 0) && e.target.value.includes('-') && !e.target.value.startsWith('-')) {
      return;
    }

    if (e.target.value && regex && !regex.test(e.target.value)) {
      return;
    }

    onChange(e.target.value);
  }, [minValue, filters, onChange, regex]);

  return (
    <Input
      placeholder={label}
      maxLength={maxLength}
      suffix={deleteButton}
      value={value ?? ''}
      className={styles.field}
      onBlur={onNumberInputBLur}
      onChange={onNumberInputChange}
      onKeyPress={onNumberInputKeyPress}
    />
  );
};

export default NumberFilterInput;

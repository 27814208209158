import React, { useEffect, useState } from 'react';
import { Form, notification } from 'antd';
import { ISMTP } from 'interfaces/ISMTP';
import { SMTPSettingsBodyType } from './types';
import { useSetSMTP, useTestSMTP, useTranslate } from 'hooks';
import { Button, StatusBadge } from 'components/index';
import { FormSMTPSettings } from './components';
import styles from './SmtpSettings.module.scss';

type SmtpSettingsProps = {
  onCloseModal: () => void;
  smtp: ISMTP;
  rootUnitId: number;
  refetchBusiness: () => void;
};

const SmtpSettings = ({ onCloseModal, smtp, rootUnitId, refetchBusiness }: SmtpSettingsProps) => {
  const { t } = useTranslate();
  const [smtpForm] = Form.useForm<SMTPSettingsBodyType>();
  const [SMTPConnected, setSMTPConnected] = useState(false);

  const { testSMTP, testSMTPAsync, isTestSMTPLoading } = useTestSMTP({
    onSuccess: (response) => {
      setSMTPConnected(response.result);
    },
  });

  const { setSMTPAsync, isSetSMTPLoading } = useSetSMTP();

  const validateFields = async () => {
    try {
      await smtpForm.validateFields();
    } catch (error) {
      notification.close('smtp-validation');
      notification.warn({
        key: 'smtp-validation',
        message: t('SMTP.fields.fillAllFields'),
      });
      return false;
    }

    return true;
  };

  const handleCheckConnection = async () => {
    if (!await validateFields()) {
      return false;
    }

    try {
      const formData = smtpForm.getFieldsValue();
      await testSMTPAsync({ ...formData, port: Number(formData.port) });
    } catch (error) {
      notification.close('smtp-validation');
      notification.error({
        key: 'smtp-validation',
        message: t('SMTP.testFailed'),
      });
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!await handleCheckConnection()) {
      return;
    }

    try {
      const fields = smtpForm.getFieldsValue();
      await setSMTPAsync({
        ...fields,
        port: Number(fields.port),
        rootUnitId,
      });
      onCloseModal();
      refetchBusiness();
    } catch (error) {
      // do nothing
    }
  };

  useEffect(() => {
    if (smtp?.host && smtp?.port && smtp?.email && smtp?.password) {
      testSMTP({
        host: smtp?.host,
        port: smtp?.port,
        email: smtp?.email,
        password: smtp?.password,
        secure: smtp?.secure,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormSMTPSettings
        form={smtpForm}
        setConnected={setSMTPConnected}
        initialValues={smtp}
      />

      <div className={styles.footer}>
        <div className={styles.statusWrapper}>
          <StatusBadge status={SMTPConnected ? 'connected' : 'not_connected'} />

          <Button
            style={{ padding: '4px 0' }}
            type="link"
            withoutBgShadow
            withoutWaveEffect
            loading={isTestSMTPLoading || isSetSMTPLoading}
            onClick={handleCheckConnection}
          >
            {t('SMTP.checkConnection')}
          </Button>
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={handleSave} loading={isTestSMTPLoading} block>
            {t('save')}
          </Button>
          <Button color="error" onClick={onCloseModal} type="link" block>
            {t('permissionsList.goBack')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmtpSettings;

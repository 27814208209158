import React, { ReactElement, useContext, useMemo } from 'react';
import { Table } from 'antd';
import clsx from 'clsx';
import { IUnit, IUnitChildren } from 'interfaces/IUnit';
import { useHandleExpandRow, useTranslate } from 'hooks';
import { useDetectTreeStartPosition } from 'components/TreeFlow/hooks/useDetectTreeStartPosition';
import { AuthorisationContext } from 'contexts';
import { setEmptyChildrenArray } from 'utils';
import getColumns from './utils/getColumns';
import { ExpandableSpace, MemoTreeFlow } from 'components/TreeFlow';
import styles from './UnitsTable.module.scss';

export type UnitsTableProps = {
  units: IUnit[] | IUnitChildren[];
  loading: boolean;
  onEditUnit: (unit: IUnit, modalType?: 'managers' | 'clients' | 'wallets' | 'default') => void;
  onFreezeUnit: (unit: IUnit) => void;
  childMode?: boolean;
};

export const UnitsTable = ({
  units,
  loading,
  childMode,
  onEditUnit,
  onFreezeUnit,
}: UnitsTableProps): ReactElement => {
  const { t } = useTranslate();
  const { checkPermissions } = useContext(AuthorisationContext);

  const { handleExpand, expandedRowKeys } = useHandleExpandRow();

  const columns = useMemo(() => getColumns({
    t,
    childMode,
    onEditUnit,
    onFreezeUnit,
    checkPermissions,
    handleExpand,
    expandedRowKeys,
  }), [t, checkPermissions, onEditUnit, onFreezeUnit, expandedRowKeys, handleExpand, childMode]);

  const { ref, width } = useDetectTreeStartPosition();
  // const withNoChildren = setEmptyChildrenArray(units);

  return (
    <div ref={ref} className={clsx(styles.wrapper, { [styles.childMode]: childMode })}>
      <Table
        rowKey="id"
        tableLayout="auto"
        columns={columns}
        pagination={false}
        dataSource={units}
        className={styles.table}
        loading={loading}
        expandable={{
          showExpandColumn: false,
          onExpand: (expanded, record) => handleExpand(record.id),
          expandedRowKeys: expandedRowKeys,
          expandedRowClassName: () => styles.expandedRow,
          expandedRowRender: (record) => (
            <ExpandableSpace>
              <MemoTreeFlow parent={record as IUnit} width={width} />
            </ExpandableSpace>
          ),
        }}
        rowClassName={(item) => clsx(styles.row, {
          [styles.expand]: expandedRowKeys.includes(item.id),
        })}
      />
    </div>
  );
};

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { IExternalUser } from 'interfaces/IExternalUser';
import { useClients, useTranslate } from 'hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { AutocompleteSelect } from 'components/AutocompleteSelect';

export interface ClientsSelectProps {
  value: number[];
  onChange: (clientIds: number[]) => void;
}

const ClientsSelect = ({ value, onChange }: ClientsSelectProps): ReactElement => {
  const [searchText, setSearchText] = useState('');
  const { payload } = useModal('units');
  const { t } = useTranslate();

  const handleErrorClients = useCallback((e: any) => {
    notification.error({
      message: e.response?.data?.message || e.message,
    });
  }, []);

  const { data, isLoading, refetch } = useClients({
    unit: true,
    sorting: [{ field: 'unit.id', direction: 'ASC' }],
    filter: {
      or: [
        { unit: { id: { is: 'null' } } }, // Free clients
        { unit: { id: { eq: payload.id } } }, // My unit clients
      ],
      and: [
        { rootUnitId: { eq: payload?.rootUnitId } }, // My business clients
        searchText ? { email: { like: `%${searchText.trim()}%` } } : {},
      ],
    },
  }, {
    onError: handleErrorClients,
  }, Boolean(payload?.rootUnitId));

  useEffect(() => {
    if (searchText) {
      refetch();
    }
  }, [refetch, searchText]);

  const clients: IExternalUser[] = useMemo(() => data?.nodes || [], [data?.nodes]);

  useEffect(() => {
    const initialClientIds = clients
      .filter(client => payload?.id && client.unit?.id === payload?.id)
      .map(client => client.id);

    onChange(initialClientIds);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  const selectedItems = value || [];

  const onClientsSelect = useCallback((id: number, checked: boolean) => {
    if (!value || !onChange) {
      return null;
    }

    if (checked) {
      const client = clients.find((item) => item.id === id);

      return client && onChange([...value, client.id]);
    }

    return onChange(value.filter((item) => item !== id));
  }, [value, onChange, clients]);

  const items = clients.map(({ id, email }) => ({ id, label: email }));

  return (
    <AutocompleteSelect
      items={items}
      loading={isLoading}
      searchText={searchText}
      selectedItems={selectedItems}
      onItemSelect={onClientsSelect}
      onSearchTextChanged={setSearchText}
      buttonLabel={t('users.teams.addClients')}
      inputPlaceholder={t('users.teams.searchClients')}
    />
  );
};

export default ClientsSelect;

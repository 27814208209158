import React, { ReactElement, useContext } from 'react';
import { Permission } from '@fanckler/processing-auth';
import clsx from 'clsx';
import { Path } from 'routes/interfaces/Path';
import { useTranslate } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useGenerateStatisticsPath } from './hooks';
import { AuthorisationContext } from 'contexts';
import AdministrationIcon from '../../icons/AdministrationIcon';
import FolderIcon from '../../icons/FolderIcon';
import ProcessingIcon from '../../icons/ProcessingIcon';
import SystemIcon from 'components/Sidebar/icons/SystemIcon';
import { MenuHead } from './components/MenuHead';
import { MenuItem } from './components/MenuItem';
import styles from './index.module.scss';

type SidebarMenuProps = {
  collapsed: boolean;
};

export const SidebarMenu = ({ collapsed }: SidebarMenuProps): ReactElement => {
  const statisticsPath = useGenerateStatisticsPath();
  const { path } = useContext(AuthorisationContext);
  const { isRoot } = useConfirmRootAdmin();
  const { t } = useTranslate();

  return (
    <div className={clsx(styles.container, { [styles.containerCollapsed]: collapsed })}>
      <MenuHead title={t('administration.title')} renderIcon={AdministrationIcon}>
        <MenuItem
          title={t('users.units.title')}
          to={path as Path}
          permissions={[Permission.ADMIN_IAM_ROOT_UNIT_READ]}
        />
        <MenuItem
          title={t('administration.financialManagement.title')}
          to={Path.ADMINISTRATION_FIN_MANAGEMENT}
          permissions={[
            Permission.CLIENT_TRANSACTION_GROUP_READ,
            Permission.CLIENT_TRANSACTION_CATEGORY_READ,
          ]}
        />
        <MenuItem
          title={t('users.replenishmentRequests.title')}
          to={Path.ADMINISTRATION_REPLENISHMENT_REQUESTS}
          permissions={[Permission.CLIENT_UNIT_READ]}
        />
        <MenuItem
          title={t('statistics.title')}
          to={statisticsPath}
          permissions={[Permission.CLIENT_UNIT_READ]}
          hide={isRoot}
        />
        <MenuItem
          title={t('administration.managers.title')}
          to={Path.ADMINISTRATION_MANAGERS}
          permissions={[Permission.ADMIN_IAM_USER_READ]}
        />
        <MenuItem
          title={t('administration.roles.title')}
          to={Path.ADMINISTRATION_ROLES}
          permissions={[Permission.ADMIN_IAM_GROUP_READ]}
        />
        {/*<MenuItem
          title={t('coldStorage.title')}
          to={Path.ADMINISTRATION_COLD_STORAGE}
          permissions={[Permission.CLIENT_COLD_STORAGE_READ]}
        />*/}
        <MenuItem
          title={t('exchangeModule.title')}
          to={Path.ADMINISTRATION_EXCHANGE_MODULE}
          permissions={[Permission.CLIENT_EXCHANGE_READ]}
        />
      </MenuHead>
      <MenuHead title={t('users.title')} renderIcon={FolderIcon}>
        <MenuItem
          title={t('users.externalUsers.title')}
          to={Path.USERS_EMPLOYEES_CLIENTS}
          permissions={[Permission.CLIENT_USER_READ]}
        />
        <MenuItem
          title={t('users.transactions.title')}
          to={Path.USERS_TRANSACTIONS}
          permissions={[Permission.CLIENT_TRANSACTION_READ]}
        />
        <MenuItem
          title={t('invoice.title')}
          to={Path.USERS_INVOICES}
          permissions={[Permission.CLIENT_INVOICE_READ]}
        />
      </MenuHead>
      <MenuHead title={t('processing.title')} renderIcon={ProcessingIcon}>
        <MenuItem
          title={t('processing.balance.title')}
          to={Path.PROCESSING_BALANCE}
          permissions={[
            Permission.PROCESSING_BALANCE_READ,
          ]}
        />
        <MenuItem
          title={t('processing.paymentOrders.title')}
          to={Path.PROCESSING_PAYMENT_ORDERS}
          permissions={[
            Permission.PROCESSING_PAYMENT_ORDER_READ,
          ]}
        />
        <MenuItem
          title={t('processing.transactions.title')}
          to={Path.PROCESSING_TRANSACTIONS}
          permissions={[
            Permission.PROCESSING_TRANSACTION_READ,
          ]}
        />
        <MenuItem
          title={t('processing.resources.title')}
          to={Path.PROCESSING_RESOURCES}
          permissions={[Permission.PROCESSING_RESOURCE_READ]}
        />
      </MenuHead>
      <MenuHead title={t('system.title')} renderIcon={SystemIcon}>
        <MenuItem
          title={t('commissions.title')}
          to={Path.SYSTEM_COMMISSIONS}
          permissions={[Permission.CLIENT_COMMISSION_READ]}
        />
        <MenuItem
          title={t('activityLog.title')}
          to={Path.SYSTEM_ACTIVITY_LOG}
          permissions={[Permission.ADMIN_IAM_LOGS_READ]}
        />
        <MenuItem
          title={t('system.firewall.title')}
          permissions={[Permission.ADMIN_IAM_ALLOWED_IP_READ]}
          to={Path.SYSTEM_FIREWALL}
        />
      </MenuHead>
    </div>
  );
};

import type { Location } from 'history';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import qs from 'qs';
import { PerPageNumber } from '../../../components/Pagination';

export type ParsePaginationFromQueryParamsProps = {
  location: Location;
  allowedPerPageOptions: number[];
};

export type ParsePaginationFromQueryParamsResult = {
  page?: number;
  perPage?: PerPageNumber;
};

const parsePaginationFromQueryParams = ({
  location,
  allowedPerPageOptions,
}: ParsePaginationFromQueryParamsProps): ParsePaginationFromQueryParamsResult => {
  const { page, perPage } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const parsedPage = _isString(page) ? parseInt(page, 10) : null;
  const parsedPerPage = _isString(perPage) ? parseInt(perPage, 10) : null;

  return {
    page: _isNumber(parsedPage) && parsedPage > 0 ? parsedPage : undefined,
    perPage: _isNumber(parsedPerPage) && allowedPerPageOptions.includes(parsedPerPage)
      ? parsedPerPage as PerPageNumber
      : undefined,
  };
};

export default parsePaginationFromQueryParams;

import {
  NotificationFilters,
  NotificationPayload,
  SET_NOTIFICATION_FILTERS,
  SET_NOTIFICATION_PAYLOAD,
  SET_NOTIFICATION_VISIBLE,
  SetNotificationFilters,
  SetNotificationPayload,
  SetNotificationVisible,
} from '../types/Actions';

export const setNotificationVisible = (
  payload: boolean,
): SetNotificationVisible => ({
  type: SET_NOTIFICATION_VISIBLE,
  payload,
});

export const setNotificationPayload = (
  payload: NotificationPayload,
): SetNotificationPayload => ({
  type: SET_NOTIFICATION_PAYLOAD,
  payload,
});

export const setNotificationFilters = (
  payload: NotificationFilters,
): SetNotificationFilters => ({
  type: SET_NOTIFICATION_FILTERS,
  payload,
});

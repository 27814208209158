import { useEffect, useState } from 'react';

export enum ImageStatusEnum {
  LOADING,
  SUCCESS,
  ERROR,
  IDLE,
}

export type UseCheckImageParams = {
  src: string;
  base?: string;
};

const DEFAULT_BASE = process.env.REACT_APP_AUTH_URI as string;

const useCheckImage = ({ src, base = DEFAULT_BASE }: UseCheckImageParams) => {
  const [status, setStatus] = useState<ImageStatusEnum>(ImageStatusEnum.IDLE);

  useEffect(() => {
    if (!src) return setStatus(ImageStatusEnum.IDLE);

    const image = new Image();

    setStatus(ImageStatusEnum.LOADING);

    image.onerror = () => {
      setStatus(ImageStatusEnum.ERROR);
    };

    image.onload = () => {
      setStatus(ImageStatusEnum.SUCCESS);
    };

    image.src = base.concat(src);

  }, [base, src]);

  return status;
};

export default useCheckImage;

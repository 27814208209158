import _ from 'lodash';

interface ReturnType {
  key: string;
  value: number | string;
}

const getHighestSignal = (signals: ReturnType[]): ReturnType => {
  const sortedSignals = _.sortBy(signals, (signal) => signal.value);
  const lastSignal = _.last(sortedSignals);
  return lastSignal || { key: '', value: '' };
};

export default getHighestSignal;

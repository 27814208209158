import React, { ReactElement, useState } from 'react';
import { Button, TimePicker, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { ExpiryTimeType } from 'components/CreateInvoice/types';
import { useTranslate } from 'hooks';
import { generateTimeOptions } from './utils/generateTimeOptions';
import { MAX_INVOICE_DAYS } from '../../CreateInvoiceForm';
import styles from './CustomExpiryTime.module.scss';

export type CustomExpiryTimeProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export const CustomExpiryTime = ({
  value,
  onChange,
}: CustomExpiryTimeProps): ReactElement | null => {
  const { t } = useTranslate();

  const [time, setTime] = useState<Moment | null>(() => {
    if (!value) return null;

    if (value?.includes('custom')) {
      const initialTime: ExpiryTimeType = JSON.parse(value.split('|')[1]);
      return moment().hours(initialTime.days).minutes(initialTime.hours);
    } else {
      const initialTime: ExpiryTimeType = JSON.parse(value);
      return moment().hours(initialTime.days).minutes(initialTime.hours);
    }
  });

  const disableTime = () => ({
    disabledHours: () => generateTimeOptions(MAX_INVOICE_DAYS, 24),
    disabledMinutes: (hour: number) => generateTimeOptions(hour === MAX_INVOICE_DAYS ? 0 : 23, 60, hour !== MAX_INVOICE_DAYS),
  });

  const formatTime = (date: Moment) => (
    `${Math.floor(date.hours())} days ${date.minutes()} hours`
  );

  const onChangeTime = (dateValue: Moment | null) => {
    if (!dateValue) return setTime(null);

    const days = dateValue.hours();
    const hours = days === MAX_INVOICE_DAYS ? 0 : dateValue.minutes();

    setTime(moment().hours(days).minutes(hours));

    return onChange && onChange(`custom|${JSON.stringify({ days, hours })}`);
  };

  const onSetMaxTime = () => {
    setTime(moment().hours(MAX_INVOICE_DAYS).minutes(0));

    return onChange && onChange(`custom|${JSON.stringify({ days: MAX_INVOICE_DAYS, hours: 0 })}`);
  };

  if (!value?.includes('custom')) return null;

  return (
    <div className={styles.container}>
      <TimePicker
        value={time}
        showNow={false}
        showSecond={false}
        format={formatTime}
        hideDisabledOptions
        disabledTime={disableTime}
        onChange={onChangeTime}
        className={styles.timePicker}
      />

      <Tooltip
        title={t('invoice.tooltip.maxPeriodDays', { variables: { days: `${MAX_INVOICE_DAYS}` } })}
      >
        <Button
          type="link"
          onClick={onSetMaxTime}
          className={styles.maxButton}
        >
          Max
        </Button>
      </Tooltip>
    </div>
  );
};

import { useEffect, useState } from 'react';
import LocalStorage, { LocalStorageKey } from '../utils/localStorage';

const useDisplayedColumns = (
  storageKey: LocalStorageKey,
  defaultColumns?: string[],
): [string[], React.Dispatch<React.SetStateAction<string[]>>] => {
  const [displayedColumns, setDisplayedColumns] = useState<string[]>(() => {
    const storage = LocalStorage.get(storageKey);

    return Array.isArray(storage) ? storage : (defaultColumns || []);
  });

  useEffect(() => {
    LocalStorage.set(storageKey, displayedColumns);
  }, [displayedColumns, storageKey]);

  return [displayedColumns, setDisplayedColumns];
};

export default useDisplayedColumns;

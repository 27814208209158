import axios from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import {
  GroupCreateVars,
  GroupDeleteVars,
  GroupUpdateVars,
  OptionType,
  TranGroupMutateReturn,
} from '../types';

export const useGroupMutations = (options?: OptionType): TranGroupMutateReturn => {
  const createGroup = async ({
    name, unitIds, rootUnitId,
    description, color,
  }: GroupCreateVars) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/group`,
      { name, unitIds, rootUnitId, color, description },
    );
    return data;
  };

  const updateGroup = async ({
    id, name,
    rootUnitId, unitIds,
    color, description,
  }: GroupUpdateVars) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/group/${id}`,
      { name, unitIds, rootUnitId, color, description },
    );
    return data;
  };

  const removeGroup = async ({ id }: GroupDeleteVars) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/group/${id}`);
    return data;
  };

  const {
    mutate: mutateCreateGroup,
    isPending: isCreatingGroup,
  } = useMutation({ mutationFn: createGroup, ...options });

  const {
    mutate: mutateUpdateGroup,
    isPending: isUpdatingGroup,
  } = useMutation({ mutationFn: updateGroup, ...options });

  const {
    mutate: mutateRemoveGroup,
    isPending: isRemovingGroup,
  } = useMutation({ mutationFn: removeGroup, ...options });

  return {
    mutateCreateGroup, isCreatingGroup,
    mutateUpdateGroup, isUpdatingGroup,
    mutateRemoveGroup, isRemovingGroup,
  };
};

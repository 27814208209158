import numeral from 'numeral';

const expandExponential = (number: number) => {
  const strNumber = number.toString();

  if (strNumber.includes('e')) {
    const [base, exponent] = strNumber.split('e');
    const expandedNumber = parseFloat(base) * 10 ** parseInt(exponent);
    return expandedNumber;
  } else {
    return number;
  }
};

export const parseDigit = (digit: number, postfix?: string): string => {
  // eslint-disable-next-line
  const parsedDigit = numeral(digit).format('0.00').split('.').join('\, ');

  if (postfix) {
    return `${parsedDigit} ${postfix}`;
  }
  return parsedDigit;
};

export const normalizeAmount = (num: number | string) => {
  const number = typeof num === 'string' ? Number(num) : num;
  // const expandedNumber = expandExponential(number);

  if (number === 0) {
    return '0.00';
  } else if (Math.floor(number) !== number) {
    return number.toString();
  } else {
    return number.toFixed(2);
  }
};

import React, { ReactElement } from 'react';
import { Button, Image, Typography } from 'antd';
import { useTranslate } from 'hooks';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './FormScanQR.module.scss';

export type FormScanQRProps = {
  qrCode: string;
  onBack?: () => void;
  onContinue?: () => void;
};

export const FormScanQR = ({
  qrCode,
  onBack,
  onContinue,
}: FormScanQRProps): ReactElement => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <Image
        src={qrCode}
        draggable={false}
        preview={false}
        className={styles.image}
      />

      <div className={styles.rightInfo}>
        <Typography.Text className={styles.text}>
          {t('QR.scanAndPress')}
        </Typography.Text>

        <div className={styles.buttons}>
          <Button
            type="primary"
            onClick={onContinue}
            className={styles.button}
          >
            {t('continue')}
          </Button>

          {onBack && (
            <Button
              type="ghost"
              onClick={onBack}
              icon={<ArrowLeftOutlined className={styles.icon} />}
              className={styles.backButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

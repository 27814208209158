import React, { FC, useId, useMemo, useState } from 'react';
import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { CurrencyIcon } from 'utils/currencyIcon';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './CurrencySelect.module.scss';

export type CurrencySelectPropsType<T> = {
  options: ({ value: string, label: string, disabled?: boolean } & T)[];
  onSelect: (currency: string) => void;
  currency: string | undefined;
  wrapperWidth?: number | string;
  trimHeight?: boolean;
  withoutIcon?: boolean;
  mode?: 'default' | 'filter';
  isDisabled?: boolean;
  className?: string;
  withoutPopupContainer?: boolean;
  dropdownAlign?: { offset: [number, number] },
  CustomCurrencyLabel?: FC<{ value: string, label: string, disabled?: boolean } & T>;
  popupClassName?: string;
};

export const getCurrencyLabelJSX = (currency: string, label?: string) => {
  return (
    <span className={styles.optionLabel}>
      {currency && <img src={CurrencyIcon[currency]} className={styles.currencyIcon} alt={currency} />}
      {label || currency}
    </span>
  );
};

function CurrencySelect<T>({
  options,
  currency,
  onSelect,
  trimHeight,
  withoutIcon,
  mode = 'default',
  wrapperWidth = 150,
  isDisabled,
  className,
  withoutPopupContainer,
  dropdownAlign,
  CustomCurrencyLabel,
  popupClassName,
}: CurrencySelectPropsType<T>, props: SelectProps) {
  const id = useId();
  const { t } = useTranslate();
  const [isOpen, setOpen] = useState(false);

  const suffixIcon = isOpen
    ? <UpOutlined className={styles.arrow} />
    : <DownOutlined className={styles.arrow} />;

  const currencyOptions = useMemo(() => options.map((option) => {
    const { value, label, disabled } = option;
    return ({
      value,
      disabled,
      label: CustomCurrencyLabel ?
        <CustomCurrencyLabel {...option} /> : withoutIcon ? label : getCurrencyLabelJSX(value, label),
    });
  }), [CustomCurrencyLabel, options, withoutIcon]);

  const wrapperCls = clsx(styles.wrapper, {
    [styles.filterMode]: mode === 'filter',
    [styles.trimHeight]: trimHeight,
  });

  return (
    <div
      id={id}
      style={{ width: wrapperWidth }}
      className={clsx(wrapperCls, className)}
    >
      <Select
        {...props}
        bordered={false}
        dropdownAlign={dropdownAlign}
        value={currency}
        onSelect={onSelect}
        suffixIcon={suffixIcon}
        options={currencyOptions}
        popupClassName={clsx(styles.popup, popupClassName)}
        onDropdownVisibleChange={setOpen}
        className={clsx(styles.select)}
        disabled={isDisabled}
        placeholder={t('selectCurrency')}
        {...(!withoutPopupContainer ? {
          getPopupContainer: () => document.getElementById(id) as HTMLElement,
        } : {})}
      />
    </div>
  );
}

export {
  CurrencySelect,
};

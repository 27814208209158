import React, { useCallback } from 'react';
import { Empty, Spin, Typography } from 'antd';
import { IUnit } from 'interfaces/IUnit';
import { IWallet } from 'types/IWallet';
import { CreateWalletDTO } from 'pages/Administration/Units/components/NestedUnit/components/Wallets/hooks/useCreateWallet';
import { useTranslate } from 'hooks';
import { useCreateWallet, useWalletFilter } from './hooks';
import { Actions, NetworkSelection, WalletItem } from './components';
import styles from './WalletList.module.scss';

type WalletListProps = {
  unit: IUnit;
  isLoading: boolean;
  onRefresh: () => Promise<void>;
};

export const WalletList = ({
  unit,
  isLoading,
  onRefresh,
}: WalletListProps) => {
  const { t } = useTranslate();
  const {
    selectedNetworks,
    toggleFavoriteWallet,
    favoriteWallets,
    filteredWallets,
    toggleSelectedNetwork,
    onWalletWillCreate,
  } = useWalletFilter(unit?.wallets || []);

  const {
    createWallet,
    isLoading: isCreatingWallet,
  } = useCreateWallet({ unitId: unit?.id, uuid: unit?.uuid });

  const createWalletWrapper = useCallback((args: CreateWalletDTO) => {
    createWallet(args);
    onWalletWillCreate(args);
  }, [createWallet, onWalletWillCreate]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <Typography.Title className={styles.title}>
          {t('users.externalUser.wallets')}
        </Typography.Title>

        <NetworkSelection
          favoriteWallets={favoriteWallets || []}
          wallets={unit?.wallets || []}
          selectedNetworks={selectedNetworks || []}
          onToggleSelectedNetwork={toggleSelectedNetwork}
        />

        {!isLoading && (
          <Actions
            unit={unit}
            onRefresh={onRefresh}
            disabled={!unit?.isActive}
            createWallet={createWalletWrapper}
            isCreatingWallet={isCreatingWallet}
          />
        )}
      </div>

      {isLoading && <Spin className={styles.loader} />}

      <div className={styles.list}>
        {!isLoading && !filteredWallets.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.emptyPlaceholder} />
        ) : (
          (filteredWallets || []).map((wallet: IWallet) => (
            <WalletItem
              key={wallet.id}
              balance={wallet.balance}
              payway={wallet.network}
              currency={wallet.currency}
              walletAddress={wallet.walletAddress}
              isFavorite={favoriteWallets.includes(`${wallet.currency}/${wallet.network}`)}
              onToggleFavorite={() => toggleFavoriteWallet(`${wallet.currency}/${wallet.network}`)}
              riskScore={wallet.riskScore}
            />
          ))
        )}
      </div>
    </section>
  );
};

import React, {
  ReactElement, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Permission } from '@fanckler/processing-auth';
import { notification } from 'antd';
import { IUser } from 'interfaces/IUser';
import { useUsers } from 'pages/Administration/Managers/hooks/useUsers';
import { AuthorisationContext } from 'contexts/AuthorisationContext';
import { AutocompleteSelect } from 'components/AutocompleteSelect';

export interface AdminsSelectProps {
  buttonPlaceholder: string;
  inputPlaceholder: string;
  value?: number[];
  onChange?: (admins: number[]) => void;
  businessId?: number | null;
}

const AdminsSelect = ({
  value,
  onChange,
  inputPlaceholder,
  buttonPlaceholder,
  businessId = null,
}: AdminsSelectProps): ReactElement => {
  const { user } = useContext(AuthorisationContext);

  const [searchText, setSearchText] = useState('');

  const handleErrorUsers = useCallback((e: any) => {
    notification.error({
      message: e.response?.data?.message || e.message,
    });
  }, []);

  const { data, isLoading, refetch } = useUsers({
    sorting: [
      { field: 'rootUnitId', direction: 'DESC' },
      { field: 'group.rootUnitId', direction: 'DESC' },
    ],
    filter: {
      and: [
        searchText ? { name: { ilike: `%${searchText.trim()}%` } } : {},
        { group: { rootUnitId: { isNot: 'null' } } },
        { rootUnitId: businessId ? { eq: businessId } : { isNot: 'null' } },
        { groupId: { neq: 1 } },
      ],
    },
  }, {
    onError: handleErrorUsers,
  });

  useEffect(() => {
    if (searchText) {
      refetch();
    }
  }, [refetch, searchText]);

  // const debouncedRefetchSelectedBusiness = useRef(debounce(refetch, 300));

  // useEffect(() => {
  //   if (businessId !== undefined) {
  //     debouncedRefetchSelectedBusiness.current();
  //   }
  // }, [businessId]);

  const selectedItems = (value || []);
  const admins: IUser[] = useMemo(() => data?.nodes || [], [data?.nodes]);

  const onSelect = useCallback((id: number, checked: boolean) => {
    if (!value || !onChange) {
      return null;
    }

    if (checked) {
      return onChange([...value, id]);
    }

    return onChange(value.filter((item) => item !== id));
  }, [onChange, value]);

  return (
    <AutocompleteSelect
      loading={isLoading}
      searchText={searchText}
      buttonLabel={buttonPlaceholder}
      selectedItems={selectedItems}
      onItemSelect={onSelect}
      onSearchTextChanged={setSearchText}
      inputPlaceholder={inputPlaceholder}
      items={admins.map(({ id, name, group }) => {
        const isAdminOfRootUnit = group.permissions.some(
          ({ name: permission }: { name: Permission }) => permission === Permission.CLIENT_UNIT_CREATE,
        );

        return { id, label: name, disabled: id === user?.id || isAdminOfRootUnit };
      })}
    />
  );
};

export default AdminsSelect;

import { IStatus } from '../../interfaces/IStatus';
import { ColorEnum } from '../../types';
import { TransactionTypeEnum } from 'pages/Processing/TransactionsFiat/types';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SwapOutlined,
  SyncOutlined } from '@ant-design/icons';

export const getTxTypes = (t: TranslateFunc): IStatus[] => [
  {
    status: TransactionTypeEnum.DEPOSIT,
    color: ColorEnum.SUCCESS,
    icon: ArrowLeftOutlined,
  },
  {
    status: TransactionTypeEnum.WITHDRAWAL,
    color: ColorEnum.ERROR,
    icon: ArrowRightOutlined,
  },
  {
    status: TransactionTypeEnum.TRANSFER,
    color: ColorEnum.IDLE,
    icon: SwapOutlined,
  },
  {
    status: TransactionTypeEnum.SINGLE_AGGREGATION,
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: TransactionTypeEnum.LITTLE_AGGREGATION,
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
];

export const generateColor = (percentage: number) => {
  if (percentage === 0) {
    return 'var(--gray-2-color)';
  } else if (percentage <= 25) {
    return 'var(--green-status-color)';
  } else if (percentage > 25 && percentage < 80) {
    return 'var(--orange-status-color)';
  } else if (percentage >= 80) {
    return 'var(--red-2-error-color)';
  }
  return 'var(--black-color)';
};

import React, { ReactElement } from 'react';

export function SettingsIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#1DA1F2"
        d="M22.297 7.777c-.172-.718-1.079-.959-1.601-.437l-2.928 2.928a2.5 2.5 0
        01-3.709-.192c-.828-1.012-.651-2.52.274-3.445l2.826-2.826c.522-.522.282-1.43-.436-1.602a7.479 7.479 0 00-3.349-.027c-2.911.632-5.231
        3.016-5.752 5.949a7.592 7.592 0 00.259 3.734l-4.392 4.379a3.375 3.375 0 004.773 4.773l4.398-4.385a7.586 7.586 0
        003.714.251c2.934-.521 5.318-2.84 5.949-5.752a7.475 7.475 0 00-.026-3.348z"
        opacity="0.35"
      />
      <path fill="#1DA1F2" d="M6 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
    </svg>
  );
}

import React, { ReactElement, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import type { IRoute } from '../../routes/interfaces/IRoute';
import { Path } from '../../routes/interfaces/Path';
import { withInactivityCheck } from 'hocs';
import AlertingProvider from 'contexts/AlertingContext/AlertingProvider';
import { AuthorisationContext, SocketProvider } from '../../contexts';
import { CommonLayout } from '../CommonLayout';
import { Home } from '../Home';
import { ICommonLayoutProps } from 'components/CommonLayout/CommonLayout';
import { RequireAuth } from './components';

export interface IRouterProps {
  routes: IRoute[];
}

const CommonLayoutWithInactivityCheck = withInactivityCheck<ICommonLayoutProps>(React.memo(CommonLayout), {
  inactivityDuration: process.env.REACT_APP_AFK_DETECT_TIMER ? +process.env.REACT_APP_AFK_DETECT_TIMER : 10,
  popupDuration: process.env.REACT_APP_AFK_POPUP_TIMER ? +process.env.REACT_APP_AFK_POPUP_TIMER : 30,
  awayDuration: 5,
});

const Router = ({ routes }: IRouterProps): ReactElement => {
  const { token, checkPermissions } = useContext(AuthorisationContext);

  return (
    <Routes>
      <Route
        path={Path.HOME}
        element={(
          <RequireAuth>
            <Home />
          </RequireAuth>
        )}
      />
      {routes.map(({
        path, isPrivate, component, withHeader, withSideBar, permissions,
      }) => {
        if (permissions && !checkPermissions(permissions)) {
          return null;
        }
        return (isPrivate ? (
          <Route
            key={path}
            path={path}
            element={(
              <RequireAuth>
                <SocketProvider>
                  <AlertingProvider>
                    <CommonLayoutWithInactivityCheck
                      withHeader={withHeader}
                      withSidebar={withSideBar}
                      component={component}
                    />
                  </AlertingProvider>
                </SocketProvider>
              </RequireAuth>
            )}
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={<CommonLayout withHeader={withHeader} withSidebar={withSideBar} component={component} />}
          />
        ));
      })}
      <Route
        path="*"
        element={(
          <Navigate to={token ? Path.HOME : Path.LOGIN} />
        )}
      />
    </Routes>
  );
};

export default Router;

import React, { ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as AntdButton, Typography } from 'antd';
import { Path } from 'routes/interfaces/Path';
import { useTranslate } from 'hooks';
import { AuthorisationContext } from 'contexts';
import { getAvatarUrl } from 'utils/getAvatarUrl';
import { UserAvatar } from '../../../UserAvatar';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { Button } from 'components';
import styles from './index.module.scss';

type SidebarSettingsType = {
  collapsed: boolean;
};

export const SidebarSettings = ({ collapsed }: SidebarSettingsType): ReactElement => {
  const { user, logout } = useContext(AuthorisationContext);
  const navigate = useNavigate();
  const { t } = useTranslate();

  if (collapsed) {
    return (
      <>
        <UserAvatar
          name={user?.name || 'A'}
          src={getAvatarUrl(user)}
        />
        <AntdButton
          type="text"
          size="large"
          shape="circle"
          icon={<SettingsIcon />}
          onClick={() => navigate(Path.MY_ACCOUNT)}
          className={styles.editProfileCollapsed}
        />
        <AntdButton
          type="text"
          size="large"
          shape="circle"
          icon={<LogoutIcon />}
          onClick={() => logout()}
          className={styles.logoutCollapsed}
        />
      </>
    );
  }

  return (
    <div>
      <div className={styles.avatarContainer}>
        <UserAvatar
          name={user?.name || 'A'}
          src={getAvatarUrl(user)}
        />
        <div className={styles.info}>
          <Typography.Text className={styles.fullName}>
            {user?.name}
          </Typography.Text>
          <Typography.Text className={styles.description}>{user?.group?.name}</Typography.Text>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button
          block
          shape="round"
          textSize="15px"
          style={{ padding: '10px 15px' }}
          onClick={() => navigate(Path.MY_ACCOUNT)}
          className={styles.editProfile}
        >
          {t('editProfile')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          withoutBgShadow
          onClick={() => logout()}
        >
          {t('logout')}
        </Button>
      </div>
    </div>
  );
};

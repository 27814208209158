export enum WalletCurrencyEnum {
  BTC = 'BTC',
  ETH = 'ETH',
  TRX = 'TRX',
  USDT = 'USDT',
  USDC = 'USDC',
  BNB = 'BNB',
  BUSD = 'BUSD',
  DAI = 'DAI',
  EUR = 'EUR',
}

import React, { ReactElement } from 'react';
import { Spin, Tooltip } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { ReactComponent as SnowflakeIcon } from 'assets/icons/Snowflake.svg';
import styles from './FreezeButton.module.scss';

export interface IFreezeButtonProps {
  isActive: boolean;
  onFreeze: () => void;
  isLoading: boolean;
  size?: number | string;
}

export const FreezeButton = ({
  size = 30,
  isActive,
  onFreeze,
  isLoading,
}: IFreezeButtonProps): ReactElement => {
  const { t } = useTranslate();

  const tooltipTitleJSX = isActive
    ? t('users.units.deactivateUnit')
    : t('users.units.activateUnit');

  return (
    <div className={styles.wrapper}>
      {!isLoading ? (
        <Tooltip title={tooltipTitleJSX}>
          <SnowflakeIcon
            cursor="pointer"
            onClick={onFreeze}
            style={{ width: size, height: size }}
            className={clsx(styles.icon, { [styles.active]: isActive })}
          />
        </Tooltip>
      ) : (
        <Spin className={styles.loader} />
      )}
    </div>
  );
};

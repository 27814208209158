import React, { lazy } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';
import { NestedUnit } from 'pages/Administration/Units/components/NestedUnit';

const Units = lazy(() => import('pages/Administration/Units/Units'));
const Managers = lazy(() => import('pages/Administration/Managers/Managers'));
const AdministrationGroups = lazy(() => import('pages/Administration/AdministrationGroups/AdministrationGroups'));
const ReplenishmentRequests = lazy(() => import('pages/Administration/ReplenishmentRequests/ReplenishmentRequests'));
const FinancialManagement = lazy(() => import('../pages/Administration/FinancialManagement/FinancialManagement'));
const Businesses = lazy(() => import('pages/Administration/Businesses/Businesses'));
const Statistics = lazy(() => import('pages/Administration/Statistics/Statistics'));
const ColdStorage = lazy(() => import('pages/Administration/ColdStorage'));
const ExchangeModule = lazy(() => import('pages/Administration/ExchangeModule/ExchangeModule'));

const administrationRoutes: IRoute[] = [
  {
    path: `${Path.ADMINISTRATION_MANAGERS}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_USER_READ],
    component: () => <Managers />,
  },
  {
    path: `${Path.ADMINISTRATION_FIN_MANAGEMENT}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [
      Permission.CLIENT_TRANSACTION_GROUP_READ,
      Permission.CLIENT_TRANSACTION_CATEGORY_READ,
    ],
    component: () => <FinancialManagement />,
  },
  {
    path: `${Path.ADMINISTRATION_COLD_STORAGE}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_COLD_STORAGE_READ],
    component: () => <ColdStorage />,
  },
  {
    path: `${Path.ADMINISTRATION_ROLES}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_GROUP_READ],
    component: () => <AdministrationGroups />,
  },
  {
    path: `${Path.ADMINISTRATION_BUSINESSES}`,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.ADMIN_IAM_ROOT_UNIT_READ],
    component: () => <Businesses />,
  },
  {
    path: Path.ADMINISTRATION_REPLENISHMENT_REQUESTS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <ReplenishmentRequests />,
  },
  {
    path: Path.ADMINISTRATION_BUSINESSES_UNITS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <Units />,
  },
  {
    path: Path.ADMINISTRATION_BUSINESSES_UNITS_UNIT_IDS,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <NestedUnit />,
  },
  {
    path: Path.ADMINISTRATION_STATISTICS_ROOT_UNIT_ID,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_UNIT_READ],
    component: () => <Statistics />,
  },
  {
    path: Path.ADMINISTRATION_EXCHANGE_MODULE,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    permissions: [Permission.CLIENT_EXCHANGE_READ],
    component: () => <ExchangeModule />,
  },
];

export default administrationRoutes;

import { useCallback } from 'react';
import axios from 'libs/axios';
import { UnknownType } from 'types/Unknown';

const detectExpiredSession = (error: UnknownType) => {
  const case1 = error.response?.status === 401
    && !error.config.url.includes('auth/current-user')
    && !error.config.url.includes('auth/login');
  const case2 = (error.response?.status === 400 || error.response?.status === 401)
    && (error.config.url.includes('auth/refresh-tokens') || error.config.url.includes('auth/logout'));

  return case1 || case2;
};

export const useExpiredSessionInterceptor = (logout: (options?: { message?: string }) => void) => {
  const handleError = useCallback((error: UnknownType) => {
    return detectExpiredSession(error)
      ? logout({ message: 'The session has expired' }) // TODO: add translation
      : Promise.reject(error);
  }, [logout]);

  axios.interceptors.response.use(response => response, handleError);
};

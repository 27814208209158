import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { notification, Table } from 'antd';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { AppState } from 'init/rootReducer';
import { IReplenishment } from 'interfaces/IReplenishment';
import { IUnit } from 'interfaces/IUnit';
import { LogActionEnum } from '../../../../../../types/LogAction';
import { ActionModalType } from 'components/Modal/types/Modal';
import { FormVerifyRequest } from 'pages/Administration/ReplenishmentRequests/components';
import { useReplenishmentMutations } from 'pages/Administration/ReplenishmentRequests/hooks';
import { setReplenishmentRequest } from 'pages/Administration/Units/actions';
import { useLogMutation, useTablePagination, useTranslate } from 'hooks';
import { StageEnum, use2FA } from 'hooks/use2FA';
import { useReplenishmentsById } from './hooks/useReplenishmentsById';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts';
import { getColumns } from './utils/getColumns';
import { moveItemsToFront } from './utils/moveItemsToFront';
import { Form2FA, FormScanQR, Modal, Pagination } from 'components';
import { AuditOutlined } from '@ant-design/icons';
import styles from './PendingApprovalTable.module.scss';

export enum ReplenishmentFormStep {
  CREATE = 'create',
  QR_CODE = 'qrCode',
  OTP_CODE = 'otpCode',
}

type PendingApprovalsTableProps = {
  unit: IUnit;
  loading: boolean;
  isTabActive: boolean;
};

export const PendingApprovalsTable = ({
  unit,
  loading,
  isTabActive,
}: PendingApprovalsTableProps): ReactElement => {
  const [actionType, setActionType] = useState<'approve' | 'decline' | null>(null);
  const { isRequestCreated } = useSelector((state: AppState) => state.unitReducer);
  const { checkPermissions } = useContext(AuthorisationContext);
  // const { createLog } = useLogMutation();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const { page, perPage, setPage, setPerPage } = useTablePagination({ withoutURL: true });

  const {
    qrcode,
    stage, setStage,
    isDisabled, setDisabled,
    prevStage, setPrevStage,
    onError, onSuccess,
  } = use2FA();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  } = useModal('replenishmentRequests');

  const {
    data,
    refetch,
    isLoading: isRequestsLoading,
  } = useReplenishmentsById(unit?.uuid, {
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    paging: { limit: perPage, offset: (page - 1) * perPage },
  });

  useEffect(() => {
    if (isRequestCreated && isTabActive) {
      refetch();
      dispatch(setReplenishmentRequest(false));
    }
  }, [dispatch, refetch, isTabActive, isRequestCreated]);

  const { approveRequest, declineRequest } = useReplenishmentMutations({
    onSuccess: async (_, variables) => {
      onSuccess(_, async () => {
        onModalClose();

        if (variables?.type === 'approve') {
          // await createLog({ action: LogActionEnum.APPROVE_REPLENISHMENT_REQUEST, payload: JSON.stringify(variables) });
          notification.success({
            message: `${t('users.replenishmentRequests.title')} "${variables.id}"`,
            description: t('users.replenishmentRequests.approved', {
              variables: { id: `${variables.id}` },
            }),
          });
        } else {
          // await createLog({ action: LogActionEnum.DECLINE_REPLENISHMENT_REQUEST, payload: JSON.stringify(variables) });
          notification.success({
            message: `${t('users.replenishmentRequests.title')} "${variables?.id}"`,
            description: t('users.replenishmentRequests.declined', {
              variables: { id: `${variables?.id}` },
            }),
          });
        }
        await refetch();
      });
    },
    onError: async (e: any, variables) => {
      setActionType(variables?.type || null);

      onError(e, () => {
        notification.error({
          description: e.response.data.message,
          message: e.response.statusText,
        });
      });
    },
  });

  const openVerifyModal = useCallback(
    (request: IReplenishment) => {
      batch(() => {
        onModalOpen();
        onSetModalPayload({
          ...request,
        });
        onSetModalType(ActionModalType.EDIT);
      });
    },
    [onModalOpen, onSetModalPayload, onSetModalType],
  );

  const isApprove = checkPermissions([Permission.CLIENT_UNIT_UPDATE]);
  const replenishmentRequests = data?.nodes || [];
  const totalCount = data?.totalCount || 0;
  const wallets = unit?.wallets;

  const columns = useMemo(() =>
    getColumns({
      wallets,
      currentUnit: unit,
      uuid: unit?.uuid,
      isApprove,
      t,
      openVerifyModal,
      onRefresh: refetch,
      onDecline: declineRequest,
    }),
  [wallets, unit, isApprove, t, openVerifyModal, refetch, declineRequest]);

  const sortedReplenishmmentRequests = moveItemsToFront(replenishmentRequests, unit?.uuid);

  const getRowClassName = (record: IReplenishment) => {
    if (record.unit.uuid === unit?.uuid) {
      return `${styles.row} ${styles.highlighted}`;
    }
    return styles.row;
  };

  const onSubmitCode = (code: string) => {
    if (!isEmpty(payload)) {
      setDisabled(true);

      if (actionType === 'approve') {
        approveRequest({ id: payload.id as number, type: 'approve', otpCode: code });
      } else {
        declineRequest({ id: payload.id as number, type: 'decline', otpCode: code });
      }
    }
  };

  return (
    <div
      className={clsx(styles.wrapper, { [styles.empty]: !totalCount })}
      style={{ height: 'calc(250px + 54px + 40px + 20px)' }} // table-body + table-header + pagination + gap height
    >
      <Table
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ y: totalCount && !isRequestsLoading ? 250 : 'calc(250px + 40px + 20px)' }} // table-body + pagination + gap height
        rowClassName={getRowClassName}
        loading={isRequestsLoading || loading}
        expandable={{ showExpandColumn: false }}
        dataSource={sortedReplenishmmentRequests}
        showHeader={!isRequestsLoading && !loading && !!totalCount}
      />

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isRequestsLoading || !totalCount}
        disablePerPageHandler={isRequestsLoading || !totalCount}
        elementsSize="small"
      />

      <Modal
        width={450}
        isOpen={isOpen}
        onClose={onModalClose}
        title={t('users.replenishmentRequests.approveOrDecline')}
        titleIcon={<AuditOutlined />}
        destroyOnClose
      >
        <>
          {stage === StageEnum.DEFAULT && (
            <FormVerifyRequest
              onApprove={approveRequest}
              onDecline={declineRequest}
            />
          )}

          {(stage === StageEnum.QR_CODE && !!qrcode) && (
            <FormScanQR
              qrCode={qrcode}
              onBack={()=>setStage(StageEnum.DEFAULT)}
              onContinue={() => {
                setPrevStage(StageEnum.QR_CODE);
                setStage(StageEnum.OTP_CODE);
              }}
            />
          )}

          {stage === StageEnum.OTP_CODE && (
            <Form2FA
              isLoading={isRequestsLoading || isDisabled}
              onSubmit={onSubmitCode}
              onBack={()=>setStage(prevStage)}
            />
          )}
        </>
      </Modal>
    </div>
  );
};

import React from 'react';
import { Empty, Space, Spin } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { Pagination, PerPageNumber } from 'components/Pagination';
import styles from './NotificationList.module.scss';

const NotificationList = ({
  children,
  isLoading,
  totalCount,
  page,
  setPage,
  perPage,
  setPerPage,
}: {
  children: React.ReactNode[];
  isLoading: boolean;
  totalCount: number;
  page: number;
  setPage: (value: number) => void;
  perPage: PerPageNumber;
  setPerPage: (value: PerPageNumber) => void;
}) => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <Space direction="vertical" className={styles.list}>
        {!isLoading && !totalCount ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={styles.emptyImage}
            description={t('notifications.empty')}
          />
        ) : (
          <div className={clsx(styles.content, { [styles.loading]: isLoading })}>
            {children} {isLoading && <Spin className={styles.loader} />}
          </div>
        )}
      </Space>

      <div className={styles.pagination}>
        <Pagination
          perPageItems={[10, 15, 25]}
          page={page}
          perPage={perPage}
          onPageChange={setPage}
          totalCount={totalCount}
          onPerPageChange={setPerPage}
          disablePageChange={isLoading || !totalCount}
          disablePerPageHandler={isLoading || !totalCount}
          elementsSize="xs"
        />
      </div>
    </div>
  );
};

export default NotificationList;

import { useCallback, useMemo } from 'react';
import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IRate } from 'pages/Processing/Balance/utils/getWalletsTotalBalance';
import { unifyTotalBalancesStructure, unifyWalletsStructure } from 'utils';
import { convertCurrency } from 'utils/convertCurreny';

export interface CurrencyRate {
  addtime: number;
  input: string;
  output: string;
  rate: [number, number];
  source: string;
}

export interface Wallet {
  currency: string;
  balance?: number;
  all?: number;
}

export const useCurrencyRate = () => {
  const getCurrencyRate = useCallback(async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_RATE_HOST}`);
    return data;
  }, []);

  const { data: rates } = useQuery({
    queryKey: ['currency-rate'],
    queryFn: getCurrencyRate,
  });

  const extendedRates: CurrencyRate[] = useMemo(() => {
    return rates ? [
      ...rates,
      {
        input: 'usdt',
        output: 'usd',
        rate: [1, 1.02],
      },
      {
        input: 'usdt',
        output: 'usdc',
        rate: [1, 1], // TODO: >>>>> CHANGE TO CORRECT RATE
      },
    ] : [];
  }, [rates]);
  const walletBalance = useCallback(
    (wallets: Wallet[], currency: string): number => {
      wallets = unifyWalletsStructure(wallets);
      return wallets.reduce((total, wallet) => {
        const converted = convertCurrency(
          extendedRates as IRate[],
          wallet.currency,
          currency,
          Number(wallet.balance),
        );
        return total + converted;
      }, 0);
    },
    [extendedRates],
  );

  const totalBalance = useCallback(
    (totalBalances: { [key: string]: number }, currency: string): number => {
      const convertedTotalBalances = unifyTotalBalancesStructure(totalBalances);

      const result = convertedTotalBalances.reduce((total, b) => {
        const converted = convertCurrency(
          extendedRates as IRate[],
          b.currency,
          currency,
          Number(b.balance),
        );
        return total + converted;
      }, 0);

      return result;
    },
    [extendedRates],
  );

  return {
    walletBalance,
    totalBalance,
  };
};

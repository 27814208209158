type UnifyWalletsStructureReturn = {
  balance: number;
  currency: string;
};

export const unifyWalletsStructure = (
  wallets: any[] = [],
): UnifyWalletsStructureReturn[] => {
  const results = [];

  for (const wallet of wallets) {
    if (wallet?.balance && wallet?.currency) {
      results?.push({
        balance: wallet?.balance,
        currency: wallet?.currency,
      });
    }

    if (wallet?.type && wallet?.type === 'all') {
      results?.push({
        balance: wallet?.amount,
        currency: wallet?.currency,
      });
    }

    if (wallet?.all) {
      results?.push({
        balance: wallet?.all,
        currency: wallet?.currency,
      });
    }
  }

  return results;
};

export const unifyTotalBalancesStructure = (object: { [key: string]: number }): { balance: string, currency: string }[] => {
  const array = [];
  for (const key in object) {
    const balance = object[key];
    array.push({
      balance: balance + '',
      currency: key,
    });
  }
  return array;
};

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IBusiness } from 'interfaces/IBusiness';

export const useBusinessById = (id: number | null) => {
  const getBusinessById = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_AUTH_URI}/root-units/${id}`);
    return data;
  };

  return useQuery<IBusiness>({
    queryKey: [`business-${id}`],
    queryFn: getBusinessById,
    staleTime: 10000,
    enabled: !!id,
  });
};

import { IStatus } from '../../interfaces/IStatus';
import { LogActionEnum } from 'types/LogAction';
import { ColorEnum } from '../../types';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const getLogActions = (t: TranslateFunc): IStatus[] => [
  {
    status: LogActionEnum.CREATE_PO_LVL_2,
    title: 'CREATE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.APPROVE_PO_LVL_2,
    title: 'APPROVE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.SUCCESS,
    icon: CheckCircleOutlined,
  },
  {
    status: LogActionEnum.DECLINE_PO_LVL_2,
    title: 'DECLINE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.ERROR,
    icon: CloseCircleOutlined,
  },
  // {
  //   status: LogActionEnum.CREATE_PO,
  //   color: ColorEnum.IDLE,
  //   icon: PlusCircleOutlined,
  // },
  // {
  //   status: LogActionEnum.APPROVE_PO,
  //   color: ColorEnum.SUCCESS,
  //   icon: CheckCircleOutlined,
  // },
  // {
  //   status: LogActionEnum.DECLINE_PO,
  //   color: ColorEnum.ERROR,
  //   icon: CloseCircleOutlined,
  // },
  {
    status: LogActionEnum.DEPOSIT_CHECKOUT,
    title: 'DEPOSIT_CHECKOUT',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.RESEND_TRANSACTION,
    title: 'RESEND_TRANSACTION',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.UPDATE_USER,
    title: 'UPDATE_USER',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.CREATE_USER,
    title: 'CREATE_USER',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_GROUP,
    title: 'CREATE_GROUP',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.UPDATE_GROUP,
    title: 'UPDATE_GROUP',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  // {
  //   status: LogActionEnum.APPROVE_REPLENISHMENT_REQUEST,
  //   color: ColorEnum.SUCCESS,
  //   icon: CheckCircleOutlined,
  // },
  // {
  //   status: LogActionEnum.DECLINE_REPLENISHMENT_REQUEST,
  //   color: ColorEnum.ERROR,
  //   icon: CloseCircleOutlined,
  // },
  {
    status: LogActionEnum.ADD_WALLET_TO_UNIT,
    title: 'ADD_WALLET_TO_UNIT',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_INVOICE,
    title: 'CREATE_INVOICE',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.REQUEST_REPLENISHMENT,
    title: 'REQUEST_REPLENISHMENT',
    color: ColorEnum.PENDING,
    icon: ClockCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_PAYMENT_ORDER,
    title: 'CREATE_PAYMENT_ORDER',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_TRANSACTION_GROUP,
    title: 'CREATE_TRANSACTION_GROUP',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_TRANSACTION_CATEGORY,
    title: 'CREATE_TRANSACTION_CATEGORY',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.UPDATE_TRANSACTION_GROUP,
    title: 'UPDATE_TRANSACTION_GROUP',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.UPDATE_TRANSACTION_CATEGORY,
    title: 'UPDATE_TRANSACTION_CATEGORY',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.CREATE_UNIT,
    title: 'CREATE_UNIT',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.UPDATE_UNIT,
    title: 'UPDATE_UNIT',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.MASS_PAYOUT_INITIALIZATION,
    title: 'MASS_PAYOUT_INITIALIZATION',
    color: ColorEnum.PENDING,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.CREATE_EXCHANGE,
    title: 'CREATE_EXCHANGE',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.UPDATE_PASSWORD,
    title: 'UPDATE_PASSWORD',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.UPDATE_MANY_USERS,
    title: 'UPDATE_MANY_USERS',
    color: ColorEnum.IDLE,
    icon: SyncOutlined,
  },
];

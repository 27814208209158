import React, { useContext, useEffect, useState } from 'react';
import { INotification } from 'interfaces/INotification';
import useNotificationAlert from 'hooks/useNotifications/useNotificationAlert';
import useNotificationRead from 'hooks/useNotifications/useNotificationRead';
import AlertMessage from 'contexts/AlertingContext/AlertMessage';
import { SocketContext } from 'contexts/SocketContext';

const AlertingProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [data, setData] = useState<INotification | null>(null);
  const [isVisible, setVisible] = useState(false);
  const { socket } = useContext(SocketContext);

  const { refetch } = useNotificationAlert({
    onSuccess: (alert) => {
      if (typeof alert !== 'string') {
        setData(alert);
      } else {
        setData(null);
      }
    },
  });

  const { mutate: read } = useNotificationRead({
    onSuccess: () => {
      refetch();
      setVisible(false);
    },
  });

  useEffect(() => {
    if (data) {
      setTimeout(() => setVisible(true), 500);
    }
  }, [data]);

  useEffect(() => {
    if (!isVisible && data) {
      setTimeout(() => setData(null), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (socket) {
      socket.on('notification-alert', () => refetch());
    }
  }, [refetch, socket]);

  return (
    <>
      {data && <AlertMessage data={data} onRead={read} isVisible={isVisible} />}

      {children}
    </>
  );
};

export default AlertingProvider;

import { IBusiness } from 'interfaces/IBusiness';
import { useCurrencyRate } from 'hooks';
import { convertArrayToHierarchy, CURRENCY, useBusinessTreeFlow } from '..';

export const useBusinessTree = (parent: IBusiness) => {
  const { walletBalance } = useCurrencyRate();
  const { data, isLoading, isError } = useBusinessTreeFlow(parent);
  const hierarchyWithParent = {
    id: parent?.id,
    name: parent?.name,
    currency: CURRENCY,
    ...(parent?.wallets?.length > 0 ? { balance: walletBalance(parent?.wallets as any[], CURRENCY) } : {}),
    children: convertArrayToHierarchy(data, walletBalance as (wallets: any[], currency: string) => number),
    parent,
  };

  return {
    tree: hierarchyWithParent,
    isLoading,
    isError,
  };
};

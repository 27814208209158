import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { useBusinesses } from 'pages/Administration/Businesses/hooks/useBusinesses';
import { useTranslate } from '../../hooks';
import { AutocompleteSelect } from '../AutocompleteSelect';

export interface BusinessesSelectProps {
  value?: number | null;
  onChange?: (businesses: number | null) => void;
}

export const BusinessSelect = ({
  value,
  onChange,
}: BusinessesSelectProps): ReactElement => {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslate();

  const { data, isLoading, refetch } = useBusinesses(
    {
      perPage: 7,
      filter: {
        and: [searchText ? { name: { like: `%${searchText.trim()}%` } } : {}],
      },
      options: {
        onError: (e) => {
          notification.error({
            message: e.response?.data?.message || e.message,
          });
        },
      },
    },
  );

  useEffect(() => {
    if (searchText) {
      refetch();
    }
  }, [refetch, searchText]);

  const businesses: IBusiness[] = data?.nodes || [];

  const onBusinessesSelect = useCallback(
    (id: number) => {
      if (onChange) {
        onChange(id);
      }
    },
    [onChange],
  );

  const mappedItems = businesses.map(({ id, name }: IBusiness) => ({
    id: Number(id),
    label: name,
  }));

  return (
    <AutocompleteSelect
      loading={isLoading}
      searchText={searchText}
      buttonLabel={t('business.add')}
      selectedItems={value ? [value] : []}
      onItemSelect={onBusinessesSelect}
      onSearchTextChanged={setSearchText}
      inputPlaceholder={t('business.search')}
      items={mappedItems}
      type="radio"
    />
  );
};

import axios from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import {
  CategoryCreateVars,
  CategoryDeleteVars,
  CategoryUpdateVars,
  OptionType,
  TranCategoryMutateReturn,
} from '../types';

export const useCategoryMutations = (options?: OptionType): TranCategoryMutateReturn => {
  const createCategory = async ({
    name, unitIds, groupId, rootUnitId,
  }: CategoryCreateVars) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CLIENT_HOST}/transaction-categories`,
      { name, unitIds, groupId, rootUnitId },
    );
    return data;
  };

  const updateCategory = async ({ id, unitIds, rootUnitId, name }: CategoryUpdateVars) => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/${id}`,
      { name, unitIds, rootUnitId },
    );
    return data;
  };

  const removeCategory = async ({ id }: CategoryDeleteVars) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/${id}`);
    return data;
  };

  const {
    mutate: mutateCreateCategory,
    isPending: isCreatingCategory,
  } = useMutation({ mutationFn: createCategory, ...options });

  const {
    mutate: mutateUpdateCategory,
    isPending: isUpdatingCategory,
  } = useMutation({ mutationFn: updateCategory, ...options });

  const {
    mutate: mutateRemoveCategory,
    isPending: isRemovingCategory,
  } = useMutation({ mutationFn: removeCategory, ...options });

  return {
    mutateCreateCategory, isCreatingCategory,
    mutateUpdateCategory, isUpdatingCategory,
    mutateRemoveCategory, isRemovingCategory,
  };
};

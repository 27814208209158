import React, { ReactElement, useState } from 'react';
import { batch } from 'react-redux';
import { AxiosError } from 'libs/axios';
import { notification } from 'antd';
import { IWallet } from 'types/IWallet';
import { InvoiceFormDataType } from './types';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useTranslate } from 'hooks';
import { useInvoiceMutation } from 'hooks/useInvoices';
import { useModal } from 'components/Modal/hooks/useModal';
import { Button, Modal } from 'components';
import { CreateInvoiceForm } from './components';
import { FileDoneOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './CreateInvoice.module.scss';

export type CreateInvoicePropsType = {
  unitId: number;
  onRefresh: () => void;
  disabledButton: boolean;
  wallets: IWallet[];
};

export const CreateInvoice = ({
  unitId,
  onRefresh,
  disabledButton,
  wallets,
}: CreateInvoicePropsType): ReactElement => {
  const [isAnyAmount, setAnyAmount] = useState(false);
  const { t } = useTranslate();

  const {
    isOpen,
    isCreate,
    onModalOpen,
    onModalClose,
    onSetModalType,
  } = useModal('invoice');

  const onCloseModal = () => {
    onModalClose();
    setAnyAmount(false);
  };

  const { create, isCreateLoading } = useInvoiceMutation({
    onSuccess: async (data) => {
      onCloseModal();
      onRefresh();

      return notification.success({
        message: t('created'),
        description: t('invoice.notify.invoiceWithIdCreated', { variables: { id: data.id } }),
      });
    },
    onError: (e: AxiosError) => {
      const { message, error } = e.response?.data as { message: string; error: string };

      notification.error({
        message: error,
        description: message,
      });
    },
  });

  const onCreateInvoice = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
    });
  };

  const onSubmit = (formData: InvoiceFormDataType) => {
    create({
      ...formData,
      unitId,
      isAnyAmount,
      title: formData?.title?.trim() || '',
      amount: isAnyAmount ? String(0) : String(formData.amount),
    });
  };

  return (
    <div className={styles.wrapper}>
      <Button
        type="link"
        withoutBgShadow
        withoutWaveEffect
        disabled={disabledButton}
        onClick={onCreateInvoice}
        suffixIcon={<PlusOutlined />}
      >
        {t('invoice.createInvoice')}
      </Button>

      <Modal
        isOpen={isOpen && isCreate}
        width={450}
        destroyOnClose
        onClose={onCloseModal}
        title={t('invoice.createInvoice')}
        titleIcon={<FileDoneOutlined style={{ fontSize: 20 }} />}
      >
        <CreateInvoiceForm
          setAnyAmount={setAnyAmount}
          isAnyAmount={isAnyAmount}
          onSubmit={onSubmit}
          isLoading={isCreateLoading}
          wallets={wallets}
        />
      </Modal>
    </div>
  );
};

import axios, { AxiosError } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IExternalUser } from 'interfaces/IExternalUser';
import { Filter } from 'types/Filter';

type ClientsSelectParams = {
  filter?: Filter<Record<string, unknown>> | any;
  paging?: { limit: number; offset?: number };
  sorting?: any;
  unit?: boolean;
};

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

export const useClients = (params: ClientsSelectParams, options?: OptionType, enabled = true) => {
  const getClients = async () => {
    const { data } = await axios.get(process.env.REACT_APP_CLIENT_HOST + '/clients', {
      params,
    });
    return data;
  };

  return useQuery<{ nodes: IExternalUser[], totalCount: number }>({
    queryKey: ['clients', params],
    queryFn: getClients,
    enabled,
    refetchOnMount: true,
    ...options,
  });
};

import React, { ReactElement } from 'react';
import { Button, Typography } from 'antd';
import clsx, { ClassValue } from 'clsx';
import { useTranslate } from '../../hooks';
import { ReactComponent as TableEmptyIcon } from '../../assets/icons/TableEmpty.svg';
import styles from './EmptyTablePlaceholder.module.scss';

export interface IEmptyTablePlaceholderProps {
  refetch: () => void;
  className?: ClassValue;
}

const EmptyTablePlaceholder = ({ refetch, className }: IEmptyTablePlaceholderProps): ReactElement => {
  const { t } = useTranslate();
  return (
    <div className={clsx(styles.container, className)}>
      <TableEmptyIcon />
      <Typography.Text className={styles.message}>{t('emptyTable')}</Typography.Text>
      <Button
        id={styles.button}
        className={styles.button}
        onClick={() => refetch()}
      >
        {t('refreshPage')}
      </Button>
    </div>
  );
};

export default EmptyTablePlaceholder;

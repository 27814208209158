import {
  ADD_PO_TO_APPROVE,
  ADD_PO_TO_APPROVE_MASS,
  PoActionType,
  REMOVE_PO_FROM_APPROVE,
} from './types/Actions';

const initialState: number[] | [] = [];

export const approvePOReducer = (
  state = initialState,
  action: PoActionType,
): number[] | [] => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case ADD_PO_TO_APPROVE:
      return [...state, action.payload];
    case ADD_PO_TO_APPROVE_MASS:
      return [...state, ...action.payload];
    case REMOVE_PO_FROM_APPROVE:
      return [...state].filter((i) => i !== action.payload);
    default:
      return state;
  }
};

import React from 'react';
import { Tooltip } from 'antd';
import { useTranslate } from 'hooks';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import styles from './WalletItem.module.scss';

type FavoriteProps = {
  isFavorite: boolean;
  onToggleFavorite: () => void;
};

const Favorite = ({ isFavorite, onToggleFavorite }: FavoriteProps) => {
  const { t } = useTranslate();
  const FavoriteIcon = isFavorite ? StarFilled : StarOutlined;

  return (
    <Tooltip title={t(isFavorite ? 'favoriteRemove' : 'favoriteAdd')}>
      <FavoriteIcon
        className={styles.starIcon}
        onClick={onToggleFavorite}
      />
    </Tooltip>
  );
};

export default Favorite;

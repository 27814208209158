import React, { ReactElement, useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Layout, Space } from 'antd';
import { AppState } from 'init/rootReducer';
import { useTranslate } from '../../hooks';
import { AuthorisationContext } from 'contexts';
import { getAvatarUrl } from 'utils/getAvatarUrl';
import { getPageTitle } from './utils';
import { LocaleDropdown } from '../LocaleDropdown';
import { UserAvatar } from '../UserAvatar';
import Notifications from 'components/Notifications';
import styles from './Header.module.scss';

const Header = (): ReactElement => {
  const { logout, user } = useContext(AuthorisationContext);
  const { pathname } = useLocation();
  const { t } = useTranslate();
  const params = useParams();

  const { title: unitTitle } = useSelector((state: AppState) => state.unitReducer);

  const title = useMemo(() =>
    getPageTitle({
      params,
      unitTitle,
      translate: t,
      path: pathname,
    }),
  [t, pathname, params, unitTitle]);

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.leftContent}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.rightContent}>
        <Space>
          <UserAvatar
            src={getAvatarUrl(user)}
            name={user?.name}
            withStatus
            withName
          />
          <span className={styles.divider} />
          <Notifications />
          <span className={styles.divider} />
          <LocaleDropdown />
          <span className={styles.divider} />
          <Button
            danger
            type="link"
            onClick={() => logout()}
            className={styles.logout}
          >
            {t('logout')}
          </Button>
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;

import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState } from 'react';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { Input2FA } from './components';
import styles from './Form2FA.module.scss';

export type Form2FAProps = {
  error?: boolean,
  setError?: Dispatch<SetStateAction<boolean>>,
  isLoading: boolean;
  onSubmit: (code: string) => void;
  onBack?: () => void;
};

const { Text, Title } = Typography;

export const Form2FA = ({
  error: isResponseError,
  setError: setResponseError,
  isLoading,
  onSubmit,
  onBack,
}: Form2FAProps): ReactElement => {
  const [code2FA, setCode2FA] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslate();

  useEffect(() => {
    if (isResponseError) {
      setError(t('2FA.invalidCode'));
    } else {
      setError('');
    }
  }, [isResponseError, t]);

  const onFinish = () => {
    if (code2FA.length < 6 || isResponseError) {
      setError(t('2FA.invalidCode'));
    } else {
      setError('');
      onSubmit(code2FA);
    }
  };

  const onChange = (value: string) => {
    setCode2FA(value);

    if (value.length === 6) {
      setError('');

      if (!isLoading) {
        onSubmit(value);
      }

      if (setResponseError) {
        setResponseError(false);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Title className={styles.title}>{t('2FA.code')}</Title>
      <Text className={styles.text}>{t('2FA.pleaseEnterCode')}</Text>

      <div>
        <Input2FA onChange={onChange} error={error} />

        <div className={clsx(styles.buttons, { [styles.onlySubmit]: !onBack })}>
          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={onFinish}
            className={clsx(styles.button, styles.submit)}
          >
            {t('send')}
          </Button>

          {onBack && (
            <Button
              type="ghost"
              onClick={onBack}
              className={clsx(styles.button, styles.back)}
            >
              {t('back')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

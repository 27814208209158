import axios from 'libs/axios';
import JWT from 'jsonwebtoken';
import _ from 'lodash';
import { UnknownType } from 'types/Unknown';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';

const decodeToken = (token: unknown): UnknownType =>
  typeof token === 'string' ? JWT.decode(token) : null;

const getAccessTokenExpDate = () => {
  const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
  const decodedToken = accessToken ? decodeToken(accessToken) : null;
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return decodedToken?.exp || nowTime;
};

export const isAccessTokenExpiring = () => {
  const nowTime = Math.floor(new Date().getTime() / 1000);
  const accessTokenExpDate = getAccessTokenExpDate();

  return (accessTokenExpDate - 60) <= nowTime;
};

export const isAccessTokenExpired = () => {
  const accessTokenExpDate = getAccessTokenExpDate();
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return (accessTokenExpDate < nowTime);
};

export const refreshTokens = async (fingerprint: string | undefined) => {
  return axios.post(`${process.env.REACT_APP_AUTH_URI}/auth/refresh-tokens`, { fingerprint }, {
    headers: {
      refreshToken: LocalStorage.get(LocalStorageKey.REFRESH_TOKEN),
    },
  }).then((response) => {
    const { data } = response || {};
    if (data?.accessToken) {
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
    }
    if (data?.refreshToken) {
      LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, data.refreshToken);
    }
    return data || {};
  });
};

export const debounceRefreshTokens = _.debounce((fingerprint) => refreshTokens(fingerprint), 1000);

import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { IUnit, IUnitChildren } from 'interfaces/IUnit';
import { ToolOutlined } from '@ant-design/icons';
import styles from './EditButton.module.scss';

export interface IEditButtonProps {
  unit: IUnit | IUnitChildren;
  onUpdate: (unit: any, modalType?: 'managers' | 'clients' | 'wallets' | 'default') => void
}

const EditButton = ({ unit, onUpdate }: IEditButtonProps): ReactElement => (
  <Button
    className={styles.button}
    onClick={() => onUpdate(unit, 'default')}
    icon={<ToolOutlined />}
  />
);

export default EditButton;

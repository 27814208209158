import axios, { AxiosError } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

export type OptionType = {
  onSuccess?: (e: any, variables?: any) => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>, variables?: any) => void;
};

type UpdateTransactionProps = {
  transactionId: number;
  categoryId: number;
};

export const useTransactionMutation = (options: OptionType) => {
  const updateTransaction = async ({ transactionId, categoryId }: UpdateTransactionProps) => {
    const BASE_URL = `${process.env.REACT_APP_CLIENT_HOST}/transactions/category`;

    const { data } = await axios.patch(`${BASE_URL}?categoryId=${categoryId}&transactionId=${transactionId}`);

    return data;
  };

  const {
    mutate: updateTransactionMutation,
    isPending: isTransactionUpdating,
  } = useMutation({ mutationFn: updateTransaction, ...options });

  return {
    updateTransactionMutation,
    isTransactionUpdating,
  };
};

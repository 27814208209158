import React from 'react';
import { Image } from 'antd';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import ActivityStatus from 'components/ActivityStatus';
import styles from './index.module.scss';

type UserAvatarType = {
  name: string | undefined;
  withName?: boolean;
  withStatus?: boolean;
  src?: string;
  className?: string;
};

export const UserAvatar = ({
  src,
  name,
  withName,
  withStatus,
  className,
}: UserAvatarType) => {
  const { t } = useTranslate();

  const AvatarCircleJSX = (
    <div className={clsx(styles.avatar, className)}>
      {src ? <Image src={src} preview={false} alt={name} /> : name && name[0]}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      {withStatus
        ? <ActivityStatus>{AvatarCircleJSX}</ActivityStatus>
        : AvatarCircleJSX}

      {(withName && name) && (
        <div className={styles.name}>
          <span>{t('hi')},</span> {name}
        </div>
      )}
    </div>
  );
};

import React, {
  ReactElement, useCallback, useContext, useState,
} from 'react';
import { Table } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IExternalUser } from '../../interfaces/IExternalUser';
import { IUser } from '../../interfaces/IUser';
import {
  DataType,
  ReturnedColumnsType,
  ReturnedTableDataType,
  TableColumnType,
  TableDataType,
} from './types';
import { UserSortField } from 'pages/Administration/Managers/types';
import { ExternalUserSortField } from 'pages/Users/ExternalUsers/types';
import { FormUpdateSelectedUsersType } from 'pages/Administration/Managers/components/FormUpdateSelectedUsers/FormUpdateSelectedUsers';
import { FormUpdateSelectedExternalUsersType } from '../../pages/Users/ExternalUsers/components/FormUpdateSelectedUsers/FormUpdateSelectedUsers';
import { Sort } from '../../hooks';
import { AuthorisationContext } from '../../contexts/AuthorisationContext';
import styles from './UsersTable.module.scss';

export type IUsersTableProps<S> = {
  loading: boolean;
  sort: S | null;
  users: TableDataType;
  columns: TableColumnType;
  onSort: (sort: S | null) => void;
  onRefresh?: () => void;
  embeddedContent?: React.ComponentType<FormUpdateSelectedUsersType & FormUpdateSelectedExternalUsersType>;
  head?: ReactElement;
  footer?: ReactElement;
  isUpdateEnabled: boolean;
  scrollWidth: number;
  isExternalUsers?: boolean;
};

const UsersTable = <S extends Sort<UserSortField> | Sort<ExternalUserSortField>>({
  users, loading, onSort, columns, onRefresh, embeddedContent: EmbeddedContent,
  isUpdateEnabled, scrollWidth, isExternalUsers,
}: IUsersTableProps<S>): ReactElement => {
  const { user } = useContext(AuthorisationContext);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const selectedCount = selectedRows.length;

  const onChange = useCallback((
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<IExternalUser & IUser> | SorterResult<IExternalUser & IUser>[],
  ) => {
    if (!Array.isArray(sorter)) {
      onSort(sorter.order ? { field: sorter.columnKey || sorter.field, order: sorter.order } as S : null);
    }
  }, [onSort]);

  const applyHandler = () => {
    if (onRefresh) {
      onRefresh();
    }
    setSelectedRows([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: String(record.id) === String(user?.id),
    }),
  };

  const rowSelectionExternal = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: React.Key[], data: any) => {
      setSelectedRows(selectedRowKeys);
      setSelectedUsers(data);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: String(record.id) === String(user?.id),
    }),
  };

  const titleJSX = (
    <div className={styles.head}>
      {EmbeddedContent && (
        <EmbeddedContent
          selectedUsers={selectedUsers}
          selectedKeys={selectedRows}
          onRefresh={applyHandler}
        />
      ) }
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Table
        scroll={{ x: scrollWidth, y: 'calc(100vh - 340px)' }}
        tableLayout="auto"
        rowKey="id"
        // rowSelection={isUpdateEnabled ? {
        //   type: 'checkbox',
        //   ...(isExternalUsers ? rowSelectionExternal : rowSelection),
        //   columnWidth: 50,
        // } : undefined}
        loading={loading}
        columns={columns as ReturnedColumnsType}
        pagination={false}
        dataSource={users as ReturnedTableDataType}
        onChange={onChange}
        title={() => (selectedCount >= (isExternalUsers ? 1 : 2) ? titleJSX : false)}
        rowClassName={styles.row}
      />
    </div>
  );
};

export default UsersTable;

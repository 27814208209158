import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Divider, notification, Space, Spin } from 'antd';
import { IPermission } from 'interfaces/IPermission';
import { UnknownType } from 'types/Unknown';
import { formatPermissions } from 'pages/Administration/AdministrationGroups/utils/formatPermissions';
import { usePermissions, useTranslate } from 'hooks';

export interface PermissionsSelectProps {
  value?: IPermission[];
  onChange?: (permissions: IPermission[]) => void;
}

export const categorySettings = [
  [
    'transactions',
    ['transaction', 'transaction_payout', 'transaction_payin'],
  ],
  ['financialManagment', ['transaction_group', 'transaction_category']],
];

export const PermissionsList = ({
  value,
  onChange,
}: PermissionsSelectProps): ReactElement => {
  const [valueArray, setValueArray] = useState<number[]>([]);
  const { t } = useTranslate();

  const handleErrorPermissions = useCallback((e: UnknownType) => {
    notification.error({
      message: e.response?.data?.message || e.message,
    });
  }, []);

  const { data, isLoading } = usePermissions('', {
    onError: handleErrorPermissions,
  });

  const permissions: IPermission[] = useMemo(
    () => data?.nodes || [],
    [data?.nodes],
  );

  const toggleCheckbox = (id: number) => {
    const newValueArray = [...valueArray];
    const index = newValueArray.indexOf(id);

    if (index !== -1) {
      newValueArray.splice(index, 1);
    } else {
      newValueArray.push(id);
    }

    setValueArray(newValueArray);

    if (onChange) {
      const newPermissions = permissions.filter((item) =>
        newValueArray.includes(item.id),
      );
      onChange(newPermissions);
    }
  };

  const groupedPermissions = formatPermissions(
    permissions || [],
    categorySettings as any,
  );

  useEffect(() => {
    setValueArray(value?.map(v => v?.id) || [] as number[]);
  }, [value]);

  if (isLoading) {
    return (
      <div style={{ margin: 8, display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{ maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', paddingRight: 16, width: '100%' }}
    >
      {groupedPermissions?.map((item: any) => {
        return (
          <Space key={item?.group} direction="vertical" style={{ width: '100%' }}>
            <h2 style={{ fontSize: 16, fontWeight: 600, marginBottom: 0 }}>
              {t(`permissionsList.${item.group}`)}
            </h2>
            {item.category.map((category: any) => {
              return (
                <Space
                  key={category?.name}
                  direction="vertical"
                  style={{ marginTop: 8, overflowX: 'hidden' }}
                >
                  <h3 style={{ color: '#333333a3', fontSize: 14, fontWeight: 600 }}>
                    {t(`permissionsList.${category?.name}`)}
                  </h3>
                  {category?.permissions?.map((perm: any) => {
                    return (
                      <Space
                        key={category?.name + perm?.name}
                        direction="vertical"
                        style={{ marginLeft: 8 }}
                      >
                        <Checkbox
                          checked={valueArray.includes(perm?.id)}
                          onChange={() => toggleCheckbox(perm?.id)}
                        >
                          {t(perm?.name)}
                        </Checkbox>
                      </Space>
                    );
                  })}
                </Space>
              );
            })}
            <Divider />
          </Space>
        );
      })}
    </Space>
  );
};

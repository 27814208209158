import React, { ReactElement } from 'react';

export interface IIconProps {
  isActive?: boolean;
}

function ProcessingIcon({ isActive }: IIconProps): ReactElement {
  if (isActive) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.35"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="white"
        />
        <path
          // eslint-disable-next-line
          d="M16.1035 14.577C16.1035 16.159 15.3205 18 12.5445 18H10.1435C9.26955 18 8.56055 17.291 8.56055 16.417V7.583C8.56155 6.709 9.26955 6 10.1435 6H12.1545C14.3335 6 15.6965 7.034 15.6965 9.085C15.6965 9.797 15.7185 10.848 14.2055 11.712V11.745C14.4325 11.803 16.1035 12.315 16.1035 14.577ZM13.3085 9.517C13.3085 8.521 12.6645 8.238 12.0025 8.238H11.0875V10.848H12.0195C12.7795 10.848 13.3085 10.369 13.3085 9.517ZM13.5285 14.33C13.5285 13.575 13.1235 12.83 12.0705 12.83H11.0875V15.761H12.1055C13.1025 15.762 13.5285 15.079 13.5285 14.33Z"
          fill="white"
        />
        <path
          d="M13 5C13 4.448 12.552 4 12 4C11.448 4 11 4.448 11 5C11 5.186 11 5.814 11 6C11 6.552 11.448 7 12 7C12.552 7 13 6.552 13 6C13 5.814 13 5.186 13 5Z"
          fill="white"
        />
        <path
          // eslint-disable-next-line
          d="M13 18C13 17.448 12.552 17 12 17C11.448 17 11 17.448 11 18C11 18.186 11 18.814 11 19C11 19.552 11.448 20 12 20C12.552 20 13 19.552 13 19C13 18.814 13 18.186 13 18Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.35"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#1DA1F2"
      />
      <path
        // eslint-disable-next-line
        d="M16.1035 14.577C16.1035 16.159 15.3205 18 12.5445 18H10.1435C9.26955 18 8.56055 17.291 8.56055 16.417V7.583C8.56155 6.709 9.26955 6 10.1435 6H12.1545C14.3335 6 15.6965 7.034 15.6965 9.085C15.6965 9.797 15.7185 10.848 14.2055 11.712V11.745C14.4325 11.803 16.1035 12.315 16.1035 14.577ZM13.3085 9.517C13.3085 8.521 12.6645 8.238 12.0025 8.238H11.0875V10.848H12.0195C12.7795 10.848 13.3085 10.369 13.3085 9.517ZM13.5285 14.33C13.5285 13.575 13.1235 12.83 12.0705 12.83H11.0875V15.761H12.1055C13.1025 15.762 13.5285 15.079 13.5285 14.33Z"
        fill="#1DA1F2"
      />
      <path
        d="M13 5C13 4.448 12.552 4 12 4C11.448 4 11 4.448 11 5C11 5.186 11 5.814 11 6C11 6.552 11.448 7 12 7C12.552 7 13 6.552 13 6C13 5.814 13 5.186 13 5Z"
        fill="#1DA1F2"
      />
      <path
        // eslint-disable-next-line
        d="M13 18C13 17.448 12.552 17 12 17C11.448 17 11 17.448 11 18C11 18.186 11 18.814 11 19C11 19.552 11.448 20 12 20C12.552 20 13 19.552 13 19C13 18.814 13 18.186 13 18Z"
        fill="#1DA1F2"
      />
    </svg>
  );
}

export default ProcessingIcon;

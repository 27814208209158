import React from 'react';
import { batch, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { INotification, NotificationType } from 'interfaces/INotification';
import { useTranslate } from 'hooks';
import { Button } from 'components/Button';
import { setNotificationPayload, setNotificationVisible } from 'components/Notifications/redux/actions';
import styles from './AlertMessage.module.scss';

const AlertMessage = ({
  data,
  onRead,
  isVisible,
}: {
  data: INotification;
  isVisible: boolean;
  onRead: (id: number) => void;
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleRead = () => {
    batch(() => {
      dispatch(setNotificationPayload(data));
      dispatch(setNotificationVisible(true));
    });
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.show]: isVisible,
        [styles.warning]: data.type === NotificationType.WARNING,
        [styles.error]: data.type === NotificationType.ERROR,
      })}
    >
      <div className={styles.content}>
        <span className={styles.title}>{data.title}:</span>
        <span className={styles.description}>
          <span>{data.description}</span>

          <Button
            type="link"
            withoutBgShadow
            withoutWaveEffect
            onClick={handleRead}
            className={styles.button}
          >
            {t('notifications.readMore')}
          </Button>
        </span>
      </div>

      <Button
        onClick={() => onRead(data.id)}
        className={styles.button}
      >
        {t('agree')}
      </Button>
    </div>
  );
};

export default AlertMessage;

import {
  ModalTypePayload,
  PayloadStatus,
  PayloadVisible, SET_MODAL_PAYLOAD,
  SET_MODAL_TYPE,
  SET_MODAL_VISIBLE,
  SetModalPayload,
  SetModalType,
  ToggleModalVisible,
} from './types/Actions';

export const toggleVisibleModal = (
  payload: PayloadVisible,
): ToggleModalVisible => ({
  type: SET_MODAL_VISIBLE,
  payload,
});

export const setTypeModal = (
  payload: PayloadStatus,
): SetModalType => ({
  type: SET_MODAL_TYPE,
  payload,
});

export const setPayloadModal = <P>(
  payload: ModalTypePayload<P>,
): SetModalPayload<P> => ({
    type: SET_MODAL_PAYLOAD,
    payload,
  });

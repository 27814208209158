import axios, { AxiosError } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IPermission } from 'interfaces/IPermission';

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

export const usePermissions = (search: string, options: OptionType) => {
  const getPermissions = async () => {
    const { data } = await axios.get(process.env.REACT_APP_AUTH_URI + '/permissions', {
      params: {
        paging: {
          limit: 0,
        },
        filter: search ? {
          name: {
            like: `%${search.trim()}%`,
          },
        } : {},
      },
    });
    return data;
  };

  return useQuery<{ nodes: IPermission[], totalCount: number }>({
    queryKey: ['permissions'],
    queryFn: getPermissions,
    ...options,
  });
};

import React from 'react';
import { UnknownType } from 'types/Unknown';
import styles from './TransactionView.module.scss';

type TransactionDetailProps = {
  label: UnknownType;
  value?: UnknownType;
  children?: React.ReactNode;
};

const TransactionDetail = ({ label, value, children }: TransactionDetailProps) => (
  <div className={styles.item}>
    <p className={styles.label}>{label}</p>
    <div className={styles.value}>
      {children || value}
    </div>
  </div>
);

export default TransactionDetail;

import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Form, notification, Select, Tag, Tooltip } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { IExternalUser, IUserLabel } from '../../../../../interfaces/IExternalUser';
import { useLabels } from './hooks';
import { useTranslate } from '../../../../../hooks';
import { USER_LABEL_REGEX } from '../../../../../constants/validation';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './UserLabels.module.scss';

type UserLabelsProps = {
  user: Partial<IExternalUser>;
  onRefresh: () => void,
};

export type LabelOptionsType = {
  key: number;
  label: string;
  value: string;
};

const UserLabels = ({ user, onRefresh }: UserLabelsProps): ReactElement => {
  const [searchText, setSearchText] = useState<string>('');
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const { query, addLabel, removeLabel, createLabel } = useLabels(user,
    {
      filter: { or: [{ name: { like: `%${searchText?.trim()}%` } }] },
      sorting: [{ field: 'name', direction: 'ASC' }],
    },
    {
      onSuccess: async () => {
        form.resetFields();
        await onRefresh();
      },
      onError: (e) => {
        form.setFields([{ name: 'label', errors: [e.response?.data?.message || ''] }]);

        notification.error({
          message: e.response?.data?.message || t('internalServerError'),
          description: e.message,
        });
      },
    });

  const labels = (user?.labels || [])
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  useEffect(() => {
    query.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.refetch, searchText]);

  const labelOptions: LabelOptionsType[] = (query?.data?.nodes || [])
    .map((label: IUserLabel) => ({
      key: label.id,
      label: label.name,
      value: label.name,
    }));

  const handleCreate = (data: LabelOptionsType) => {
    const isExist = labels.some((userLabel) => userLabel.name === data.label)
      || query?.data?.nodes?.some(userLabel => userLabel.name === data.label);

    if (!isExist) {
      createLabel.mutate({ name: searchText });
    } else {
      form.setFields([{
        name: 'label',
        errors: [t('users.externalUsers.labelAlreadyExist')],
      }]);
    }
    setSearchText('');
  };

  const handleRemove = (removedId: number) => {
    if (removedId) {
      removeLabel.mutate(removedId);
    }
  };

  const handleSelect = (item: LabelOptionsType) => {
    const isExist = labels.some((userLabel) => userLabel.name === item.value);

    if (!isExist) {
      addLabel.mutate(item.key);
    } else {
      form.setFields([{
        name: 'label',
        errors: [t('users.externalUsers.labelAlreadyExist')],
      }]);
    }
    setSearchText('');
  };

  const handlePressEnter = (e: React.KeyboardEvent) => {
    const keyButton = e.keyCode || e.which;
    if (keyButton === 13) {
      form.submit();
    }
  };

  const SubmitButtonJSX = (
    <Tooltip title={t('users.externalUsers.clickOrPressEnterToCreateLabel')}>
      <Button
        htmlType="submit"
        icon={<PlusOutlined className={styles.icon} />}
        className={styles.submitBtn}
      />
    </Tooltip>
  );

  return (
    <div className={styles.wrapper}>
      <TweenOneGroup
        className={styles.tweenOneGroup}
        appear={false}
        enter={{
          scale: 0.8,
          opacity: 0,
          type: 'from',
          duration: 100,
        }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
      >
        {labels.map(label => (
          <span key={label.id} className={styles.tagSpan}>
            <Tag
              closable
              className={styles.tag}
              onClose={(e) => {
                e.preventDefault();
                handleRemove(label.id);
              }}
            >
              {label.name}
            </Tag>
          </span>
        ))}
      </TweenOneGroup>

      <Form form={form} name="select-form" onFinish={handleCreate}>
        <Form.Item
          name="label"
          rules={[
            { required: true, message: t('users.labels.pleaseSelectLabel') },
            { pattern: USER_LABEL_REGEX, message: t('users.labels.rules') },
          ]}
        >
          <div id="select" className={styles.selectContainer}>
            <Select
              showArrow
              showSearch
              listHeight={200}
              placement="bottomLeft"
              className={styles.select}
              defaultActiveFirstOption={false}
              placeholder={t('users.externalUsers.newLabel')}
              suffixIcon={query.isLoading ? <LoadingOutlined /> : SubmitButtonJSX}
              value={null}
              options={labelOptions}
              loading={query.isLoading}
              searchValue={searchText}
              onSearch={setSearchText}
              onKeyDown={handlePressEnter}
              onSelect={(_, item) => handleSelect(item)}
              getPopupContainer={() => document.getElementById('select') as HTMLElement}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserLabels;

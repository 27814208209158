import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Path } from 'routes/interfaces/Path';
import { CurrencyEnum } from 'types/Currency';

export type CurrencyType = keyof typeof CurrencyEnum;

export const useProcessingCurrency = (path: Path) => {
  const { currency } = useParams<{ currency: Lowercase<CurrencyType> }>();
  const navigate = useNavigate();

  const [
    selectedCurrency,
    setSelectedCurrency,
  ] = useState<CurrencyEnum>(CurrencyEnum[currency?.toUpperCase() as CurrencyType] || CurrencyEnum.TRX);

  useEffect(() => {
    if (currency && CurrencyEnum[currency?.toUpperCase() as CurrencyType]) {
      setSelectedCurrency(currency?.toUpperCase() as CurrencyEnum);
    } else {
      navigate(`${path}/${CurrencyEnum.TRX.toLowerCase()}`);
    }
  }, [currency, navigate, path]);

  return {
    currency: selectedCurrency,
  };
};

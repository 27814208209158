import { useEffect, useState } from 'react';
import { getFingerprint } from './utils/getFingerprint';

export const useFingerprint = () => {
  const [fingerprint, setFingerprint] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const fp = await getFingerprint();

      if (fp) {
        setFingerprint(fp);
      }
    })();
  }, []);

  return fingerprint;
};

import { INotification } from 'interfaces/INotification';

const getSortedNotifications = (notifications: INotification[]) => {
  const currentDate = new Date();

  const todayList = notifications
    .filter((i) => currentDate.getDate() === new Date(i.createdAt).getDate())
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA < dateB ? 1 : -1;
    }) ?? [];

  const laterList = notifications
    .filter((i) => currentDate.getDate() !== new Date(i.createdAt).getDate())
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA < dateB ? 1 : -1;
    }) ?? [];

  return { todayList, laterList };
};

export default getSortedNotifications;

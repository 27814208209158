import React, { useCallback, useId, useMemo, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import clsx from 'clsx';
import { NotificationType } from 'components/Notifications/types';
import { useTranslate } from 'hooks';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Button } from 'components';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import styles from './TypeSelect.module.scss';

const TypeSelect = ({
  value,
  onChange,
}: {
  value: NotificationType[];
  onChange: (newTypes: NotificationType[]) => void;
}) => {
  const { t } = useTranslate();
  const buttonId = useId();

  const [isOpen, setOpen] = useState(false);

  const handleSelect = useCallback((newType: NotificationType) =>
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();

      if (newType === NotificationType.ALL) {
        onChange([newType]);
      } else if (value.includes(NotificationType.ALL)) {
        onChange([newType]);
      } else {
        const updatedValue = value.includes(newType)
          ? value.filter(oldType => oldType !== newType)
          : [...value, newType];
        onChange(updatedValue);
      }
    },
  [onChange, value]);

  const dropdownItems = useMemo(
    (): ItemType[] => Object.values(NotificationType).map(type => {
      const disabled = value.length === 1 && value[0] === type;

      return ({
        key: type,
        label: (
          <span
            onClick={!disabled ? handleSelect(type) : undefined}
            className={clsx(styles.item, { [styles.disabled]: disabled })}
          >
            <Checkbox checked={value.includes(type)} disabled={disabled} />
            {t(`notifications.type.${type}`)}
          </span>
        ),
      });
    }), [handleSelect, t, value]);

  return (
    <Dropdown
      onOpenChange={setOpen}
      overlayClassName={styles.dropdown}
      menu={{ items: dropdownItems }}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
    >
      <Button
        id={buttonId}
        type="link"
        withoutBgShadow
        withoutWaveEffect
        suffixIcon={<ArrowUp className={clsx(styles.arrow, { [styles.up]: isOpen })} />}
        className={styles.button}
      >
        {t('type')}
        {value.length && <div className={styles.badge}>{value.length}</div>}
      </Button>
    </Dropdown>
  );
};

export default TypeSelect;

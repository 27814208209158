import React, { memo, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import { truncate } from 'lodash';
import { generateRandomColor } from 'utils';
import { ITreeFlowItem, TreeFlowItemProps } from '.';
import { CountUp } from 'components';
import styles from './TreeFlowItem.module.scss';

export const TreeFlowItem = (props: TreeFlowItemProps): ReactElement => {
  const { pathname } = useLocation();
  const { nodeDatum, foreignObjectProps, onNodeClick, parent } = props;
  const { name, balance, currency } = nodeDatum as ITreeFlowItem;
  const { color, colorWithAlpha } = generateRandomColor(name);
  const shouldRenderBalance = typeof balance === 'number' && !isNaN(balance) && !!currency;

  const IS_BUSINESS_LEVEL = pathname === '/administration/businesses';
  const BUSINESS_LEVEL_LINK = IS_BUSINESS_LEVEL && !!props.nodeDatum.uuid ? `${parent.id}/units/${props.nodeDatum.uuid}` : `${parent.id}/units`;
  const URL = IS_BUSINESS_LEVEL ? BUSINESS_LEVEL_LINK : props.nodeDatum.uuid;

  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Link to={URL as string} className={styles.link}>
          <div className={styles.treeFlowItem} onClick={onNodeClick}>
            <div
              className={styles.avatar}
              style={{ backgroundColor: `#${colorWithAlpha}`, color: `#${color}` }}
            >
              {name.slice(0, 1).toUpperCase()}
            </div>
            <div className={styles.information}>
              <div className={styles.name}>
                <Tooltip title={name}>
                  {truncate(nodeDatum.name, { length: 20 })}
                </Tooltip>
              </div>
              {shouldRenderBalance && (
                <div className={styles.balance}>
                  <span className={styles.amount}>
                    <CountUp end={balance} currency={currency} isRoundDown withIcon />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Link>
      </foreignObject>
    </g>
  );
};

export const MemoTreeFlowItem = memo(TreeFlowItem);

import React, { useContext, useEffect, useState } from 'react';
import { Divider, Form, FormInstance, Input, Switch, Typography } from 'antd';
import { UnknownType } from 'types/Unknown';
import { UnitFormData } from '../../types';
import { useTranslate } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts';
import { Button } from 'components';
import { BusinessSelect } from 'components/BusinessSelect';
import { AdminsSelect, ClientsSelect } from './components';
import styles from './FormUnit.module.scss';

export interface ModalUnitFormProps {
  loading?: boolean;
  isParent: boolean;
  isCreate: boolean;
  onClose: () => void;
  onSubmit: (data: UnitFormData) => void;
  initialValues: UnitFormData | null;
  form: FormInstance<UnitFormData>;
  rootUnitId?: number;
}

const FormUnit = ({
  form,
  loading,
  onClose,
  onSubmit,
  isParent,
  isCreate,
  initialValues,
  rootUnitId,
}: ModalUnitFormProps) => {
  const { t } = useTranslate();
  const { isRoot } = useConfirmRootAdmin();
  const { user } = useContext(AuthorisationContext);
  const { payload } = useModal('units');

  const [changedValues, setChangedValues] = useState<string[]>([]);

  const [selectedClientIds, setSelectedClientIds] = useState<number[]>([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(
    (payload?.rootUnitId as number)
    || (user?.rootUnitId as number)
    || rootUnitId
    || null,
  );

  const wallets = (payload.wallets as unknown[]) || [];
  const isWalletsModal = payload.modalType === 'wallets';
  const isClientsModal = payload.modalType === 'clients';
  const isMangersModal = payload.modalType === 'managers';
  const isDefaultModal = payload.modalType === 'default';
  const isClientIdsReallyChanged = isDefaultModal && changedValues.includes('clientIds');

  const handleFinish = (values: UnitFormData) => {
    onSubmit({
      ...values,
      clientIds: isClientsModal || isClientIdsReallyChanged ? selectedClientIds : undefined,
    });
  };

  const handleChangeValues = (values: UnknownType) => {
    const [changedValueKey] = Object.keys(values);

    if (changedValues.includes(changedValueKey)) return;

    setChangedValues(prevState => [...prevState, changedValueKey]);
  };

  const fieldNameJSX = (
    <Form.Item
      name="name"
      label={t('users.units.unitName')}
      className={styles.formItem}
      rules={[
        {
          required: true, whitespace: false,
          message: t('users.units.validation.enterUnitName'),
        },
        {
          message: t('specSymbolsAndNumbersNotAllowed'),
          pattern: /^[0-9a-zA-Zа-яА-ЯҐЄІЇ-ґєії ]+$/i,
        },
      ]}
    >
      <Input
        className={styles.input}
        placeholder={t('users.units.validation.enterUnitName')}
      />
    </Form.Item>
  );

  const fieldDescriptionJSX = (
    <Form.Item
      name="description"
      label={t('users.units.desc')}
      className={styles.formItem}
      rules={[
        {
          pattern: /^[0-9a-zA-Zа-яА-ЯҐЄІЇ-ґєії ]+$/i,
          message: t('specSymbolsAndNumbersNotAllowed'),
        },
      ]}
    >
      <Input
        className={styles.input}
        placeholder={t('users.units.validation.enterDesc')}
      />
    </Form.Item>
  );

  // rules={[{ required: true, message: t('users.teams.validation.selectClients') }]}
  const fieldClientsJSX = (
    <Form.Item label={t('users.teams.clients')} className={styles.formItem}>
      <div className={styles.select} >
        <Form.Item name="clientIds" style={{ margin: 0, width: '100%' }}>
          <ClientsSelect value={selectedClientIds} onChange={setSelectedClientIds} />
        </Form.Item>
      </div>
    </Form.Item>
  );

  const fieldBusinessesJSX = isCreate && isRoot && isParent && !selectedBusinessId && (
    <Form.Item label={t('business.business')} className={styles.formItem}>
      <div className={styles.select} >
        <Form.Item
          name="rootUnitId"
          style={{ margin: 0, width: '100%' }}
          rules={[{ required: true, message: t('business.pleaseSelect') }]}
        >
          <BusinessSelect value={rootUnitId} onChange={setSelectedBusinessId} />
        </Form.Item>
      </div>
    </Form.Item>
  );

  // rules={[{ required: true, message: t('users.teams.validation.selectManagers') }]}
  const fieldManagersJSX = selectedBusinessId && (
    <Form.Item label={t('users.teams.managers')} className={styles.formItem}>
      <div className={styles.select} >
        <Form.Item
          name="adminIds"
          style={{ margin: 0, width: '100%' }}
        >
          <AdminsSelect
            buttonPlaceholder={t('users.teams.addManagers')}
            inputPlaceholder={t('users.teams.searchManagers')}
            businessId={selectedBusinessId}
          />
        </Form.Item>
      </div>
    </Form.Item>
  );

  const fieldWalletsJSX = (isCreate || !wallets.length || isWalletsModal) && (
    <div>
      <Divider style={{ margin: 0 }} />

      <div className={styles.switchWrapper}>
        <Typography.Text type="secondary">{t('users.units.createWallet')}</Typography.Text>

        <Form.Item
          name="createWallet"
          className={styles.selectSearchField}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
    </div>
  );

  return (
    <Form
      name="unit-form"
      form={form}
      onFinish={handleFinish}
      onValuesChange={handleChangeValues}
      initialValues={{ initialValues }}
      className={styles.form}
    >
      <div>
        {(isMangersModal || isClientsModal || isWalletsModal) ? (
          <>
            {isMangersModal && fieldManagersJSX}
            {isClientsModal && fieldClientsJSX}
            {isWalletsModal && fieldWalletsJSX}
          </>
        ) : (
          <>
            {fieldNameJSX}
            {fieldDescriptionJSX}
            {fieldClientsJSX}
            {fieldBusinessesJSX}
            {fieldManagersJSX}
            {fieldWalletsJSX}
          </>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default FormUnit;

import React, { ReactElement, useCallback } from 'react';
import { Table } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IFiatTransaction } from '../../interfaces/IFiatTransaction';
import { ITeamTransaction } from '../../interfaces/ITeamTransaction';
import { ITransaction } from '../../interfaces/ITransaction';
import {
  ReturnedColumnsType,
  ReturnedTableDataType,
  TableColumnType,
  TableDataType,
  TransactionsSortField,
} from './types';
import { Sort } from '../../hooks';
import styles from './TransactionsTable.module.scss';

export interface ITransactionsTableProps<S> {
  loading: boolean;
  sort: S | null;
  transactions: TableDataType;
  columns: TableColumnType;
  onSort: (sort: S | null) => void;
  isUpdateEnabled: boolean;
  scrollWidth: number;
  onRefresh?: () => void;
  head?: ReactElement;
  footer?: ReactElement;
}

const TransactionsTable = <S extends Sort<TransactionsSortField>>({
  transactions, loading, onSort, columns, footer, onRefresh,
  isUpdateEnabled, scrollWidth,
}: ITransactionsTableProps<S>): ReactElement => {

  // const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  // const selectedCount = selectedRows.length;

  const onChange = useCallback((
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<ITransaction | IFiatTransaction | ITeamTransaction>
    | SorterResult<ITransaction | IFiatTransaction | ITeamTransaction>[],
  ) => {
    if (!Array.isArray(sorter)) {
      onSort(sorter.order ? { field: sorter.columnKey || sorter.field, order: sorter.order } as S : null);
    }
  }, [onSort]);

  // const applyHandler = () => {
  //   if (onRefresh) {
  //     onRefresh();
  //   }
  //   setSelectedRows([]);
  // };

  // const titleJSX = (
  //   <div className={styles.head} >
  //     {EmbeddedContent && (
  //       <EmbeddedContent
  //         selectedKeys={selectedRows}
  //         onRefresh={applyHandler}
  //       />
  //     ) }
  //   </div>
  // );

  const getRowClassName = (record: ITransaction) => {
    if (record.category === null) {
      return `${styles.row} ${styles.highlighted}`;
    }
    return styles.row;
  };

  return (
    <div className={styles.wrapper} >
      <Table
        scroll={{ x: scrollWidth, y: 'calc(100vh - 340px)' }}
        tableLayout="auto"
        rowKey="id"
        loading={loading}
        columns={columns as ReturnedColumnsType}
        pagination={false}
        dataSource={transactions as ReturnedTableDataType}
        onChange={onChange}
        // title={() => (selectedCount >= 2 ? titleJSX : false)}
        rowClassName={getRowClassName as any}
      />
    </div>
  );
};

export default TransactionsTable;

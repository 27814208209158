import { useCallback, useState } from 'react';
import { notification } from 'antd';
import { read, utils } from 'xlsx';
import { POFileType } from './types';
import { useTranslate } from '../../../hooks';
import { makeKeyItemPO, walletValidator } from '../utils';

type ReturnedType = {
  payments: any[];
  fileList: any[];
  onCloseModal: () => void;
  onUpload: (file: any) => boolean;
  onDrop: (key: string) => void;
  removeCurrentPO: (key: string) => void;
  onClearFiles: () => void;
};

export const useUploadFile = (callback: () => void): ReturnedType => {
  const { t } = useTranslate();
  const [fileList, setFileList] = useState<any[]>([]);
  const [payments, setPayments] = useState<POFileType[]>([]);

  const onClearFiles = () => {
    setFileList([]);
    setPayments([]);
  };

  const onCloseModal = (): void => {
    callback();
    onClearFiles();
  };

  const isFileNameValid = (file: any): boolean => {
    const isFileExist = fileList.find((f) => f.name === file.name);
    const extension = file.name.split('.').pop().toLowerCase();
    if (isFileExist) {
      notification.error({
        message: t('file.exist'),
      });
      return false;
    }
    if (file.name.length > 100) {
      notification.error({
        message: t('file.nameLength'),
      });
      return false;
    }

    if (extension !== 'xlsx') {
      notification.error({
        message: t('file.fileFormat'),
      });
      return false;
    }

    return true;
  };

  const trimObjectFields = <T>(obj: T): T => {
    const trimmedObj: Record<string, unknown> = {};

    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        trimmedObj[key] = (obj[key] as string).trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }

    return trimmedObj as T;
  };

  const checkPayment = async (item: POFileType) => {
    const Payout = item.Payout ? item.Payout.toString().replace(',', '.') : 0;
    const isWalletValid = await walletValidator(item?.Wallet, item?.Network?.toUpperCase());

    return {
      ...item,
      Payout: Payout || null,
      amountValid: Number(Payout) > 0,
      walletValid: isWalletValid,
    };
  };

  const onUpload = (file: any): boolean => {
    if (!isFileNameValid(file)) return false;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e?.target?.result as ArrayBufferLike);
      const workbook = read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: POFileType[] = utils.sheet_to_json(worksheet, { range: 0 });
      const resp = jsonData
        .map(trimObjectFields<POFileType>)
        .map(checkPayment);

      try {
        setFileList((prevState) => [...prevState, file]);
        resp.map(item => item.then((res: any) => {
          setPayments(prev => ([...prev, res]));
          return res;
        }));
      } catch (error: unknown | any) {
        console.log(error);
      }
    };
    reader.readAsArrayBuffer(file);

    return false;
  };

  const onDrop = (key: string): void => {
    setFileList([]);
    setPayments([]);
  };

  const removeCurrentPO = useCallback((key: string): void => {
    if (payments.length === 1) setFileList([]);

    return setPayments(prevState => prevState.filter((item: POFileType) => makeKeyItemPO(item) !== key));
  }, [payments.length]);

  return {
    payments,
    fileList,
    onCloseModal,
    onUpload,
    onDrop,
    removeCurrentPO,
    onClearFiles,
  };
};

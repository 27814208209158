import React, { ReactElement } from 'react';
import { Divider, Skeleton, Space } from 'antd';
import { useTranslate } from 'hooks';
import { CommisionType } from 'hooks/useCommission';
import { CountUp, CurrencyBadge } from 'components';
import styles from './CommissionView.module.scss';

export type CommissionViewPropsType = {
  currency: string;
  loading: boolean;
  amount: number;
  commission: CommisionType | undefined,
};

export const CommissionView = ({
  amount,
  currency,
  commission,
  loading,
}: CommissionViewPropsType): ReactElement => {
  const { t } = useTranslate();

  const totalAmount = commission ? amount + Number(commission.serviceFee) : 0;

  return (
    <Space direction="vertical" className={styles.container}>
      <Space direction="vertical" className={styles.field}>
        <span className={styles.label}>{t('commission.title')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp end={commission.serviceFee} currency={currency} className={styles.commission} isRoundDown />
          ) : (
            <Skeleton.Input active={loading} className={styles.sceleton} />
          )}
          <CurrencyBadge currency={currency} textSize="18px" />
        </Space.Compact>
      </Space>

      <Divider className={styles.divider} />

      <Space direction="vertical" className={styles.field}>
        <span className={styles.label}>{t('commission.amountReceived')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp end={totalAmount} currency={currency} className={styles.totalAmount} isRoundDown />
          ) : (
            <Skeleton.Input block active={loading} className={styles.sceleton} />
          )}

          <CurrencyBadge currency={currency} textSize="18px" />
        </Space.Compact>
      </Space>
    </Space>
  );
};

import _capitalize from 'lodash/capitalize';
import type { TranslateVariables } from './TranslateContext';

export class TranslateValueBuilder {
  constructor(private value: string) {}

  public injectVariables(variables: TranslateVariables): void {
    Object.keys(variables).forEach((key) => {
      this.value = this.value.replaceAll(`{${key}}`, variables[key]);
    });
  }

  public capitalize(): void {
    this.value = _capitalize(this.value);
  }

  get(): string {
    return this.value;
  }
}

import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IReplenishment } from 'interfaces/IReplenishment';
import { IUnit } from 'interfaces/IUnit';
import { IWallet } from 'types/IWallet';
import { PaymentStatusEnum } from 'types/PaymentStatus';
import { VerifyButton } from 'pages/Administration/ReplenishmentRequests/components';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { checkIfEnoughBalance } from './checkIfEnoughBalance';
import { CountUp, CurrencyNetworkTd, DateTime } from 'components';
import { StatusBadge } from 'components/StatusBadge';
import { UpliftRequestButton } from '../components/UpliftRequestButton/UpliftRequestButton.tsx';
import styles from '../PendingApprovalTable.module.scss';

export type GetColumnsParams = {
  currentUnit: IUnit;
  wallets: IWallet[];
  uuid: string;
  isApprove: boolean;
  onRefresh: () => void;
  t: TranslateFunc;
  onDecline: (payload: any) => void;
  openVerifyModal: (user: IReplenishment) => void;
};

export const showEmptyValue = (value: IReplenishment | string) => {
  const isNull = value === null;
  const isEmpryString = value === '';

  return (
    <span className={isNull || isEmpryString ? styles.null : ''}>
      {isNull || isEmpryString ? String(null) : String(value)}
    </span>
  );
};

export const getColumns = ({
  currentUnit,
  wallets,
  uuid,
  t,
  onRefresh,
  onDecline,
  openVerifyModal,
}: GetColumnsParams): ColumnsType<IReplenishment> => {
  const columns: ColumnsType<IReplenishment> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
    },
    {
      key: 'unitName',
      title: t('users.units.unitName'),
      dataIndex: 'unit',
      render: (unit, _) => <span style={{ textTransform: 'capitalize' }}>{unit.name}</span>,
    },
    {
      key: 'walletAddress',
      title: t('users.replenishmentRequests.walletAddress'),
      dataIndex: 'walletAddress',
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      render: (status) => <StatusBadge status={status} textTransform="capitalize" />,
    },
    {
      key: 'amount',
      title: t('users.replenishmentRequests.amount'),
      dataIndex: 'amount',
      render: (amount, row) => <CountUp end={amount} currency={row.currency} />,
    },
    {
      key: 'currency',
      title: t('currency'),
      dataIndex: '',
      render: (replen: IReplenishment) => <CurrencyNetworkTd currency={replen.currency} network={replen.network} />,
    },
    {
      key: 'description',
      title: t('administration.financialManagement.fields.description'),
      dataIndex: 'description',
      render: showEmptyValue,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
  ];

  columns.push({
    dataIndex: '',
    align: 'center',
    fixed: 'right',
    // width: 66,
    render: (_, request) => {
      const verifyButton = (
        <VerifyButton
          data={request}
          onDecline={onDecline}
          onRefresh={onRefresh}
          onModalOpen={()=>openVerifyModal(request)}
        />
      );
      const upliftButton = (
        <UpliftRequestButton
          id={request.id}
          uuid={uuid}
          onRefresh={onRefresh}
        />
      );
      // const emptyBox = <div style={{ width: '20px', height: '50px' }} />;

      const { hasBalance } = checkIfEnoughBalance(wallets, request);
      const isCurrentUnit = _.unit.uuid === uuid;
      const hasParent = !!_.unit.parentId;
      const canUplift = !!currentUnit.parentId;

      const showApproveButton = hasBalance || (hasBalance && isCurrentUnit && !canUplift);
      const showButton = request.status === PaymentStatusEnum.PENDING && (!isCurrentUnit || !hasParent);

      const actionButton = showApproveButton ? verifyButton : canUplift ? upliftButton : <></>;

      return !isCurrentUnit && showButton ? actionButton : <></>;
    },
  });

  return columns;
};

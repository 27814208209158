import axios from 'libs/axios';
import WAValidator from 'multicoin-address-validator';
import { UnknownType } from 'types/Unknown';

export const walletValidator = async (address: string, network: string) => {
  switch (network) {
    case 'TRX':
    case 'TRC20':
      if (WAValidator.validate(address, 'TRX')) {
        // try {
        //   const { data } = await axios.post(`${process.env.REACT_APP_TRON_API_URL}/wallet/getaccountresource`, {
        //     address,
        //     visible: true,
        //   }, {
        //     withoutAuthorizationToken: true,
        //   } as UnknownType);

        //   if (!Object.keys(data).length) {
        //     return false;
        //   }
        // } catch {
        //   return false;
        // }
        return true;
      }
      return false;

    case 'ETH':
    case 'ERC20':
    case 'BNB':
    case 'BEP20':
      if (!WAValidator.validate(address, 'ETH', process.env.REACT_APP_NETWORK_ID)) {
        return false;
      }
      return true;
    case 'BTC':
      if (!WAValidator.validate(address, 'BTC', process.env.REACT_APP_NETWORK_ID)) {
        return false;
      }
      return true;

    default:
      return false;
  }
};

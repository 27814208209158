import { combineReducers } from 'redux';
import { approveRequestReducer } from '../pages/Administration/ReplenishmentRequests/utils/reducer';
import { unitReducer } from '../pages/Administration/Units/reducer';
import { approvePOReducer } from '../pages/Processing/PaymentOrders/reducer';
import { notificationReducer } from 'components/Notifications/redux/reducer';
import { modalReducer } from '../components/Modal/reducer';
import { sidebarReducer } from '../components/Sidebar/reducer';

export const rootReducer = combineReducers({
  sidebarReducer,
  modalReducer,
  approvePOReducer,
  approveRequestReducer,
  unitReducer,
  notificationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

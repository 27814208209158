import { IStatus } from '../../interfaces/IStatus';
import { TransactionDirectionEnum } from 'types/TransactionDirection';
import { ColorEnum } from '../../types';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DollarOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const getTxDirections = (t: TranslateFunc): IStatus[] => [
  {
    status: TransactionDirectionEnum.PAYIN,
    color: ColorEnum.IDLE,
    icon: ArrowLeftOutlined,
  },
  {
    status: TransactionDirectionEnum.PAYOUT,
    color: ColorEnum.ERROR,
    icon: ArrowRightOutlined,
  },
  {
    status: TransactionDirectionEnum.REFUND,
    color: '#777777',
    icon: SyncOutlined,
  },
  {
    status: TransactionDirectionEnum.AGGREGATE,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.ACC_ACTIVATE,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.UNFREEZE_BALANCE_ENERGY,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.FREEZE_BALANCE_ENERGY,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.FREEZE_BALANCE_BANDWIDTH,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.UNFREEZE_BALANCE_BANDWIDTH,
    color: ColorEnum.IDLE,
  },
  {
    status: TransactionDirectionEnum.FEE,
    color: ColorEnum.PENDING,
    icon: DollarOutlined,
  },
];

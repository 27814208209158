import React from 'react';
import { Link } from 'react-router-dom';
import { Path } from 'routes/interfaces/Path';

export type StatisticsRelationLinkProps = {
  rootUnitId: number | string;
  unitId?: number | string;
  children: React.ReactNode;
  className?: string;
};

export const StatisticsRelationLink = ({
  rootUnitId,
  unitId,
  children,
  className,
}: StatisticsRelationLinkProps) => {
  const query = unitId ? `?unitId=${unitId}` : '';

  return (
    <div className={className}>
      <Link to={`${Path.ADMINISTRATION_STATISTICS}/${rootUnitId}${query}`}>
        {children}
      </Link>
    </div>
  );
};

import React, { ChangeEvent, ReactElement, useCallback } from 'react';
import { Input } from 'antd';
import { FilterInputProps } from '../types/FilterInputProps';
import { TextFilterFieldParams } from '../../../types/TextFilterFieldParams';
import styles from '../FilterInput.module.scss';

type ParamsFields = 'label' | 'maxLength' | 'regex' | 'trimOnBlur';
export type TextFilterInputProps = Pick<TextFilterFieldParams, ParamsFields>
& FilterInputProps<string>;

const TextFilterInput = ({
  label, value, onChange, deleteButton, maxLength, regex, trimOnBlur,
}: TextFilterInputProps): ReactElement | null => {
  const onTextInputBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (trimOnBlur) {
      onChange(e.target.value.trim());
    }
  }, [onChange, trimOnBlur]);

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && regex && !regex.test(e.target.value)) {
      return;
    }

    onChange(e.target.value);
  }, [onChange, regex]);

  return (
    <Input
      placeholder={label}
      maxLength={maxLength}
      suffix={deleteButton}
      onBlur={onTextInputBlur}
      className={styles.field}
      onChange={onInputChange}
      value={(value || '') as string}
    />
  );
};

export default TextFilterInput;

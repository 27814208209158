import React, { ReactElement, useContext, useMemo } from 'react';
import { Table } from 'antd';
import clsx from 'clsx';
import { useUsers } from 'pages/Administration/Managers/hooks/useUsers';
import { useTablePagination, useTranslate } from 'hooks';
import useSocketStatus from 'hooks/useSocketStatus';
import { TranslateContext } from 'contexts';
import { getColumns } from './utils/getColumns';
import { Pagination } from 'components';
import styles from './ManagersTable.module.scss';

type ManagersTableProps = {
  loading: boolean;
  managerIds?: number[];
};

export const ManagersTable = ({
  loading,
  managerIds,
}: ManagersTableProps): ReactElement => {
  const { localeSettings: { locale } } = useContext(TranslateContext);
  const { page, perPage, setPage, setPerPage } = useTablePagination({ withoutURL: true });
  const { t } = useTranslate();

  const {
    data,
    refetch,
    isLoading: isUsersLoading,
  } = useUsers({
    filter: { id: { in: managerIds?.length ? managerIds : [0] } },
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  useSocketStatus(refetch);

  const managers = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const columns = useMemo(() =>
    getColumns({
      t,
      locale,
    }),
  [locale, t]);

  return (
    <div
      className={clsx(styles.wrapper, { [styles.empty]: !totalCount })}
      style={{ height: 'calc(250px + 54px + 40px + 20px)' }} // table-body + table-header + pagination + gap height
    >
      <Table
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ y: totalCount && !isUsersLoading ? 250 : 'calc(250px + 40px + 20px)' }} // table-body + pagination + gap height
        rowClassName={styles.row}
        dataSource={managers}
        loading={isUsersLoading || loading}
        showHeader={!isUsersLoading && !loading && !!totalCount}
      />

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isUsersLoading || !totalCount}
        disablePerPageHandler={isUsersLoading || !totalCount}
        elementsSize="small"
      />
    </div>
  );
};

import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Permission } from '@fanckler/processing-auth';
import { noop } from 'lodash';
import { IUser } from 'interfaces/IUser';
import { StageEnum } from 'hooks/use2FA';

export type CheckPermissionsFunction = (permissions: Permission[]) => boolean;

export type LoginParams = {
  email?: string,
  password: string,
  otpCode?: string,
  withPasswordless?: boolean,
};

export interface IAuthorisationContext {
  loading: boolean;
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>
  path: string | null;
  token: string | null;
  passwordless: string | null;
  fingerprint: string | undefined;

  twoFA: {
    qrcode: string | null;
    stage: StageEnum,
    prevStage: StageEnum,
    setPrevStage: Dispatch<SetStateAction<StageEnum>>,
    setStage: Dispatch<SetStateAction<StageEnum>>,
  };

  login: (params: LoginParams) => void;
  logout: (options?: { message?: string }) => void;
  refreshUser: () => void;
  checkPermissions: CheckPermissionsFunction;
}

export const AuthorisationContext = React.createContext<IAuthorisationContext>({
  path: null,
  token: null,
  user: null,
  setUser: noop,
  loading: false,
  passwordless: null,
  fingerprint: undefined,

  twoFA: {
    qrcode: null,
    stage: StageEnum.DEFAULT,
    prevStage: StageEnum.DEFAULT,
    setStage: noop,
    setPrevStage: noop,
  },

  login: noop,
  logout: noop,
  refreshUser: noop,
  checkPermissions: () => false,
});

export const useAuthorisationContext = () => useContext(AuthorisationContext);

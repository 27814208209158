import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Form, Input, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { format } from 'date-fns';
import { IReplenishment } from 'interfaces/IReplenishment';
import { useModal } from '../../../../../components/Modal/hooks/useModal';
import { useTranslate } from '../../../../../hooks';
import { VariablesType } from '../../hooks/useReplenishmentMutations';
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  FontSizeOutlined,
  NodeIndexOutlined, WalletOutlined } from '@ant-design/icons';
import styles from './FormVerifyRequest.module.scss';

type FormVerifyRequestType = {
  onApprove: (payload: VariablesType) => void;
  onDecline: (payload: VariablesType) => void;
};

const FormVerifyRequest = ({ onApprove, onDecline }: FormVerifyRequestType): ReactElement => {
  const { isOpen, payload } = useModal('replenishmentRequests');
  const [address, setAddress] = useState<string>();
  const [form] = Form.useForm();
  const { t } = useTranslate();

  const {
    walletAddress,
    description,
    createdAt,
    network,
    amount,
    id,
  } = payload as Partial<IReplenishment>;

  const createdAtFormat = createdAt && format(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss');

  useEffect(() => setAddress(walletAddress), [walletAddress]);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        createdAt: createdAtFormat,
        walletAddress,
        description: description,
        network,
        amount,
      });

      return () => {
        form.resetFields();
      };
    }

    return undefined;
  }, [
    createdAtFormat,
    walletAddress,
    description,
    network,
    isOpen,
    amount,
    form,
  ]);

  const onApproveRequest = async () => {
    await onApprove({ id: id as number, type: 'approve' });
  };

  const onDeclineRequest = async () => {
    await onDecline({ id: id as number, type: 'decline' });
  };

  return (
    <>
      <Typography.Title level={3} className={styles.titleWarn}>
        {t('users.replenishmentRequests.information')}
      </Typography.Title>
      <Form
        name="replenishmentRequests"
        form={form}
        className={styles.form}
      >

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Form.Item
            name="network"
            help={t('processing.payway')}
            className={styles.inputWrapper}
          >
            <Input
              readOnly
              placeholder={t('processing.payway')}
              prefix={<NodeIndexOutlined className={clsx(styles.icon, styles.payway)} />}
              className={styles.input}
            />
          </Form.Item>
        </div>

        <Form.Item
          name="amount"
          help={t('processing.paymentOrders.amount')}
          className={styles.inputWrapper}
        >
          <Input
            readOnly
            placeholder={t('processing.paymentOrders.amount')}
            prefix={<DollarCircleOutlined className={clsx(styles.icon, styles.amount)} />}
            className={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="walletAddress"
          help={t('processing.destinationAddress')}
          className={styles.inputWrapper}
        >
          <Input.Group className={clsx(styles.input, styles.group)}>
            <Input.TextArea
              readOnly
              value={address}
              defaultValue={address}
              wrap="soft"
              autoSize={{ minRows: 1, maxRows: 2 }}
              className={clsx(styles.textArea)}
            />
            <WalletOutlined className={clsx(styles.icon, styles.address)} />
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="description"
          help={t('administration.financialManagement.fields.description')}
          className={styles.inputWrapper}
        >
          <Input
            readOnly
            prefix={<FontSizeOutlined className={styles.icon} />}
            className={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="createdAt"
          help={t('created')}
          className={styles.inputWrapper}
        >
          <Input
            readOnly
            placeholder={t('created')}
            prefix={<CalendarOutlined className={clsx(styles.icon, styles.calendar)} />}
            className={styles.input}
          />
        </Form.Item>

        <Form.Item className={styles.inputWrapper}>
          <div className={styles.buttons}>
            <Tooltip title={t('users.replenishmentRequests.approve')} placement="bottom">
              <Button
                type="primary"
                htmlType="button"
                onClick={onApproveRequest}
                icon={<CheckOutlined />}
                className={clsx(styles.button, styles.approveBtn)}
              >
                {t('processing.paymentOrders.approveBtn')}
              </Button>
            </Tooltip>

            <Tooltip title={t('users.replenishmentRequests.decline')} placement="bottom">
              <Button
                type="link"
                htmlType="button"
                onClick={onDeclineRequest}
                icon={<CloseOutlined />}
                className={clsx(styles.button, styles.declineBtn)}
              >
                {t('processing.paymentOrders.declineBtn')}
              </Button>
            </Tooltip>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormVerifyRequest;

import { IWallet } from 'types/IWallet';

export type RequestType = {
  amount: string | number,
  currency?: string,
  network?: string,
};

type ReturnedType = { balance: string | number; hasBalance: boolean };

export const checkIfEnoughBalance = (wallets: IWallet[], request: RequestType): ReturnedType => {
  const { amount, currency, network } = request;

  const wallet = wallets?.find((i: IWallet) => i.network === network && i.currency === currency);

  return {
    balance: wallet?.balance || 0,
    hasBalance: (wallet?.balance || 0) >= amount,
  };
};

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { init as hjInit } from 'libs/hotjar';
import initSentry from './setup/sentry';
import { store } from 'init/store';
import { TranslateProvider } from './contexts';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from './components/ErrorBoundary';
import './index.scss';

initSentry();
hjInit();

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <BrowserRouter>
    <TranslateProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </TranslateProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

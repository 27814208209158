import React, { ReactElement } from 'react';
import { Image, Typography } from 'antd';
import { ExpiryTimeType, InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useTranslate } from 'hooks';
import { CurrencyIcon } from 'utils/currencyIcon';
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount';
import styles from './InvoiceView.module.scss';

export type InvoiceViewProps = {
  invoice: InvoiceFormDataType;
  isAnyAmount?: boolean;
};

export const InvoiceView = ({ invoice, isAnyAmount }: InvoiceViewProps): ReactElement => {
  const { t } = useTranslate();

  const { Title, Text } = Typography;
  const {
    title,
    currency,
    network,
    amount,
    customerEmail,
    expirationAt,
    description,
  } = invoice;

  const getExpiryTime = (expiryTime: string) => {
    let timeView: string;

    if (expiryTime.includes('custom')) {
      const time: ExpiryTimeType = JSON.parse(expiryTime.split('|')[1]);
      timeView = `${time.days} days ${time.hours} hours`;
    } else {
      const time: ExpiryTimeType = JSON.parse(expiryTime);
      timeView = `${time.days} days ${time.hours} hours`;
    }

    return timeView;
  };

  return (
    <div className={styles.card}>
      <div className={styles.field}>
        <Title className={styles.label}>{t('processing.payway')}</Title>
        <Text className={styles.value}>{network}</Text>
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>{t('processing.paymentOrders.amount')}</Title>

        {isAnyAmount ? (
          <Text className={styles.value}>{t('processing.paymentOrders.anyAmount')}</Text>
        ) : (
          <div className={styles.amountField}>
            <Text className={styles.value}>{formatCurrencyAmount(amount, currency)}</Text>

            <div className={styles.currency}>
              <Image src={CurrencyIcon[currency]} className={styles.icon} preview={false} />
              <Text className={styles.value}>{currency}</Text>
            </div>
          </div>
        )}
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>{t('processing.paymentOrders.fee')}</Title>
        <Text className={styles.value}>100% {t('invoice.select.businessPays')}</Text>
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>{t('invoice.fields.emailCustomer')}</Title>
        <Text className={styles.value}>{customerEmail || '-'}</Text>
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>{t('invoice.fields.expiryTime')}</Title>
        <Text className={styles.value}>{getExpiryTime(expirationAt)}</Text>
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>{t('invoice.fields.title')}</Title>
        <Text className={styles.value}>{title}</Text>
      </div>

      <div className={styles.field}>
        <Title className={styles.label}>
          {t('administration.financialManagement.fields.description')}
        </Title>

        <Text className={styles.value}>{description || '-'}</Text>
      </div>
    </div>
  );
};

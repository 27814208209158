import React, { ReactElement, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Image as AntdImage, Tooltip } from 'antd';
import clsx from 'clsx';
import { truncate } from 'lodash';
import { ImageStatusEnum, useCheckImage } from 'hooks';
import { generateRandomColor } from '../../utils/generateRandomColor';
import { ReactComponent as Snowflake } from 'assets/icons/Snowflake.svg';
import styles from './AvatarWithName.module.scss';

type AvatarWithNameProps = {
  name: string;
  uuid: string;
  logo?: string | null;
  badgeCount: number;
  isActive: boolean;
};

export const AvatarWithName = ({
  name,
  uuid,
  logo,
  badgeCount,
  isActive,
}: AvatarWithNameProps): ReactElement => {
  const status = useCheckImage({ src: logo || '' });
  const { pathname } = useLocation();

  const {
    color,
    colorWithAlpha,
  } = useMemo(() => generateRandomColor(name), [name]);

  const truncatedName = truncate(name, {
    length: 50,
    omission: '...',
  });

  return (
    <Link to={`${pathname}/${uuid}`} className={styles.container}>
      {/* <Badge count={badgeCount} offset={[5, -5]}> */}
      <div className={styles.avatarWrapper}>
        <Avatar
          size="large"
          {...(isActive && { style: { backgroundColor: `#${colorWithAlpha}` } })}
          className={clsx(styles.avatar, {
            [styles.isFreeze]: !isActive,
            [styles.isLogo]: status === ImageStatusEnum.SUCCESS,
          })}
        >
          {isActive ?
            status === ImageStatusEnum.SUCCESS && logo ? (
              <AntdImage
                src={`${process.env.REACT_APP_AUTH_URI}${logo}`}
                preview={false}
                className={styles.logo}
              />
            ) : (
              <span style={{ color: `#${color}` }} className={styles.letterName}>
                {name.slice(0, 1)}
              </span>
            ) : (
              <Snowflake className={styles.freezeIcon} />
            )}
        </Avatar>
      </div>
      {/* </Badge> */}
      <span className={styles.name}>
        <Tooltip title={name}>{truncatedName}</Tooltip>
      </span>
    </Link>
  );
};

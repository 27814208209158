import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { ColumnsType } from 'antd/es/table';
import { IExternalUser } from '../../../../interfaces/IExternalUser';
import { Sort } from '../../../../hooks/useTableSort';
import { CheckPermissionsFunction } from '../../../../contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from '../../../../contexts/TranslateContext/TranslateContext';
import { DateTime, EditButton } from 'components';
import { UserLabels } from '../components/UserLabels';
import { UserLink } from '../components/UserLink';
import styles from '../ExternalUsers.module.scss';

export interface GetColumnsParams {
  t: TranslateFunc;
  sort: Sort | null;
  editUser: (user: IExternalUser) => void;
  onRefresh: () => void;
  checkPermissions: CheckPermissionsFunction;
}

export const showEmptyValue = (value: IExternalUser | string) => {
  const isNull = value === null;
  const isEmpryString = value === '';

  return (
    <span className={isNull || isEmpryString ? styles.null : ''}>
      {isNull || isEmpryString ? String(null) : String(value)}
    </span>
  );
};

const getColumns = ({
  t,
  sort,
  editUser,
  onRefresh,
  checkPermissions,
}: GetColumnsParams): ColumnsType<IExternalUser> => {
  const columns: ColumnsType<IExternalUser> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: '110px',
      sorter: true,
      sortOrder: sort && sort.field === 'id' ? sort.order : undefined,
    },
    {
      key: 'clientId',
      title: t('processing.clientId'),
      dataIndex: 'clientId',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'clientId' ? sort.order : undefined,
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'name' ? sort.order : undefined,
      render: showEmptyValue,
    },
    {
      key: 'email',
      title: t('email'),
      dataIndex: 'email',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'email' ? sort.order : undefined,
      render: showEmptyValue,
    },
    {
      key: 'phone',
      title: t('phone'),
      dataIndex: 'phone',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'phone' ? sort.order : undefined,
      render: showEmptyValue,
    },
    {
      key: 'role',
      title: t('users.roles.one'),
      dataIndex: 'role',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'role' ? sort.order : undefined,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      width: '250px',
      sorter: true,
      sortOrder: sort && sort.field === 'updatedAt' ? sort.order : undefined,
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
    {
      key: 'labels',
      title: t('users.externalUsers.labels'),
      dataIndex: 'labels',
      width: '250px',
      render: (_, user) => <UserLabels user={user} onRefresh={onRefresh} />,
    },
  ];

  if (checkPermissions([Permission.CLIENT_USER_READ])) {
    columns.push({
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      className: styles.userLink,
      render: (_, user) => <UserLink user={user} />,
    });
  }

  if (checkPermissions([Permission.CLIENT_USER_UPDATE])) {
    columns.push({
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      className: styles.editUser,
      render: (_, user) => <EditButton onClick={() => editUser(user)} />,
    });
  }

  return columns;
};

export default getColumns;

import React, { ReactElement } from 'react';
import { Card, Space } from 'antd';
import Favorite from './Favorite';
import { CountUp, CurrencyBadge, RiskScore, WalletAddress } from 'components';
import styles from './WalletItem.module.scss';

export type WalletItemProps = {
  currency: string;
  walletAddress: string;
  balance: string | number;
  payway: string;
  isFavorite: boolean;
  onToggleFavorite: () => void;
  riskScore: number;
};

export const WalletItem = ({
  walletAddress,
  currency,
  balance,
  payway,
  isFavorite,
  onToggleFavorite,
  riskScore,
}: WalletItemProps): ReactElement => {
  return (
    <Card className={styles.card}>
      <Space className={styles.body}>
        <Space className={styles.header}>
          <RiskScore riskScore={riskScore} />
          <WalletAddress
            walletAddress={walletAddress}
            startLength={6}
            endLength={4}
          />
          <Favorite
            isFavorite={isFavorite}
            onToggleFavorite={onToggleFavorite}
          />
        </Space>
        <Space className={styles.balanceWrapper}>
          <CountUp
            isRoundDown
            end={+balance}
            currency={currency}
            className={styles.balance}
          />
          <CurrencyBadge currency={currency} width="22px" textSize="16px" />
          <span className={styles.payway}>({payway})</span>
        </Space>
      </Space>
    </Card>
  );
};

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import { AppState } from '../../../../../init/rootReducer';
import { IReplenishment } from 'interfaces/IReplenishment';
import { useTranslate } from '../../../../../hooks';
import { removeRequestFromApprove } from '../../utils/actions';
import { ProgressButton } from '../ProgressButton';
import { AuditOutlined } from '@ant-design/icons';
import styles from './VerifyButton.module.scss';

export interface IEditButtonProps {
  data: IReplenishment,
  onDecline: (id: number) => void,
  onRefresh: () => void;
  onModalOpen: () => void;
}

const VerifyButton = ({ data, onDecline, onRefresh, onModalOpen }: IEditButtonProps): ReactElement => {
  const requestIds = useSelector((state: AppState) => state.approvePOReducer);
  const [isApproved, setApproved] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslate();

  useEffect(() => {
    setApproved(requestIds.includes(data.id as never));
  }, [data.id, requestIds]);

  const declineHandler = async () => {
    dispatch(removeRequestFromApprove(data.id));
    await onDecline(data.id);
  };

  const refresh = useCallback(async () => {
    await onRefresh();
    setApproved(false);
  }, [onRefresh]);

  return (
    <>
      {isApproved ? (
        <ProgressButton
          onRefresh={refresh}
          declineHandler={declineHandler}
          onFinish={()=> dispatch(removeRequestFromApprove(data.id))}
        />
      ) : (
        <Tooltip placement="left" title={t('users.replenishmentRequests.approveOrDecline')}> {/* users.replenishmentRequests.decline */}
          <Button
            className={clsx(styles.button, { [styles.isApproved]: !isApproved })}
            onClick={onModalOpen}
            icon={<AuditOutlined className={styles.icon} />}
          />
        </Tooltip>)}
    </>
  );
};

export default VerifyButton;

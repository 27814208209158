import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import clsx from 'clsx';
import { useClients, useTablePagination, useTranslate } from 'hooks';
import { AuthorisationContext } from 'contexts';
import { getColumns } from './utils/getColumns';
import { Pagination } from 'components';
import styles from './ClientsTable.module.scss';

type ClientsTableProps = {
  unitId: number;
  loading: boolean;
  isRefetch: boolean
  onSetRefetch: React.Dispatch<React.SetStateAction<boolean>>,
  onSetBreadcrumbs: () => void;
};

export const ClientsTable = ({
  unitId,
  loading,
  isRefetch,
  onSetRefetch,
  onSetBreadcrumbs,
}: ClientsTableProps): ReactElement => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { checkPermissions } = useContext(AuthorisationContext);
  const { page, perPage, setPage, setPerPage } = useTablePagination();

  const { data, isFetching, isLoading: isClientsLoading, refetch } = useClients({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    filter: { and: [{ unit: { id: { eq: unitId } } }] },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  useEffect(() => {
    if (isRefetch) {
      refetch();
    }
  }, [isRefetch, refetch]);

  useEffect(() => {
    if (!isFetching) {
      onSetRefetch(false);
    }
  }, [isFetching, onSetRefetch]);

  const clients = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const columns = useMemo(() => getColumns(
    t,
    navigate,
    checkPermissions,
    onSetBreadcrumbs,
  ), [t, navigate, checkPermissions, onSetBreadcrumbs]);

  return (
    <div
      className={clsx(styles.wrapper, { [styles.empty]: !totalCount })}
      style={{ height: 'calc(250px + 54px + 40px + 20px)' }} // table-body + table-header + pagination + gap height
    >
      <Table
        rowKey="id"
        tableLayout="auto"
        columns={columns}
        scroll={{ y: (!!totalCount && !loading) ? 250 : 'calc(250px + 40px + 20px)' }} // table-body + pagination + gap height
        pagination={false}
        loading={loading || isClientsLoading}
        dataSource={clients}
        rowClassName={styles.row}
        expandable={{ showExpandColumn: false }}
        showHeader={!loading && !!totalCount}
      />

      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isClientsLoading || !totalCount}
        disablePerPageHandler={isClientsLoading || !totalCount}
        elementsSize="small"
      />
    </div>
  );
};

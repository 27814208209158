import * as CryptoJS from 'crypto-js';

interface SignatureHeaders {
  'x-signature': string;
  'x-date': string;
}

const prepareData = (data: Record<any, any>): Record<string, any> => {
  return Object.keys(data)
    .filter(
      (key) =>
        typeof data[key] !== 'object' &&
        typeof data[key] !== 'boolean' &&
        data[key] !== undefined,
    )
    .sort()
    .reduce((acc: Record<string, any>, currentKey) => {
      acc[currentKey] = data[currentKey];
      return acc;
    }, {});
};

export const getAuthSignature = (
  data: Record<any, any>,
  date: number,
  key: string,
) => {
  const sortedData = prepareData(data);
  const message = Object.values(sortedData).join('') + date;

  const hash = CryptoJS.HmacSHA512(message, key);
  return hash.toString(CryptoJS.enc.Hex);
};

export const generateSignature = (
  data: Record<any, any>,
  key: string,
): SignatureHeaders => {
  const date = Date.now();
  const signature = getAuthSignature(data, date, key);

  return {
    'x-signature': signature,
    'x-date': date.toString(),
  };
};

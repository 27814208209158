import axios, { AxiosError } from 'libs/axios';
import { UseMutateFunction, useMutation, useQuery } from 'libs/reactQuery';
import { IExternalUser, IUserLabel } from 'interfaces/IExternalUser';

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

type LabelsResult = {
  query: {
    data: { totalCount: number, nodes: IUserLabel[] },
    refetch: () => void,
    isLoading: boolean,
  },
  addLabel: {
    mutate: UseMutateFunction<void, AxiosError<{ message: string }>, number, unknown>,
    isLoading: boolean,
  },
  removeLabel: {
    mutate: UseMutateFunction<void, AxiosError<{ message: string }>, number, unknown>,
    isLoading: boolean,
  },
  createLabel: {
    mutate: UseMutateFunction<void, AxiosError<{ message: string }>, { name: string; }, unknown>,
    isLoading: boolean,
  },
};

const useLabels = (user: Partial<IExternalUser>, params?: any, options?: OptionType): LabelsResult => {
  const getLabels = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/labels`, {
      params,
    });
    return data;
  };

  const createLabelAndAddToClient = async (body: { name: string }) => {
    await axios.post(
      `${process.env.REACT_APP_CLIENT_HOST}/labels/create/${user?.uuid}`,
      body,
    );
  };

  const addLabelToClient = async (labelId: number) => {
    await axios.patch(
      `${process.env.REACT_APP_CLIENT_HOST}/clients/label/${user?.uuid}`,
      {
        labelId,
        addLabel: true,
      },
    );
  };

  const removeLabelFromClient = async (labelId: number) => {
    await axios.patch(
      `${process.env.REACT_APP_CLIENT_HOST}/clients/label/${user?.uuid}`,
      {
        labelId,
        addLabel: false,
      },
    );
  };

  const {
    mutate: mutationCreateLabel,
    isPending: isCreating,
  } = useMutation({ mutationFn: createLabelAndAddToClient, ...options });
  const {
    mutate: mutationAddLabel,
    isPending: isAdding,
  } = useMutation({ mutationFn: addLabelToClient, ...options });
  const {
    mutate: mutationRemoveLabel,
    isPending: isRemoving,
  } = useMutation({ mutationFn: removeLabelFromClient, ...options });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['clients'],
    queryFn: getLabels,
  });

  return {
    query: {
      data,
      refetch,
      isLoading,
    },
    addLabel: {
      mutate: mutationAddLabel,
      isLoading: isAdding,
    },
    removeLabel: {
      mutate: mutationRemoveLabel,
      isLoading: isRemoving,
    },
    createLabel: {
      mutate: mutationCreateLabel,
      isLoading: isCreating,
    },
  };
};

export default useLabels;

import React, { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'libs/axios';
import { notification, Select } from 'antd';
import clsx from 'clsx';
import { ITeamTransaction } from 'interfaces/ITeamTransaction';
import { ITransaction } from 'interfaces/ITransaction';
import { ITransactionGroup } from 'pages/Administration/FinancialManagement/types';
import { ITransactionCategory } from 'pages/Administration/FinancialManagement/types';
import { useTranGroups } from 'pages/Administration/FinancialManagement/hooks';
import { useTranslate } from 'hooks';
import { useTransactionMutation } from '../../hooks/useTransactionCategoryMutation';
import styles from './CategoryTd.module.scss';

type TransactionCategoryType = ITransactionGroup & {
  group: ITransactionGroup;
};

type CategoryTdProps = {
  category: TransactionCategoryType;
  transaction: ITeamTransaction | ITransaction;
  onRefresh: () => void;
};

export const CategoryTd = ({ category, transaction, onRefresh }: CategoryTdProps) => {
  const { t } = useTranslate();
  const [selectedGroup, setSelectedGroup] = useState<number | null>(category?.group?.id || null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(category?.id || null);
  const [selectedColor, setSelectedColor] = useState<string>(category?.group?.color || '');

  const { updateTransactionMutation } = useTransactionMutation({
    onSuccess: async () => {
      notification.success({
        message: t('transaction.categorySuccessfullyUpdated'),
      });
      onRefresh();
    },
    onError: (e: AxiosError<{ message: string }>) => {
      notification.error({
        message: e.response?.data?.message || t('internalServerError'),
        description: e.message,
      });
    },
  });

  const unitId = useMemo(() => transaction?.unit?.uuid, [transaction?.unit?.uuid]);
  const { data } = useTranGroups(unitId);

  useEffect(() => {
    setSelectedColor((prevState) =>
      data?.find((group: ITransactionGroup) => group?.id === selectedGroup)?.color
      || prevState
      || '',
    );
  }, [data, selectedGroup]);

  const selectGroupHandler = (value: number) => {
    setSelectedGroup(value);
    setSelectedCategory(null);
  };

  const selectCategoryHandler = (value: number) => {
    setSelectedCategory(value);
    updateTransactionMutation({ transactionId: transaction.id, categoryId: value });
  };

  const groupOptions = data?.map((group: ITransactionGroup) => ({
    value: group.id,
    label: group.name,
  })) || [];

  const isGroupInData = data?.some((item: ITransactionGroup) => item.id === selectedGroup);
  const groups = selectedGroup === null || isGroupInData
    ? groupOptions
    : [...groupOptions, { value: category?.group?.id, label: category?.group?.name, disabled: true }];

  const categories = data
    ?.find((group: ITransactionGroup) => group?.id === selectedGroup)?.categories
    ?.map((c: ITransactionCategory) => ({ value: c.id, label: c.name }))
    || [{ value: category?.id, label: category?.name, disabled: true }];

  return (
    <div className={styles.wrapper}>
      <div className={selectedColor ? styles.groupSelectWrapper : styles.noColorGroupSelectWrapper}>
        {selectedColor && (
          <div className={styles.circle} style={{ backgroundColor: selectedColor }} />
        )}
        <Select
          placeholder={t('transaction.chooseGroup')}
          options={groups}
          onSelect={selectGroupHandler}
          value={selectedGroup}
          className={clsx(styles.select, { [styles.selected]: transaction.category !== null })}
          popupClassName={styles.popup}
          bordered={false}
        />
      </div>
      <Select
        placeholder={t('transaction.chooseCategory')}
        options={categories}
        onSelect={selectCategoryHandler}
        disabled={!selectedGroup && !category?.id}
        value={selectedCategory}
        className={clsx(styles.select, { [styles.selected]: transaction.category !== null })}
        popupClassName={styles.popup}
        bordered={false}
      />
    </div>
  );
};

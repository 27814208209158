import { useState } from 'react';
import { AxiosError } from 'libs/axios';

export enum StageEnum {
  DEFAULT = 'DEFAULT',
  QR_CODE = 'QR_CODE',
  OTP_CODE = 'OTP_CODE',
}

export const use2FA = () => {
  const [qrcode, setQrcode] = useState<string | null>(null);
  const [stage, setStage] = useState<StageEnum>(StageEnum.DEFAULT);
  const [prevStage, setPrevStage] = useState<StageEnum>(StageEnum.DEFAULT);
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const onSuccess = <T extends { qrcode?: string }>(response: T, callback: () => void) => {
    setPrevStage(StageEnum.DEFAULT);

    if (response?.qrcode) {
      setQrcode(response.qrcode);
      setStage(StageEnum.QR_CODE);
    } else {
      setStage(StageEnum.DEFAULT);
      setQrcode(null);
      callback();
      setDisabled(false);
    }
  };

  const onError = <T extends { message?: string }>(error: AxiosError<T>, callback: () => void) => {
    const errorMsg = error?.response?.data?.message;
    const is2FAStage = stage === StageEnum.QR_CODE || stage === StageEnum.OTP_CODE;

    if (errorMsg?.includes('OTP code') && !is2FAStage) {
      setStage(StageEnum.OTP_CODE);
      setPrevStage(StageEnum.DEFAULT);
    } else {
      callback();
    }

    setDisabled(false);
  };

  return {
    qrcode,
    isDisabled,
    setDisabled,
    stage, setStage,
    prevStage, setPrevStage,
    onError, onSuccess,
  };
};

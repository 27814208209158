import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Image, Input, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import clsx from 'clsx';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup } from 'pages/Administration/FinancialManagement/types';
import { useTranGroups } from 'pages/Administration/FinancialManagement/hooks';
import { useWalletValidator } from 'pages/Processing/PaymentOrders/components/FormCreatePaymentOrder/hooks/useWalletValidator';
import { useCommission, useTranslate } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import { POSITIVE_FRACTIONAL_NUMBER } from 'constants/validation';
import { CurrencyIcon, formatCurrencyAmount, getPayway, truncateNumber } from 'utils';
import { checkIfEnoughBalance } from '../Tables/PendingApprovalTable/utils/checkIfEnoughBalance';
import { Button } from 'components';
import { CommissionView, WalletSelect } from './components';
import { CarryOutOutlined, DollarCircleOutlined, FontSizeOutlined, GroupOutlined, NodeIndexOutlined, WalletOutlined } from '@ant-design/icons';
import styles from './FormWithdraw.module.scss';

export type WithdrawFormDataReturned = {
  walletAddress: string;
  currency: string;
  network: string;
  amount: string;
  feeValue: string;
  totalFee: string;
  gasPrice?: string;
  group: number;
  category: number;
  description?: string;
  otpCode?: string;
};

export type WithdrawFormData = {
  walletAddress: string;
  amount: string;
  network: string;
  currency: string;
  description?: string;
  group: number;
  category: number;

  isWalletValid?: boolean;
  isAmountValid?: boolean;
};

export type FormWidthdrawPropsType = {
  unitId: string;
  onSubmit: (formData: UnknownType) => void;
  onClose: () => void;
  isLoading: boolean;
  isWithdraw: boolean;
  wallets: IWallet[];
  initialValues?: WithdrawFormData;
};

const INIT_NETWORK_OPTIONS = [
  { label: 'USDT (trc20)', value: 'USDT|TRC20' },
  { label: 'USDT (erc20)', value: 'USDT|ERC20' },
  { label: 'USDT (bep20)', value: 'USDT|BEP20' },
  { label: 'USDC (erc20)', value: 'USDC|ERC20' },
  { label: 'USDC (bep20)', value: 'USDC|BEP20' },
  { label: 'ETH (erc20)', value: 'ETH|ERC20' },
  { label: 'ETH (bep20)', value: 'ETH|BEP20' },
  { label: 'BTC (bep20)', value: 'BTC|BEP20' },
  { label: 'DAI (bep20)', value: 'DAI|BEP20' },
  // { label: 'BUSD (bep20)', value: 'BUSD|BEP20' },
  { label: 'BNB (bep20)', value: 'BNB|BEP20' },
  { label: 'TRX (Tron)', value: 'TRX|TRC20' },
  { label: 'BTC (Bitcoin)', value: 'BTC|Bitcoin' },
];

export const FormWithdraw = ({
  unitId,
  wallets,
  onClose,
  onSubmit,
  isLoading,
  isWithdraw,
  initialValues,
}: FormWidthdrawPropsType): ReactElement => {
  const [amount, setAmount] = useState('');
  const [payway, setPayway] = useState<string | null>(null);
  const [currency, network] = payway?.split('|') || [];
  const [walletAddress, setWalletAddress] = useState<string>('');

  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const [isWalletValid, setWalletValid] = useState(!!initialValues?.isWalletValid);
  const [isAmountValid, setAmoutValid] = useState(!!initialValues?.isAmountValid);

  const { data } = useTranGroups(unitId);
  const { t } = useTranslate();
  const [form] = useForm();
  const debouncedAmount = useDebounce(amount, 1000);

  const addressFrom = useMemo(() => wallets.find(
    (w) => w.network === network && w.currency === currency,
  )?.walletAddress || '',
  [currency, network, wallets]);

  const groups = data?.map((group: ITransactionGroup) => ({
    value: group.id,
    label: group.name,
  }));

  const categories = data
    ?.find((group: ITransactionGroup) => group?.id === selectedGroup)
    ?.categories?.map((category: ITransactionCategory) => ({ value: category.id, label: category.name }));

  const { walletValidator, warning } = useWalletValidator(currency, network);

  const commissionQuery = useMemo(() => ({
    amount: debouncedAmount,
    destinationAddress: walletAddress,
    addressFrom,
    network,
    currency,
  }), [debouncedAmount, walletAddress, addressFrom, network, currency]);

  const enabledCommission = useMemo(
    () => (isAmountValid && !!debouncedAmount) && (isWalletValid && !!walletAddress) && Number(debouncedAmount) !== 0,
    [isAmountValid, debouncedAmount, isWalletValid, walletAddress],
  );

  const debouncedEnabledCommission = useDebounce(enabledCommission, 500);

  const {
    data: commission,
    isLoading: commissionLoading,
    refresh,
  } = useCommission(commissionQuery, debouncedEnabledCommission);

  const currentBalance = wallets.find(
    (wallet) => wallet.network === network && wallet.currency === currency,
  )?.balance || 0;

  const isAmountReceivedMoreThanBalanceWallet = (+amount + (commission?.serviceFee || 0)) > +currentBalance;

  useEffect(() => {
    if (initialValues) {
      const tether = ['USDT', 'USDC'].includes(initialValues.currency);

      const pw = tether
        ? `${initialValues.currency}|${initialValues.network}`
        : initialValues.network === 'TRX'
          ? 'TRX|TRC20'
          : initialValues.network === 'ETH'
            ? `ETH|${initialValues.network}`
            : initialValues.currency === 'BNB'
              ? 'BNB|BEP20'
              : `${initialValues.currency}|${initialValues.network}`;

      form.setFieldsValue(initialValues);
      setAmount(initialValues.amount);
      setPayway(pw);
      setWalletAddress(initialValues.walletAddress);
      setSelectedGroup(initialValues.group);
      setSelectedCategory(initialValues.category);
    }
  }, [initialValues]);

  const onSelectGroup = async (value: number) => {
    setSelectedGroup(value);

    await form.setFieldsValue({
      group: value,
    });
  };

  const onSelectCategory = async (value: number) => {
    setSelectedCategory(value);

    await form.setFieldsValue({
      category: value,
    });
  };

  const onFinish = (formData: WithdrawFormData) => {
    const currentWallet = formData.walletAddress.split(',');
    const walletCurrency = currentWallet[2]?.split(' ')[0];

    onSubmit({
      amount: formData.amount,
      feeValue: commission?.feeValue,
      gasPrice: commission?.gasPrice,
      currency: walletCurrency || currency,
      network: getPayway(currency, network) || currentWallet[2]?.split(' ')[1],
      walletAddress: currentWallet[0],
      description: formData.description,
      group: selectedGroup,
      category: selectedCategory,
      totalFee: commission?.totalFee,

      isWalletValid,
      isAmountValid,
    });
  };

  const resetFields = () => {
    form.resetFields(['amount', 'walletAddress']);
    setAmount('');
    setWalletAddress('');
  };

  const onSelect = (e: string) => {
    resetFields();
    form.validateFields(['walletAddress']);
    form.setFieldsValue({ network: e });
    setPayway(e);
  };

  const onWalletBlur = () => {
    form.validateFields(['walletAddress']);
  };

  const amountValidator = useCallback(
    (_: unknown, value: string) => {
      if (!value) {
        setAmoutValid(false);
        return Promise.reject(t('processing.pleaseEnterAmount'));
      }

      if (Number(value) <= 0 || !value.match(POSITIVE_FRACTIONAL_NUMBER)) {
        setAmoutValid(false);
        return Promise.reject(t('processing.amountRules'));
      }

      if (isWithdraw) {
        const { hasBalance, balance } = checkIfEnoughBalance(wallets, {
          amount: value,
          currency,
          network,
        });
        if (!hasBalance) {
          setAmoutValid(false);
          return Promise.reject(
            t('processing.notEnoughFunds', {
              variables: {
                value: `${Number(value) - Number(balance)} ${currency}`,
              },
            }),
          );
        }
      }

      try {
        const curr = currency || form.getFieldValue('currency').split(' ')[0];

        if ((curr === 'USDT' || curr === 'USDC') && Number(value) < 1) {
          setAmoutValid(false);
          return Promise.reject(
            t('processing.amountLessThan', {
              variables: {
                currency: curr,
                amount: '1',
              },
            }),
          );
        }
        if (curr === 'TRX' && Number(value) < 10) {
          setAmoutValid(false);
          return Promise.reject(
            t('processing.amountLessThan', {
              variables: {
                currency: curr,
                amount: '10',
              },
            }),
          );
        }
        if (['ETH', 'BTC'].includes(curr) && Number(value) < 0.00001) {
          setAmoutValid(false);
          return Promise.reject(
            t('processing.amountLessThan', {
              variables: {
                currency: curr,
                amount: '0.00001',
              },
            }),
          );
        }
      } catch (e) {
        console.log('Error: choose wallet first');
      }
      setAmoutValid(true);
      return Promise.resolve();
    },
    [isWithdraw, t, wallets, currency, network, form],
  );

  const walletAddressValidator = (_: unknown, value: string) => {
    const selfWallet = wallets.find((wallet) => wallet.walletAddress === value);

    if (selfWallet) {
      return Promise.reject(t('selfAddressValidate'));
    } else {
      try {
        return walletValidator(_, value, setWalletValid);
      } catch (error) {
        return Promise.resolve();
      }
    }
  };

  const onAmountChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setAmoutValid(false);
    const value = e.target.value.replace(',', '.');
    setAmount(value);
    form.setFieldsValue({ amount: value });
  };

  const onSetMaxAmount = useCallback(
    async () => {
      if (payway) {
        form.validateFields(['walletAddress']);

        const [cr, pw]: string[] = payway.split('|');
        const w = wallets.find(
          (wallet) => wallet.network === pw && wallet.currency === cr,
        );
        const maxBalance = formatCurrencyAmount(w?.balance || 0, cr, true);

        try {
          const { serviceFee } = await refresh({
            amount: maxBalance,
            destinationAddress: form.getFieldValue('walletAddress'),
            addressFrom,
            network,
            currency,
          });

          const maxAmount = Number(maxBalance) - Number(serviceFee);
          if (maxAmount <= 0) {
            setAmount('');
            form.setFields([{ name: 'amount', value: '', errors: [t('processing.justNotEnoughFunds')] }]);
            notification.close('justNotEnoughFunds');
            return notification.error({
              message: t('processing.justNotEnoughFunds'),
              key: 'justNotEnoughFunds',
            });
          }
          const truncatedMaxAmount = truncateNumber({
            number: maxAmount,
            currency,
            asString: true,
          });
          setAmount(String(truncatedMaxAmount));
          form.setFields([{ name: 'amount', value: String(truncatedMaxAmount), errors: [] }]);
          form.validateFields(['amount']);
          setAmoutValid(true);
        } catch (e) {
          form.setFields([{ name: 'amount', value: maxBalance, errors: [] }]);
          setAmoutValid(true);
        }
      } else {
        form.setFields([{ name: 'amount', errors: [t('processing.pleaseSelectPayway')] }]);
        setAmoutValid(false);
      }
    },
    [t, form, payway, wallets],
  );

  const networkOptions = useMemo(() => INIT_NETWORK_OPTIONS.filter((item) =>
    wallets.find((wallet) => {
      const [cr, pw] = item.value.split('|');
      return wallet.network === pw && wallet.currency === cr;
    })),
  [wallets]);

  return (
    <Form
      form={form}
      name="createPayment"
      onFinish={onFinish}
      className={styles.form}
    >
      <div>
        {isWithdraw && (
          <Form.Item
            name="network"
            label={t('processing.payway')}
            rules={[{ required: true, message: t('processing.pleaseSelectPayway') }]}
            className={styles.formItem}
          >
            <div className={clsx(styles.select, styles.network)}>
              <NodeIndexOutlined className={styles.icon} />

              <Select
                value={payway}
                onSelect={onSelect}
                placeholder={t('processing.payway')}
              >
                {networkOptions.map((item) => {
                  const [cr, pw]: string[] = item.value.split('|');
                  const w = wallets.find(
                    (wallet) => wallet.network === pw && wallet.currency === cr,
                  );

                  return (
                    <Select.Option key={item.label} value={item.value}>
                      <div className={styles.option}>
                        <span className={styles.optionLabel}>{item.label}</span>
                        <span className={styles.optionCurrency}>
                          {formatCurrencyAmount(w?.balance || 0, cr, true)}

                          <Image
                            src={CurrencyIcon[cr]}
                            width="18px"
                            preview={false}
                            rootClassName={styles.currencyIcon}
                          />
                        </span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </Form.Item>
        )}

        <Form.Item
          required
          name="walletAddress"
          label={t('processing.destinationAddress')}
          className={styles.formItem}
          {...(isLoading && { hasFeedback: true, validateStatus: 'validating' })}
          help={warning && <span style={{ color: '#faad14', marginLeft: 10 }}>{warning}</span>}
          rules={[
            ...(isWithdraw
              ? [{ validator: walletAddressValidator, validateTrigger: ['blur', 'change'] }]
              : [{ required: true, message: t('processing.destinationAddressisRequired') }]),
          ]}
        >
          {isWithdraw ? (
            <Input
              {...warning && { status: 'warning' }}
              prefix={<WalletOutlined className={styles.icon} />}
              value={walletAddress}
              placeholder={t('processing.destinationAddress')}
              disabled={isLoading}
              onBlur={onWalletBlur}
              onChange={(e) => {
                setWalletValid(false);
                setWalletAddress(e.target.value);
                form.setFieldValue('walletAddress', e.target.value);
              }}
              className={styles.input}
            />
          ) : (
            <WalletSelect form={form} wallets={wallets} className={styles.select} />
          )}
        </Form.Item>

        <Form.Item
          required
          name="amount"
          label={t('processing.paymentOrders.amount')}
          className={styles.formItem}
          rules={[{ validator: amountValidator }]}
        >
          <div style={{ position: 'relative' }}>
            {isWithdraw && (
              <Button
                type="link"
                withoutBgShadow
                withoutWaveEffect
                disabled={!payway || !isWalletValid}
                onClick={() => onSetMaxAmount()}
                className={styles.maxButton}
              >
                Max
              </Button>
            )}

            <Input
              onBlur={() => form.validateFields(['walletAddress'])}
              value={amount}
              {...isWithdraw && {
                disabled: !payway || !isWalletValid,
              }}
              prefix={<DollarCircleOutlined className={styles.icon} />}
              placeholder={t('processing.paymentOrders.amount')}
              onChange={onAmountChange}
              className={styles.input}
            />
          </div>
        </Form.Item>

        {!isWithdraw && (
          <Form.Item
            name="description"
            label={t('administration.financialManagement.fields.description')}
            className={styles.formItem}
          >
            <Input
              prefix={<FontSizeOutlined className={styles.icon} />}
              placeholder={t('administration.financialManagement.fields.enterDescription')}
              className={styles.input}
            />
          </Form.Item>
        )}

        {isWithdraw && (
          <Form.Item
            name="group"
            label={t('transaction.transactionGroup')}
            className={styles.formItem}
            rules={[{ required: true, message: t('transaction.pleaseSelectGroup') }]}
          >
            <div className={styles.select}>
              <GroupOutlined className={styles.icon} />

              <Select
                value={selectedGroup}
                options={groups}
                placeholder={t('transaction.transactionGroup')}
                onChange={onSelectGroup}
              />
            </div>
          </Form.Item>
        )}

        {isWithdraw && (
          <Form.Item
            name="category"
            label={t('transaction.transactionCategory')}
            className={styles.formItem}
            rules={[{ required: true, message: t('transaction.pleaseSelectCategory') }]}
          >
            <div className={clsx(styles.select, { [styles.disabled]: !selectedGroup })}>
              <CarryOutOutlined className={styles.icon} />

              <Select
                options={categories}
                value={selectedCategory}
                onChange={onSelectCategory}
                disabled={!selectedGroup}
                placeholder={t('transaction.transactionCategory')}
              />
            </div>
          </Form.Item>
        )}

        {isWithdraw && (
          <CommissionView
            amount={Number(amount)}
            currency={currency}
            commission={commission}
            loading={commissionLoading}
          />
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          block
          htmlType="submit"
          loading={isLoading}
          disabled={isWithdraw
            ? (!isAmountValid && !isWalletValid) || isLoading || isAmountReceivedMoreThanBalanceWallet
            : isLoading
          }
        >
          {t('create')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

import { batch, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import { ActionModalType } from '../types/Modal';
import { ModalPayloadType, ModalReducerType } from '../types/Reducer';
import { setPayloadModal, setTypeModal, toggleVisibleModal } from '../actions';

type ReturnedType<P> = {
  isOpen: boolean;
  isEdit: boolean;
  isCreate: boolean;
  isUpload: boolean;
  isNotification: boolean;
  payload: P;
  onModalOpen: () => void;
  onModalClose: (isNotResetPayload?: any) => void;
  onSetModalPayload: (data: P) => void;
  onSetModalType: (type: ActionModalType) => void;
};

export const useModal = <P = ModalPayloadType>(modal: ModalReducerType): ReturnedType<P> => {
  const dispatch = useDispatch();
  const modalData = useSelector((state: AppState) => state.modalReducer[modal]);

  const onModalOpen = () => {
    dispatch(toggleVisibleModal({
      modal,
      value: true,
    }));
  };

  const onModalClose = (isNotResetPayload = true) => {
    batch(() => {
      dispatch(toggleVisibleModal({
        modal,
        value: false,
      }));
      dispatch(setTypeModal({
        modal,
        value: ActionModalType.DEFAULT,
      }));
      if (isNotResetPayload) {
        dispatch(setPayloadModal({
          modal,
          payload: {},
        }));
      }
    });
  };

  const onSetModalType = (modalType: ActionModalType) => {
    dispatch(setTypeModal({
      modal,
      value: modalType,
    }));
  };

  const onSetModalPayload = (data: P) => {
    dispatch(setPayloadModal<P>({
      modal,
      payload: data,
    }));
  };

  return {
    isOpen: modalData.isOpen,
    payload: modalData.payload as unknown as P,
    isEdit: modalData.type === ActionModalType.EDIT,
    isCreate: modalData.type === ActionModalType.CREATE,
    isUpload: modalData.type === ActionModalType.UPLOAD,
    isNotification: modalData.type === ActionModalType.NOTIFICATION,
    onModalOpen,
    onModalClose,
    onSetModalType,
    onSetModalPayload,
  };
};

import { useContext } from 'react';
import { AuthorisationContext } from 'contexts';

export const useConfirmRootAdmin = () => {

  const { user } = useContext(AuthorisationContext);

  const isRoot = !user?.rootUnitId && user?.groupId === 1;

  return { isRoot };
};

import { roundDown } from './roundDown';

export const formatCurrencyAmount = (
  amount: number | string,
  currency: string,
  isRoundDown?: boolean,
): string => {
  const decimals = ['ETH', 'BTC', 'DAI', 'BNB'].includes(currency) ? 5 : 2;
  const balance = Number(amount || 0) === 0 ? 0 : Number(amount || 0);

  return isRoundDown
    ? balance ? roundDown(balance, decimals).toString() : balance.toFixed(2)
    : (balance).toFixed(balance ? decimals : 2);
};

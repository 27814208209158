import {
  NotificationActionType,
  SET_NOTIFICATION_FILTERS,
  SET_NOTIFICATION_PAYLOAD,
  SET_NOTIFICATION_VISIBLE,
} from '../types/Actions';
import { Notification } from '../types/Reducer';

const initialState: Notification = {
  isOpen: false,
  payload: null,
  filters: null,
};

export const notificationReducer = (
  state = initialState,
  action: NotificationActionType,
): Notification => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case SET_NOTIFICATION_VISIBLE:
      return {
        ...state,
        isOpen: action.payload,
      };
    case SET_NOTIFICATION_PAYLOAD:
      return {
        ...state,
        payload: action.payload,
      };
    case SET_NOTIFICATION_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

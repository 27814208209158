import IconBNB from 'assets/icons/currency/svg/BNB.svg';
import IconDOGE from 'assets/icons/currency/svg/DOGE.svg';
import IconLTC from 'assets/icons/currency/svg/LTC.svg';
import IconAED from 'assets/icons/IconAED.svg';
import IconBTC from 'assets/icons/IconBTC.svg';
import IconBUSD from 'assets/icons/IconBUSD.svg';
import IconDAI from 'assets/icons/IconDAI.svg';
import IconETH from 'assets/icons/IconETH.svg';
import IconEUR from 'assets/icons/IconEUR.svg';
import IconGBP from 'assets/icons/IconGBP.svg';
import IconILS from 'assets/icons/IconILS.svg';
import IconTRX from 'assets/icons/IconTRX.svg';
import IconUAH from 'assets/icons/IconUAH.svg';
import IconUSD from 'assets/icons/IconUSD.svg';
import IconUSDC from 'assets/icons/IconUSDC.svg';
import IconUSDT from 'assets/icons/IconUSDT.svg';

export const CurrencyIcon: { [key: string]: string } = {
  'BTC/Bitcoin': IconBTC,
  'USDC/ERC20': IconUSDC,
  'ETH/ERC20': IconETH,
  'USDT/ERC20': IconUSDT,
  'USDT/TRC20': IconUSDT,
  'USDT/BEP20': IconUSDT,
  'USDC/BEP20': IconUSDC,
  'DAI/BEP20': IconDAI,
  'BUSD/BEP20': IconBUSD,
  'BNB/BEP20': IconBNB,
  'ETH/BEP20': IconETH,
  'BTC/BEP20': IconBTC,
  'TRX/TRC20': IconTRX,

  'USDT_TRC20': IconUSDT,
  'USDT_ERC20': IconUSDT,
  'USDT_BEP20': IconUSDT,
  'USDC_ERC20': IconUSDC,
  'USDC_BEP20': IconUSDC,
  'TRX_TRC20': IconTRX,
  'ETH_ERC20': IconETH,
  'ETH_BEP20': IconETH,
  'DAI_BEP20': IconDAI,
  'BUSD_BEP20': IconBUSD,
  'BTC_BITCOIN': IconBTC,
  'BTC_BEP20': IconBTC,
  'BNB_BEP20': IconBNB,

  'ETH': IconETH,
  'ERC20': IconETH,
  'TRX': IconTRX,
  'TRC20': IconTRX,
  'BTC': IconBTC,
  'Bitcoin': IconBTC,

  'BEP20': IconBNB,
  'BNB': IconBNB,
  'DAI': IconDAI,
  'BUSD': IconBUSD,

  'BNB BNB': IconBNB,
  'DAI BEP20': IconDAI,
  'BUSD BEP20': IconBUSD,
  'ETH BEP20': IconBUSD,
  'BTC BEP20': IconBUSD,

  'Litecoin': IconLTC,
  'Dogecoin': IconDOGE,

  'USDC': IconUSDC,
  'USDT': IconUSDT,
  'USDC ERC20': IconUSDC,
  'USDT TRC20': IconUSDT,
  'USDT ERC20': IconUSDT,
  'USDT BEP20': IconUSDT,
  'USDC BEP20': IconUSDC,

  'UAH': IconUAH,
  'USD': IconUSD,
  'EUR': IconEUR,
  'GBP': IconGBP,
  'ILS': IconILS,
  'AED': IconAED,
};

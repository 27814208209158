import React, { ReactElement } from 'react';
import { Image, Space, Typography } from 'antd';
import { CurrencyIcon } from 'utils/currencyIcon';

export type CurrencyBadgePropsType = {
  currency: string;
  gap?: number;
  inline?: boolean;
  revers?: boolean;
  width?: string;
  textSize?: string;
  withoutText?: boolean;
  textWeight?: number;
};

export const CurrencyBadge = ({
  currency,
  textSize,
  revers,
  inline,
  gap = 10,
  width = '20px',
  withoutText,
  textWeight = 400,
}: CurrencyBadgePropsType): ReactElement => {
  const { Text } = Typography;

  const src = CurrencyIcon[currency];

  return (
    <Space.Compact style={{ display: 'flex', alignItems: 'center', gap }}>
      {src && (
        <div style={{ display: 'inline', order: revers ? '2' : '1', height: width }}>
          <Image
            src={src}
            width={width}
            preview={false}
            style={{ display: inline ? 'inline' : 'block' }}
          />
        </div>
      )}

      {!withoutText && (
        <Text style={{
          fontWeight: textWeight,
          fontSize: textSize,
          lineHeight: `calc(${textSize} - 1px)`,
          wordBreak: 'inherit',
          order: revers ? '1' : '2',
        }}>
          {currency}
        </Text>
      )}
    </Space.Compact>
  );
};

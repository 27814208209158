import React, { ReactElement } from 'react';

export function LogoutIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill="#FE8686"
        d="M13.429 11.759h-1.201a.269.269 0 00-.21.099 6.046 6.046 0 01-4.664 2.19 6.028 6.028 0 01-4.282-1.772 6.037 6.037 0 01-1.298-1.922A5.989 5.989
        0 011.299 8a6.019 6.019 0 011.773-4.276 6.045 6.045 0 014.282-1.772 6.046 6.046 0 014.664 2.19c.05.064.13.1.21.1h1.2c.108 0 .175-.12.115-.21A7.35
        7.35 0 007.335.653 7.338 7.338 0 000 8.075a7.35 7.35 0 007.354 7.271 7.351 7.351 0 006.19-3.377.136.136 0 00-.115-.21zm1.519-3.867L12.523
        5.98a.137.137 0 00-.222.108v1.298H6.937a.137.137 0 00-.137.137v.956c0 .076.061.137.137.137H12.3v1.298c0 .115.133.18.222.108l2.425-1.913a.137.137
        0 000-.216z"
      />
    </svg>
  );
}

import React, { ReactElement, useCallback, useState } from 'react';
import { Table } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { IReplenishment } from 'interfaces/IReplenishment';
import {
  DataType,
  ReturnedColumnsType,
  ReturnedTableDataType,
  TableColumnType,
  TableDataType,
} from './types';
import { ReplenishmentSortField } from '../../types';
// import {
//   SelectedPaymentOrderActionsType,
// } from '../SelectedPaymentOrderActions/SelectedPaymentOrderActions';
import { Sort } from '../../../../../hooks';
import styles from './RequestsTable.module.scss';

export type IRequestsTableProps<S> = {
  loading: boolean;
  sort: S | null;
  requests: TableDataType;
  columns: TableColumnType;
  onSort: (sort: S | null) => void;
  onRefresh?: () => void;
  embeddedContent?: React.ComponentType<any>;
  head?: ReactElement;
  footer?: ReactElement;
  isUpdateEnabled: boolean;
  scrollWidth: number;
};

const RequestsTable = <S extends Sort<ReplenishmentSortField>>({
  footer,
  onSort,
  loading,
  columns,
  requests,
  onRefresh,
  scrollWidth,
  isUpdateEnabled,
  embeddedContent: EmbeddedContent,
}: IRequestsTableProps<S>): ReactElement => {
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const selectedCount = selectedRows.length;

  const onChange = useCallback((
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<IReplenishment> | SorterResult<IReplenishment>[],
  ) => {
    if (!Array.isArray(sorter)) {
      onSort(sorter.order ? { field: sorter.columnKey || sorter.field, order: sorter.order } as S : null);
    }
  }, [onSort]);

  const applyHandler = () => {
    if (onRefresh) {
      onRefresh();
    }
    setSelectedRows([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: false,
    }),
  };

  const titleJSX = (
    <div className={styles.head} >
      {EmbeddedContent && (
        <EmbeddedContent
          onRefresh={applyHandler}
          onDeselectKeys={()=>{setSelectedRows([]);}}
          selectedKeys={selectedRows}
        />
      ) }
    </div>
  );

  return (
    <div className={styles.wrapper} >
      <Table
        scroll={{ x: scrollWidth, y: 'calc(100vh - 340px)' }}
        tableLayout="auto"
        rowKey="id"
        rowSelection={isUpdateEnabled ? {
          type: 'checkbox',
          ...rowSelection,
          columnWidth: 50,
        } : undefined}
        loading={loading}
        columns={columns as ReturnedColumnsType}
        pagination={false}
        dataSource={requests as ReturnedTableDataType}
        onChange={onChange}
        title={() => (selectedCount >= 2 ? titleJSX : false)}
        rowClassName={styles.row}
      />
    </div>
  );
};

export default RequestsTable;

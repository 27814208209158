import React, { ReactElement } from 'react';
import { Button, Progress, Tooltip } from 'antd';
import clsx from 'clsx';
import { useProgressTimer, useTranslate } from '../../../../../hooks';
import { CloseOutlined } from '@ant-design/icons';
import styles from './ProgressButton.module.scss';

export interface IProgressButtonProps {
  declineHandler: () => void,
  onFinish: () => void,
  onRefresh: () => Promise<void>;
}

const ProgressButton = ({ declineHandler, onRefresh, onFinish }: IProgressButtonProps): ReactElement => {
  const percent = useProgressTimer(10000, async () => {onFinish(); await onRefresh();});
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <Progress
        type="circle"
        className={styles.progress}
        percent={percent}
        strokeColor="#ff4d4f"
        format={() => (
          <Tooltip placement="left" title={t('users.replenishmentRequests.decline')}>
            <Button
              className={clsx(styles.button, styles.cancel)}
              icon={<CloseOutlined className={clsx(styles.icon)} />}
              onClick={declineHandler}
            />
          </Tooltip>
        )} />
    </div>
  );
};

export default ProgressButton;

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';

export const useUnitTreeFlow = (unitId: number) => {
  const getUnitTree = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/units/tree`,
      {
        params: { unitId },
      },
    );
    return data;
  };

  return useQuery({
    queryFn: getUnitTree,
    queryKey: [`unit-tree-${unitId}`],
  });
};

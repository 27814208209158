import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useDetectTreeStartPosition = () => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);
  const location = useLocation();

  useLayoutEffect(() => {
    if (divRef.current) {
      const divWidth = divRef?.current?.offsetWidth;
      setWidth(divWidth);
    }
  }, [divRef, location.pathname]);

  return { width, ref: divRef };
};

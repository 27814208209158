import {
  ADD_REQUEST_TO_APPROVE,
  ADD_REQUEST_TO_APPROVE_MASS,
  REMOVE_REQUEST_FROM_APPROVE,
  RequestActionType,
} from '../types/Actions';

const initialState: number[] | [] = [];

export const approveRequestReducer = (
  state = initialState,
  action: RequestActionType,
): number[] | [] => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case ADD_REQUEST_TO_APPROVE:
      return [...state, action.payload];
    case ADD_REQUEST_TO_APPROVE_MASS:
      return [...state, ...action.payload];
    case REMOVE_REQUEST_FROM_APPROVE:
      return [...state].filter((i) => i !== action.payload);
    default:
      return state;
  }
};

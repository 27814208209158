import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Permission } from '@fanckler/processing-auth';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IExternalUser } from 'interfaces/IExternalUser';
import { Path } from 'routes/interfaces/Path';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { DateTime } from 'components';
import { EyeOutlined } from '@ant-design/icons';
import styles from '../ClientsTable.module.scss';

export const showEmptyValue = (value: IExternalUser | string) => {
  const isNull = value === null;
  const isEmpryString = value === '';

  return (
    <span className={isNull || isEmpryString ? styles.null : ''}>
      {isNull || isEmpryString ? String(null) : String(value)}
    </span>
  );
};

export const getColumns = (
  t: TranslateFunc,
  navigate: NavigateFunction,
  checkPermissions: CheckPermissionsFunction,
  onSetBreadcrumbs: () => void,
): ColumnsType<IExternalUser> => {
  const columns: ColumnsType<IExternalUser> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: '110px',
    },
    {
      key: 'clientId',
      title: t('processing.clientId'),
      dataIndex: 'clientId',
      width: '110px',
    },
    {
      key: 'rootUnitId',
      title: t('businessId'),
      dataIndex: 'rootUnitId',
      render: (rootUnidId) =>
        <div
          title={t('clickToCopy')}
          className={styles.merchantId}
          onClick={() => navigator.clipboard.writeText(rootUnidId)}
        >
          {rootUnidId}
        </div>,
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
      render: showEmptyValue,
    },
    {
      key: 'email',
      title: t('email'),
      dataIndex: 'email',
      render: showEmptyValue,
    },
    {
      key: 'phone',
      title: t('phone'),
      dataIndex: 'phone',
      render: showEmptyValue,
    },
    {
      key: 'role',
      title: t('users.roles.one'),
      dataIndex: 'role',
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
  ];

  if (checkPermissions([Permission.CLIENT_USER_READ])) {
    columns.push({
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      className: styles.userLink,
      render: (_, user) => (
        <Button
          block
          type="link"
          onClick={()=>{
            onSetBreadcrumbs();
            navigate(`${Path.USERS_EMPLOYEES_CLIENTS}/${user.uuid}`);
          }}
          icon={<EyeOutlined style={{ fontSize: '17px' }} />}
        />
      ),
    });
  }

  return columns;
};

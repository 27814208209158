import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IReplenishment } from 'interfaces/IReplenishment';
import { Filter } from 'types/Filter';
import { ReplenishmentSorting } from 'pages/Administration/ReplenishmentRequests/types';

type ReplenishmentParams = {
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset: number };
  sorting?: ReplenishmentSorting[];
};

type UseReplenByIdResult = {
  data: { nodes: IReplenishment[], totalCount: number };
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export const useReplenishmentsById = (uuid?: string, params?: ReplenishmentParams): UseReplenByIdResult => {
  const getReplenishmentRequests = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/replenishment-requests/${uuid}`, {
      params,
    });
    return data;
  };

  const {
    data,
    refetch,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['replenishment-requests', uuid || '', params || ''],
    queryFn: getReplenishmentRequests,
    staleTime: 10000,
    enabled: !!uuid,
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

import { UnknownType } from 'types/Unknown';

export enum NotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface INotification {
  id: number;
  userId: string;
  title: string;
  description: string;
  isAlert: boolean;
  payload: UnknownType;
  type: NotificationType;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  readAt: string | null;
}

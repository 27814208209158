import React, { ReactElement } from 'react';

export interface IIconProps {
  isActive?: boolean;
}

function AdministrationIcon({ isActive }: IIconProps): ReactElement {
  if (isActive) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M14.5 2a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM17 9a2 2 0 11-.001-3.999A2 2 0 0117 9z"
          opacity="0.35"
        />
        <path
          fill="#fff"
          d="M7.587 12.413l-5.294 5.294a1 1 0 00-.293.707V21a1 1 0 001 1h2.586c.265 0 .52-.105.707-.293l1.293-1.293c.265-.265.414-.625.414-1C8 18.633 8.633
          18 9.414 18c.375 0 .735-.149 1-.414l1.173-1.173a7.525 7.525 0 01-4-4z"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1DA1F2"
        d="M14.5 2a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM17 9a2 2 0 11-.001-3.999A2 2 0 0117 9z"
        opacity="0.35"
      />
      <path
        fill="#1DA1F2"
        d="M7.587 12.413l-5.294 5.294a1 1 0 00-.293.707V21a1 1 0 001 1h2.586c.265 0 .52-.105.707-.293l1.293-1.293c.265-.265.414-.625.414-1C8 18.633
        8.633 18 9.414 18c.375 0 .735-.149 1-.414l1.173-1.173a7.525 7.525 0 01-4-4z"
      />
    </svg>
  );
}

export default AdministrationIcon;

import React, { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './NumerousTd.module.scss';

export type NumerousTdProps = {
  name: string;
  amount: number;
  action?: () => void;
};

export const NumerousTd = ({
  name,
  amount,
  action,
}: NumerousTdProps): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{name}</span>
      <div className={styles.numerous}>
        <span className={styles.amount}>{amount}</span>
        {action && <ArrowRightOutlined onClick={action} className={styles.arrowIcon} />}
      </div>
    </div>
  );
};

import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import clsx from 'clsx';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import styles from './CommonLayout.module.scss';

export interface ICommonLayoutProps {
  withHeader?: boolean;
  withSidebar?: boolean;
  component: () => ReactElement;
}

const CommonLayout = ({
  withHeader,
  withSidebar,
  component: Component,
}: ICommonLayoutProps): ReactElement => (
  <Layout className={styles.layout}>
    {withSidebar && <Sidebar />}
    <Layout className={styles.layoutSite}>
      {withHeader && <Header />}
      <Layout.Content>
        <div className={clsx(styles.layoutContent, { [styles.layoutWithSidebar]: withSidebar })}>
          <Component />
        </div>
      </Layout.Content>
    </Layout>
  </Layout>
);

export default CommonLayout;

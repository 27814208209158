import {
  SET_REPLENISHMENT_REQUEST,
  SET_ROOT_UNIT_ID,
  SET_UNIT_PAGE_TITLE,
  SetReplenishmentRequest,
  SetRootUnitId,
  SetUnitPageTitle,
} from './types/Actions';

export const setUnitPageTitle = (
  payload: string | null,
): SetUnitPageTitle => ({
  type: SET_UNIT_PAGE_TITLE,
  payload,
});

export const setReplenishmentRequest = (
  payload: boolean,
): SetReplenishmentRequest => ({
  type: SET_REPLENISHMENT_REQUEST,
  payload,
});

export const setRootUnitId = (
  payload: number | null,
): SetRootUnitId => ({
  type: SET_ROOT_UNIT_ID,
  payload,
});

import React, { ReactElement } from 'react';
import { CopyToClipboard as CopyAntd } from 'react-copy-to-clipboard';
import { Button, notification, Space, Tooltip, Typography } from 'antd';
import { useTranslate } from 'hooks';
import { truncateString, TruncateStringType } from 'utils';
import { CopyOutlined } from '@ant-design/icons';
import styles from './CopyToClipboard.module.scss';

interface CopyToClipboardProps {
  walletAddress: string;
  notifyMsg?: string;
  type?: TruncateStringType,
  startLength?: number,
  endLength?: number,
  revers?: boolean,
  iconColor?: string,
}

export const CopyToClipboard = ({
  walletAddress,
  notifyMsg,
  type,
  startLength,
  endLength,
  revers,
  iconColor = '#8d8d8d',
}: CopyToClipboardProps): ReactElement => {
  const { t } = useTranslate();

  const truncatedAddress = truncateString(walletAddress, {
    startLength,
    endLength,
    type,
  });

  const handleCopy = () => {
    notification.success({
      message: notifyMsg || t('invoicePage.walletAddressCopied'),
    });
  };

  return (
    <Space className={styles.wrapper}>
      <CopyAntd text={walletAddress} onCopy={handleCopy}>
        <Space.Compact style={{ display: 'flex', cursor: 'pointer', gap: 10 }}>
          <Tooltip title={walletAddress}>
            <Typography.Text
              className={styles.text}
              ellipsis={{ tooltip: walletAddress }}
              style={{ order: revers ? '2' : '1' }}
            >
              {truncatedAddress}
            </Typography.Text>
          </Tooltip>

          <Button
            type="text"
            size="middle"
            icon={<CopyOutlined className={styles.copyIcon} />}
            style={{ color: iconColor, order: revers ? '1' : '2' }}
            className={styles.copyBtn}
          />
        </Space.Compact>
      </CopyAntd>
    </Space>
  );
};

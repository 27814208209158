type TruncateNumberProps = {
  number: string | number;
  asString?: boolean;
} & ({
  decimals: number; currency?: never
} | {
  decimals?: never; currency: string
});

const WITHOUT_SHORT_CURRENCY = [
  'BTC_BEP20',
  'BTC_BITCOIN',
  'BTC/Bitcoin',
  'BTC',

  'ETH_ERC20',
  'ETH_BEP20',
  'ETH/ERC20',
  'ETH',

  'BNB',
  'BNB_BEP20',
  'DAI',
  'DAI_BEP20',
];

export const getDecimals = ({ decimals, currency }: Omit<TruncateNumberProps, 'number'>) => {
  if (decimals) {
    return decimals;
  }
  if (currency) {
    return WITHOUT_SHORT_CURRENCY.includes(currency) ? 5 : 2;
  }
  return 2;
};

const truncateNumber = ({
  number,
  currency,
  decimals: initDecimals,
  asString,
}: TruncateNumberProps) => {
  const decimals = getDecimals({ currency, decimals: initDecimals });

  const [int, float] = number.toString().split('.');

  const truncatedValue = `${int}${float ? `.${float.slice(0, decimals)}` : ''}`;

  return asString ? truncatedValue : Number(truncatedValue);
};

export default truncateNumber;

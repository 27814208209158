import React, { ReactElement, useState } from 'react';
import { useCurrencyRate, useTranslate } from 'hooks';
import { currencyOptions } from '../BalanceTd/BalanceTd';
import { CountUp, CurrencySelect } from 'components';
import styles from './GroupBalanceTd.module.scss';

export type BalanceTdProps = {
  balances: { [key: string]: number };
};

export const GroupBalanceTd = ({ balances }: BalanceTdProps): ReactElement => {
  const [currency, setCurrency] = useState(currencyOptions[0]?.value);
  const { t } = useTranslate();
  const { totalBalance } = useCurrencyRate();
  const amount = Number(totalBalance(balances, currency));

  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{t('users.units.groupBalance')}</span>

      {currency ? (
        <div className={styles.balance}>
          <CountUp
            end={amount}
            currency={currency}
            className={styles.amount}
            isRoundDown
          />
          <CurrencySelect
            trimHeight
            wrapperWidth={100}
            currency={currency}
            onSelect={setCurrency}
            options={currencyOptions}
            withoutPopupContainer
          />
        </div>
      ) : (
        <span className={styles.amount}>0,00</span>
      )}
    </div>
  );
};

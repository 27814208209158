import React from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { UnitFormData } from '../../types';
import { useTranslate } from '../../../../../hooks';
import { Button } from 'components';
import styles from './FormFreezeUnit.module.scss';

export interface ModalUnitFormProps {
  form: FormInstance<UnitFormData>;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (unit: any) => void;
  initialValues: UnitFormData | null;
}

export const FormFreezeUnit = ({
  form,
  loading,
  onClose,
  onSubmit,
  initialValues,
}: ModalUnitFormProps) => {
  const { t } = useTranslate();

  const onFinish = () => {
    onSubmit({
      id: initialValues?.id,
      name: initialValues?.name,
      isActive: !initialValues?.isActive,
    });
  };

  const titleJSX = initialValues?.isActive
    ? t('users.units.areYouSureDeactivate', {
      variables: { unitName: `${initialValues?.name}` },
    })
    : t('users.units.areYouSureActivate', {
      variables: { unitName: `${initialValues?.name}` },
    });

  return (
    <Form form={form} onFinish={onFinish} className={styles.form}>
      <Typography className={styles.title}>{titleJSX}</Typography>

      <div className={styles.buttons}>
        <Button
          block
          color={initialValues?.isActive ? 'error' : 'primary'}
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {initialValues?.isActive ? t('deactivate') : t('activate') }
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

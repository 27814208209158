import Hotjar from '@hotjar/browser';

const hotjarVersion = 6;
let siteId: number | null = null;

const saveSiteId = () => {
  const newSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
  if (newSiteId) {
    siteId = Number(newSiteId);
  }
};

export const init = () => {
  saveSiteId();
  if (!siteId) return;

  Hotjar.init(Number(siteId), hotjarVersion, {
    debug: process.env.REACT_APP_HOTJAR_DEBUG === 'true',
  });
};

type IdentifyParameters = {
  userId: string;
  email: string;
  name: string;
};

export const identify = ({ userId, email, name }: IdentifyParameters) => {
  if (!siteId) return;

  Hotjar.identify(userId, {
    email,
    name,
  });
};

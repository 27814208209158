import React, { ReactElement, useCallback } from 'react';
import { DatePicker } from 'antd';
import _isFunction from 'lodash/isFunction';
import type { Moment } from 'moment';
import moment from 'moment';
import { FilterInputProps } from '../types/FilterInputProps';
import { DateFilterFieldParams } from '../../../types/DateFilterFieldParams';
import { BetweenComparisonType } from '../../..';
import styles from '../FilterInput.module.scss';

type ParamsFields = 'label' | 'showDateTime' | 'betweenComparisonType' | 'minDate' | 'maxDate' | 'monthOnly';
export type DateFilterInputProps = Pick<DateFilterFieldParams, ParamsFields>
& FilterInputProps<Moment | null> & {
  filters: Record<string, unknown>;
};

const DateFilterInput = ({
  filters, value, onChange, deleteButton, showDateTime,
  betweenComparisonType, minDate, maxDate, label, monthOnly,
}: DateFilterInputProps): ReactElement | null => {
  const onSelectDateAndModifyMilliseconds = useCallback((newValue: Moment | null) => {
    if (!newValue) {
      return onChange(null);
    }

    newValue.set({
      milliseconds: betweenComparisonType === BetweenComparisonType.LOWER ? 0 : 999,
    });

    if (!showDateTime && betweenComparisonType === BetweenComparisonType.LOWER) {
      return onChange(newValue.startOf('day'));
    }

    if (!showDateTime && betweenComparisonType === BetweenComparisonType.UPPER) {
      return onChange(newValue.endOf('day'));
    }

    return onChange(newValue);
  }, [betweenComparisonType, showDateTime, onChange]);

  const onSelectMonth = useCallback((newValue: Moment | null) => {
    if (!newValue) {
      return onChange(null);
    }
    newValue.set({
      date: 1,
      hours: 2,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    return onChange(newValue);
  }, [onChange]);

  const disabledDate = useCallback((date: Moment) => {
    const min = _isFunction(minDate) ? minDate(filters) : minDate;
    const max = _isFunction(maxDate) ? maxDate(filters) : maxDate;

    return Boolean(!date || (max && date.isAfter(max)) || (min && date.isBefore(min)));
  }, [filters, maxDate, minDate]);

  if (monthOnly === true) {
    const disabledDateMonth = (current: Moment) => current && current > moment().endOf('month');

    return (
      <DatePicker
        inputReadOnly
        picker="month"
        placeholder={label}
        disabledDate={disabledDateMonth}
        onChange={onSelectMonth}
        className={styles.field}
        value={(value as Moment) || null}
      />
    );
  }

  return (
    <DatePicker
      inputReadOnly
      allowClear={false}
      placeholder={label}
      format="DD.MM.YYYY"
      showTime={showDateTime}
      className={styles.field}
      disabledDate={disabledDate}
      value={(value as Moment) || null}
      suffixIcon={deleteButton}
      onChange={onSelectDateAndModifyMilliseconds}
    />
  );
};

export default DateFilterInput;

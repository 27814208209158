import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { Table } from 'antd';
import clsx from 'clsx';
import { ITeamTransaction } from 'interfaces/ITeamTransaction';
import { UnknownType } from 'types/Unknown';
import { useTablePagination, useTranslate } from 'hooks';
import { useTransactions } from './hooks/useTransactions';
import { useModal } from 'components/Modal/hooks/useModal';
import { SocketContext } from 'contexts/SocketContext';
import { getColumns } from './utils/getColumns';
import { Modal, Pagination } from 'components';
import { TransactionView } from './components';
import styles from './Transactions.module.scss';

type TrannsactionsTableProps = {
  unitId: string | number;
  loading: boolean;
  isRefetch: boolean;
  onSetRefetch: (state: boolean) => void;
};

export const TransactionsTable = ({
  unitId,
  isRefetch,
  loading,
  onSetRefetch,
}: TrannsactionsTableProps): ReactElement => {
  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });
  const { socket } = useContext(SocketContext);
  const { t } = useTranslate();

  const {
    data,
    isFetching,
    refetch: refetchTransactions,
    isLoading: isTransactionsLoading,
  } = useTransactions(unitId, {
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
  });

  const {
    isOpen,
    payload,
    isCreate,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('transactions');

  const onViewTransaction = useCallback(
    (transaction: ITeamTransaction) => {
      batch(() => {
        onModalOpen();
        onSetModalPayload({ ...transaction });
      });
    },
    [onModalOpen, onSetModalPayload],
  );

  useEffect(() => {
    return () => {
      setPage(1);
    };
  }, [setPage]);

  useEffect(() => {
    if (isRefetch) {
      refetchTransactions();
    }
  }, [isRefetch, refetchTransactions]);

  useEffect(() => {
    if (!isFetching) {
      onSetRefetch(false);
    }
  }, [isFetching, onSetRefetch]);

  useEffect(() => {
    if (socket) {
      socket.on('unit_balance_changed', async () => refetchTransactions());
    }
  }, [socket, refetchTransactions]);

  const columns = useMemo(() => getColumns({
    t,
    refetchTransactions,
    onView: onViewTransaction,
  }), [t, refetchTransactions, onViewTransaction]);

  const transactions = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const getRowClassName = (record: ITeamTransaction) => {
    if (record.category === null) {
      return `${styles.row} ${styles.highlighted}`;
    }
    return styles.row;
  };

  return (
    <div
      className={clsx(styles.wrapper, { [styles.empty]: !totalCount })}
      style={{ height: 'calc(250px + 54px + 40px + 20px)' }} // table-body + table-header + pagination + gap height
    >
      <Table
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        pagination={false}
        rowClassName={getRowClassName}
        dataSource={transactions}
        loading={isTransactionsLoading || loading}
        scroll={{ y: totalCount && !isTransactionsLoading ? 250 : 'calc(250px + 40px + 20px)' }} // table-body + pagination + gap height
        expandable={{ showExpandColumn: false }}
        showHeader={!isTransactionsLoading && !loading && !!totalCount}
      />

      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isTransactionsLoading || !totalCount}
        disablePerPageHandler={isTransactionsLoading || !totalCount}
        elementsSize="small"
      />

      <Modal
        width={450}
        destroyOnClose
        isOpen={isOpen && !isCreate}
        onClose={onModalClose}
        title={t('transaction.transactionReceipt')}
      >
        <div className={styles.infoForm}>
          <TransactionView
            close={onModalClose}
            transaction={payload as UnknownType as ITeamTransaction}
          />
        </div>
      </Modal>
    </div>
  );
};

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useProgressTimer = (duration: number, callback: () => void): number => {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const intervalId = useRef<NodeJS.Timeout>();
  // duration -= 1000;

  const tick = useCallback(() => {
    setTimeElapsed((prevTimeElapsed) => {
      const currentTimeElapsed = prevTimeElapsed + 100;

      if (currentTimeElapsed >= duration) {
        clearInterval(intervalId.current);
        setTimeElapsed(duration);
      }

      return currentTimeElapsed;
    });
  }, [duration]);

  useEffect(() => {
    if (timeElapsed < duration) {
      intervalId.current = setInterval(tick, 100);
    }

    return () => clearInterval(intervalId.current);
  }, [duration, tick, timeElapsed]);

  const percentComplete = useMemo(() => {
    const percent = ((duration - timeElapsed) / duration) * 100;
    return percent < 0 ? 0 : percent;
  }, [duration, timeElapsed]);

  useEffect(() => {
    if (percentComplete === 0) {
      intervalId.current = undefined;
      callback();
    }
  }, [percentComplete]);

  return percentComplete;
};

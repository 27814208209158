import React, { ReactElement, useContext } from 'react';
import { batch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { ActionModalType } from 'components/Modal/types/Modal';
import { useTranslate } from 'hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { AuthorisationContext } from 'contexts';
import { Button } from 'components';
import { Modal } from 'components/Modal';
import { FormCreateClient } from './components/FormCreateClient/FormCreateClient';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';

type CreateClientButtonProps = {
  unitId?: number;
  rootUnitId?: number;
  disabled?: boolean;
  onRefresh?: () => void;
};

export const CreateClientButton = ({
  unitId,
  disabled,
  onRefresh,
  rootUnitId,
}: CreateClientButtonProps): ReactElement => {
  const { checkPermissions, user } = useContext(AuthorisationContext);
  const { t } = useTranslate();

  const {
    isOpen,
    onModalOpen,
    onModalClose,
    onSetModalType,
  } = useModal('createClient');

  const openModalHandler = () => {
    batch(() => {
      onModalOpen();
      onSetModalType(ActionModalType.CREATE);
    });
  };

  const buttonCreateClient = checkPermissions([Permission.CLIENT_USER_CREATE]) && (
    <Button
      {...(unitId
        ? { suffixIcon: <PlusOutlined /> }
        : { prefixIcon: <PlusOutlined /> }
      )}
      type={(unitId ? 'link' : 'primary') as 'link'}
      withoutWaveEffect={!!unitId}
      withoutBgShadow={!!unitId}
      disabled={disabled}
      onClick={openModalHandler}
    >
      {t('client.create')}
    </Button>
  );

  return (
    <>
      {buttonCreateClient}

      <Modal
        onClose={onModalClose}
        isOpen={isOpen}
        width={450}
        title={t('client.create')}
        titleIcon={<UserAddOutlined style={{ fontSize: 20 }} />}
      >
        <FormCreateClient
          unitId={unitId}
          rootUnitId={rootUnitId || user?.rootUnitId as number}
          onRefresh={onRefresh as () => void}
        />
      </Modal>
    </>
  );
};

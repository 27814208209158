import React from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { Moment } from 'moment';
import { UnknownType } from 'types/Unknown';
import styles from './RangeDatePicker.module.scss';

const { RangePicker: RangePickerAntd } = DatePickerAntd;

const RangeDatePicker = ({
  value,
  onChange,
}: {
  value: [Moment, Moment];
  onChange: (range: [Moment, Moment]) => void;
}) => (
  <RangePickerAntd
    value={value}
    separator="-"
    allowClear={false}
    onChange={onChange as UnknownType}
    className={styles.rangeDatePicker}
  />
);

export default RangeDatePicker;

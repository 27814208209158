import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Divider } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IUnit, IUnitChildren } from 'interfaces/IUnit';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { EditButton } from '../../../EditButton';
import { StatisticsRelationLink } from 'components';
import { ActionsSpaceTd, ExpandRowButton } from 'components/TreeFlow';
import { AvatarWithName } from '../components/AvatarWithName';
import { BalanceTd } from '../components/BalanceTd';
import { GroupBalanceTd } from '../components/GroupBalanceTd';
import { NumerousTd } from '../components/NumerousTd';
import { RiskScoreTd } from '../components/RiskScoreTd';
import { LineChartOutlined } from '@ant-design/icons';
import styles from '../UnitsTable.module.scss';

export interface GetColumnsParams {
  t: TranslateFunc;
  childMode?: boolean;
  onEditUnit: (unit: IUnit, modalType?: 'managers' | 'clients' | 'wallets' | 'default') => void;
  onFreezeUnit: (unit: IUnit) => void;
  checkPermissions: CheckPermissionsFunction;
  expandedRowKeys: number[];
  handleExpand: (id: number) => void;
}

const getColumns = ({
  t,
  childMode,
  onEditUnit,
  onFreezeUnit,
  checkPermissions,
  expandedRowKeys,
  handleExpand,
}: GetColumnsParams): ColumnsType<IUnit | IUnitChildren> => {
  const actionButtonsRender = (unit: IUnit) => {
    return (
      <ActionsSpaceTd>
        <div className={styles.headerActionButtons}>
          {checkPermissions([Permission.CLIENT_UNIT_CREATE]) && (
            <>
              <EditButton onUpdate={onEditUnit} unit={unit} />

              <Divider type="vertical" className={styles.divider} />
            </>
          )}

          <StatisticsRelationLink
            rootUnitId={unit.rootUnitId}
            unitId={unit.id}
            className={styles.link}
          >
            <LineChartOutlined className={styles.icon} />
          </StatisticsRelationLink>
        </div>

        <ExpandRowButton
          isExpanded={expandedRowKeys.includes(unit?.id)}
          handleExpand={() => handleExpand(unit?.id)}
        />
      </ActionsSpaceTd>
    );
  };

  const columns: ColumnsType<IUnit | IUnitChildren> = [
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, unit) => (
        <AvatarWithName
          badgeCount={5}
          isActive={unit?.isActive}
          name={unit?.name}
          uuid={unit?.uuid}
        />
      ),
    },
    {
      key: 'adminIds',
      dataIndex: 'adminIds',
      render: (adminIds) => (
        <NumerousTd
          action={() => {}}
          amount={adminIds?.length}
          name="Managers"
        />
      ),
    },
    {
      key: 'unitCount',
      dataIndex: '',
      render: (unit) => (
        <NumerousTd
          action={() => {}}
          amount={unit?.unitCount || unit?.children?.length}
          name="Units"
        />
      ),
    },
    {
      key: 'transactionCount',
      dataIndex: 'transactionCount',
      render: (transactionCount) => (
        <NumerousTd
          action={() => {}}
          amount={transactionCount}
          name="Transactions"
        />
      ),
    },
    {
      key: 'clients',
      dataIndex: 'clients',
      render: (clients) => (
        <NumerousTd action={() => {}} amount={clients || 0} name="Users" />
      ),
    },
    {
      key: 'riskScore',
      dataIndex: 'riskScore',
      render: (_, unit) => (
        <RiskScoreTd
          name="Risk score"
          wallets={unit.wallets}
        />
      ),
    },
    {
      key: 'wallets',
      dataIndex: 'wallets',
      render: (wallets) => <BalanceTd wallets={wallets} name="Balance" />,
    },
    {
      key: 'totalBalances',
      dataIndex: 'totalBalances',
      render: (balances) => <GroupBalanceTd balances={balances} />,
    },
  ];

  columns.push({
    dataIndex: '',
    align: 'center',
    render: actionButtonsRender,
    fixed: 'right',
    width: 66,
  });

  return columns;
};

export default getColumns;

import { IStatus } from '../../interfaces/IStatus';
import { ColorEnum } from '../../types';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';

export const getGasPriceIndicators = (t: TranslateFunc): IStatus[] => [
  {
    status: 'normal',
    title: t('processing.resources.gasPrice.normal'),
    color: ColorEnum.PENDING,
  },
  {
    status: 'low',
    title: t('processing.resources.gasPrice.low'),
    color: ColorEnum.SUCCESS,
  },
  {
    status: 'high',
    title: t('processing.resources.gasPrice.high'),
    color: ColorEnum.ERROR,
  },
];

import { ActionModalType } from './Modal';
import { ModalReducerType } from './Reducer';

export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE';
export type PayloadVisible = {
  modal: ModalReducerType,
  value: boolean;
};
export type ToggleModalVisible = {
  type: typeof SET_MODAL_VISIBLE;
  payload: PayloadVisible;
};

export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export type PayloadStatus = {
  modal: ModalReducerType,
  value: ActionModalType;
};
export type SetModalType = {
  type: typeof SET_MODAL_TYPE;
  payload: PayloadStatus;
};

export const SET_MODAL_PAYLOAD = 'SET_MODAL_PAYLOAD';
export type ModalTypePayload<P> = {
  modal: ModalReducerType,
  payload: P;
};
export type SetModalPayload<P> = {
  type: typeof SET_MODAL_PAYLOAD;
  payload: ModalTypePayload<P>;
};

export type ModalActionType<P> = ToggleModalVisible | SetModalType | SetModalPayload<P>;

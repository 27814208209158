import React, { useCallback, useContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { notification, Space } from 'antd';
import { IUser } from 'interfaces/IUser';
import { NotificationStatus } from 'components/Notifications/types';
import { AuthorisationContext } from 'contexts';
import useTranslate from '../useTranslate';
import useNotifications, { type Response } from './useNotifications';
import { setNotificationFilters, setNotificationVisible } from 'components/Notifications/redux/actions';
import { BellOutlined } from '@ant-design/icons';

const useWelcomeNotification = (
  setCount: React.Dispatch<React.SetStateAction<number>>,
) => {
  const [done, setDone] = useState(Boolean(sessionStorage.getItem('WELCOME_NOTIFY')));
  const { user } = useContext(AuthorisationContext);
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const renderMessage = useCallback((undreadCount: string) => {
    const handleOpenInNotifications = () => {
      notification.close('welcome-notification');

      batch(() => {
        dispatch(setNotificationVisible(true));
        dispatch(setNotificationFilters({ status: NotificationStatus.UNREAD }));
      });
    };

    return (
      <Space size={5}>
        <span>{t('notifications.welcome.name', { variables: { name: (user as IUser).name } })},</span>
        <span style={{ color: '#1DA1F2', cursor: 'pointer' }} onClick={handleOpenInNotifications}>
          {t('notifications.welcome.unreaded', { variables: { count: undreadCount } })}
        </span>
        <span>{t('notifications.welcome.end')}</span>
      </Space>
    );
  }, [dispatch, t, user]);

  const handleSuccess = useCallback(({ totalUnreadCount }: Response) => {
    if (!totalUnreadCount) return;

    setDone(true);

    notification.info({
      key: 'welcome-notification',
      message: renderMessage(totalUnreadCount.toString()),
      icon: <BellOutlined style={{ color: '#1DA1F2', fontSize: 25 }} />,
      style: { padding: '30px 20px', width: 'auto' },
      duration: 15,
      top: 80,
    });

    setCount(totalUnreadCount);
  }, [renderMessage, setCount]);

  useNotifications({ filter: { or: [{ readAt: { is: 'null' } }] } }, {
    onSuccess: handleSuccess,
    enabled: !done, // If not done welcome notification
  });

  useEffect(() => {
    if (done) {
      sessionStorage.setItem('WELCOME_NOTIFY', 'true');
    }
  }, [done]);
};

export default useWelcomeNotification;

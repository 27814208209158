import { IUnit, IWallet } from 'interfaces';
import { CURRENCY } from '../consts';
import { ITreeFlowItem } from '../components/TreeFlowItem';

export const convertArrayToHierarchy = (
  inputArray: IUnit[],
  balanceConvertor: (wallets: IWallet[], currency: string) => number,
) => {
  const convertNode = (node: any): ITreeFlowItem => {
    const children = node?.children?.map(convertNode);

    return {
      id: node?.id,
      name: node?.name,
      balance: balanceConvertor(node?.wallets, CURRENCY),
      currency: CURRENCY,
      children: children?.length > 0 ? children : [],
      ...(node?.uuid ? { uuid: node?.uuid } : {}),
    };
  };

  const hierarchy = inputArray?.map(convertNode);
  return hierarchy;
};

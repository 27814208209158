import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../init/rootReducer';
import { toggleSidebarVisible } from '../actions';

type ReturnedType = {
  collapsed: boolean;
  animateCollapsed: boolean;
  onCollapse: () => void;
};

export const useCollapsed = (): ReturnedType => {
  const dispatch = useDispatch();
  const { isCollapsed } = useSelector((state: AppState) => state.sidebarReducer);
  const [animateCollapsed, setAnimateCollapsed] = useState(false);

  useEffect(() => {
    if (isCollapsed) {
      setAnimateCollapsed(isCollapsed);
    } else {
      setTimeout(() => {
        setAnimateCollapsed(isCollapsed);
      }, 100);
    }
  }, [isCollapsed]);

  const onCollapse = () => {
    dispatch(toggleSidebarVisible(!isCollapsed));
  };

  return {
    animateCollapsed,
    collapsed: isCollapsed,
    onCollapse,
  };
};

import LogoIcon0 from 'assets/images/Logo0.svg';
import LogoIcon1 from 'assets/images/Logo1.svg';
import LogoIcon2 from 'assets/images/Logo2.svg';
import LogoUniqitLarge from 'assets/images/uniqit-large-logo.svg';
import LogoUniqitSmall from 'assets/images/uniqit-small-logo.svg';

export const logotypes = [
  {
    index: 0, // Fintegrity 2.0
    smallLogo: LogoIcon0,
    largeLogo: LogoIcon0,
  },
  {
    index: 1, // Fintegrity
    smallLogo: LogoIcon1,
    largeLogo: LogoIcon1,
  },
  {
    index: 2, // Pintegrity
    smallLogo: LogoIcon2,
    largeLogo: LogoIcon2,
  },
  {
    index: 3, // Uniqit
    smallLogo: LogoUniqitSmall,
    largeLogo: LogoUniqitLarge,
  },
];

import { CurrencyEnum } from 'types/Currency';

export const generateBlockchainScanLink = (network: string, txId: string): string => {
  switch (network.toUpperCase()) {
    case 'ETH':
    case 'ERC20':
    case 'USDT': // Hardcode for processing
    case 'USDC': // Hardcode for processing
      return `${process.env.REACT_APP_ETHER_SCAN_URL}/tx/${txId}`;

    case 'BEP20':
    case 'BNB':
      return `${process.env.REACT_APP_BNB_SCAN_URL}/tx/${txId}`;

    case 'TRON':
    case 'TRC20':
    case 'TRX': // Hardcode for processing
    case 'USDT TRC20': // Hardcode for processing
      return `${process.env.REACT_APP_TRON_SCAN_URL}/#/transaction/${txId}`;
    case 'BTC':
    case 'BITCOIN':
      return `${process.env.REACT_APP_BTC_SCAN_URL}/tx/${txId}`;
    default:
      return '/';
  }
};

export const generateWalletLink = (
  currency: CurrencyEnum,
  walletAddress: string,
  network: 'ERC20' | 'TRC20',
) => {
  let link = '';

  switch (currency) {
    case 'ETH':
    case 'USDC':
      link = `${process.env.REACT_APP_ETHER_SCAN_URL}/address/${walletAddress}`;
      break;
    case 'TRX':
      link = `${process.env.REACT_APP_TRON_SCAN_URL}/#/address/${walletAddress}`;
      break;
    case 'BTC':
      link = `${process.env.REACT_APP_BTC_SCAN_URL}/address/${walletAddress}`;
      break;
    default:
      if (currency.includes('USDT')) {
        if (network === 'ERC20') {
          link = `${process.env.REACT_APP_ETHER_SCAN_URL}/address/${walletAddress}`;
        } else if (network === 'TRC20') {
          link = `${process.env.REACT_APP_TRON_SCAN_URL}/#/address/${walletAddress}`;
        }
      }
      break;
  }

  return link;
};

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { useTranslate } from 'hooks';
import { useUpliftReplenishmentRequest } from './hooks/useUpliftReplenishmentRequest';
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as UpliftReplenishmentRequestIcon } from 'assets/icons/LiftReplenishmentRequest.svg';
import styles from './UpliftRequestButton.module.scss';

type UpliftRequestButtonProps = {
  id: number;
  uuid: string,
  onRefresh: () => void;
};

const UpliftRequestIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UpliftReplenishmentRequestIcon} {...props} />
);

export const UpliftRequestButton = ({ id, uuid, onRefresh }: UpliftRequestButtonProps) => {
  const { t } = useTranslate();

  const { mutationUpliftReplenishmentRequest, isReplenishmentRequestFetching } =
    useUpliftReplenishmentRequest({ onRefresh });

  return (
    <Tooltip
      placement="left"
      title={t('users.replenishmentRequests.upliftRequest')}
    >
      <Button
        className={styles.button}
        onClick={() => mutationUpliftReplenishmentRequest({ id, uuid })}
        loading={isReplenishmentRequestFetching}
        icon={<UpliftRequestIcon className={styles.icon} />}
      />
    </Tooltip>
  );
};

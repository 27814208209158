import React, { ReactElement, useCallback } from 'react';
import { Select } from 'antd';
import { FilterInputProps } from '../types/FilterInputProps';
import { SelectFilterFieldParams } from '../../../types/SelectFilterFieldParams';
import styles from '../FilterInput.module.scss';

type ParamsFields = 'label' | 'options';
export type SelectFilterInputProps = Pick<SelectFilterFieldParams, ParamsFields>
& FilterInputProps<string>;

const SelectFilterInput = ({
  label, value, onChange, deleteButton, options, mode,
}: SelectFilterInputProps): ReactElement | null => {

  const onSelect = useCallback((newValue: string) => {
    if (mode === 'multiple' && Array.isArray(newValue) && newValue.length === 0) {
      onChange('');
    } else {
      onChange(newValue);
    }
  }, [onChange, mode]);

  return (
    <Select
      mode={mode}
      value={value}
      placeholder={label}
      onChange={onSelect}
      className={styles.field}
      suffixIcon={deleteButton}
    >
      {options.map((option) => (
        <Select.Option key={option.label} value={option.value as string}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectFilterInput;

export const truncateDecimals = (number: number, digits = 2): number => {
  const truncatedNumber = number * 100;
  const numString = truncatedNumber.toString();
  const decimalIndex = numString.indexOf('.');

  if (decimalIndex !== -1) {
    const endIndex = decimalIndex + digits + 1;
    return parseFloat(numString.slice(0, endIndex));
  }

  return truncatedNumber;
};

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import io, { Socket } from 'socket.io-client';
import { useTranslate } from 'hooks';
import { Status } from 'hooks/useUpdateStatus';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';
import { AuthorisationContext } from '../AuthorisationContext';
import { SocketContext } from './SocketContext';

interface ISocketProviderProps {
  children: ReactElement;
}

const SocketProvider = ({ children }: ISocketProviderProps): ReactElement => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { logout, user, setUser } = useContext(AuthorisationContext);
  const { t } = useTranslate();

  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_NOTIFICATION_URI as string, {
      auth: {
        token: LocalStorage.get(LocalStorageKey.ACCESS_TOKEN) || '',
        app: 'fgty-admin',
      },
    });

    setSocket(socketIo);

    socketIo.on('update_current_user_status', () => {
      if (user) setUser({ ...user, status: Status.ONLINE });
    });

    socketIo.on('session_logout', () => {
      logout();

      notification.warning({
        message: t('myAccount.warning'),
        description: t('myAccount.disconnected'),
      });
    });

    return () => {
      socketIo.disconnect();
    };
  }, []); // eslint-disable-line

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

import React, { ReactElement } from 'react';
import { Checkbox, Select } from 'antd';
import clsx from 'clsx';
import { CustomExpiryTimeProps } from '../CustomExpiryTime/CustomExpiryTime';
import styles from './SelectWithCheckbox.module.scss';

export type TextWithCheckboxProps = { checked: boolean, label: string };
const TextWithCheckbox = ({ checked, label }: TextWithCheckboxProps) => {

  return (
    <div className={styles.customOption}>
      <Checkbox checked={checked} />
      <span>{label}</span>
    </div>
  );
};

export type SelectWithCheckboxProps = {
  options: { label: string, value: string, disabled?: boolean }[];
  placeholder: string;
  value?: string;
  defaultValue?: string;
  wrapperId?: string;
  className?: string;
  expandedContent?: React.ComponentType<CustomExpiryTimeProps>,
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export const SelectWithCheckbox = ({
  expandedContent: ExpandedContent,
  wrapperId = 'select',
  placeholder,
  options,
  className,
  onChange,
  value,
  defaultValue,
  disabled,
}: SelectWithCheckboxProps): ReactElement => {
  const customOptions = options.map(option => ({
    value: option.value,
    children: option.label,
    disabled: option.disabled,
    label: <TextWithCheckbox checked={value === option.value} label={option.label} />,
  }));

  return (
    <div id={wrapperId}>
      <Select
        value={value}
        options={customOptions}
        placeholder={placeholder}
        optionLabelProp="children"
        onChange={onChange}
        defaultValue={defaultValue}
        // onFocus={()=>setOpen(true)}
        // onBlur={()=>setOpen(false)}
        className={clsx(className, { [styles.expanded]: value?.includes('custom') })}
        popupClassName={styles.selectDropdown}
        getPopupContainer={() => document.getElementById(wrapperId) as HTMLElement}
        disabled={disabled}
      />

      {!!ExpandedContent && (
        <ExpandedContent value={value} onChange={onChange} />
      )}
    </div>
  );
};

import React from 'react';
import { format } from 'date-fns';
import styles from './DateTime.module.scss';

export const DateTime = ({ value }: { value: Date }) => {
  const date = format(new Date(value), 'dd.MM.yyyy');
  const time = format(new Date(value), 'HH:mm:ss');

  return (
    <div className={styles.wrapper}>
      <span className={styles.date}>{date}</span>
      <span className={styles.time}>{time}</span>
    </div>
  );
};

import { Filters, NotificationStatus, NotificationType } from 'components/Notifications/types';
import { NotificationParams } from 'hooks/useNotifications/useNotifications';
import { PerPageNumber } from 'components/Pagination';

type Params = Filters & {
  page: number;
  perPage: PerPageNumber,
};

const getNotificationParams = (params: Params): NotificationParams => {
  const getFilterByStatus = (status: NotificationStatus) => ({
    ...(status === NotificationStatus.ALL && { deletedAt: { is: 'null' } }),
    ...(status === NotificationStatus.READ && { deletedAt: { is: 'null' }, readAt: { isNot: 'null' } }),
    ...(status === NotificationStatus.UNREAD && { deletedAt: { is: 'null' }, readAt: { is: 'null' } }),
    ...(status === NotificationStatus.ARCHIVE && { deletedAt: { isNot: 'null' } }),
  });

  const getFilterByTypes = (types: NotificationType[]) => ({
    ...(!types.includes(NotificationType.ALL) && { type: { in: types } }),
  });

  return {
    paging: { limit: params.perPage, offset: (params.page - 1) * params.perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: {
      and: [{
        ...getFilterByStatus(params.status),
        ...getFilterByTypes(params.types),

        createdAt: {
          between: {
            lower: params.date[0].set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
            upper: params.date[1].set('h', 23).set('m', 59).set('s', 59).set('ms', 999).toISOString(),
          },
        },
      }],
    },
  };
};

export default getNotificationParams;

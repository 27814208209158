import React, { ReactElement, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Path } from 'routes/interfaces/Path';
import { AuthorisationContext } from 'contexts';

export const RequireAuth = ({ children }: { children: JSX.Element }): ReactElement => {
  const { token } = useContext(AuthorisationContext);

  if (!token) {
    return <Navigate to={Path.LOGIN} replace />;
  }

  return children;
};

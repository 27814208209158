import axios from 'libs/axios';
import { UseMutateFunction, useMutation, useQuery, UseQueryResult } from 'libs/reactQuery';
import { IUser } from 'interfaces/IUser';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';

type AuthMeReturned = UseQueryResult<IUser, unknown>;
type AuthMeOptionsType = {
  onSuccess: (data: IUser) => void;
  onError: (e: any) => void;
};

type LoginBody = { email?: string; password: string, otpCode?: string, fingerprint?: string };
export type LoginVariablesType = { passwordless?: boolean, body: LoginBody };
export type LoginData = {
  status?: 'OK',
  qrcode?: string,
  currentUser: IUser,
  accessToken: string;
  refreshToken: string;
};
type LoginOptionsType = {
  onSuccess?: (data: LoginData) => void;
  onError: (e: unknown) => void;
};
type LoginReturned = {
  loginMutate: UseMutateFunction<LoginData, unknown, LoginVariablesType, unknown>,
  isLoading: boolean
};
type LogoutReturned = {
  logoutMutate: UseMutateFunction<unknown, unknown, void, unknown>,
  isLoading: boolean
};

export const useAuthMe = (options: AuthMeOptionsType, enabled: boolean): AuthMeReturned => {
  const fetchUser = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_AUTH_URI}/auth/current-user`);
    return data;
  };

  return useQuery<IUser>({
    queryKey: ['currentUser'],
    queryFn: fetchUser,
    enabled,
    ...options,
  });
};

export const useLogin = (options: LoginOptionsType): LoginReturned => {
  const login = async ({ passwordless, body }: LoginVariablesType) => {
    if (passwordless) {
      const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URI}/auth/login/passwordless`, body);
      return data;
    } else {
      const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URI}/auth/login`, body);
      return data;
    }
  };

  const { mutate, isPending } = useMutation({ mutationFn: login, ...options });

  return {
    loginMutate: mutate,
    isLoading: isPending,
  };
};

export const useLogout = (options: LoginOptionsType): LogoutReturned => {
  const logout = async () => {
    const refreshToken = LocalStorage.get(LocalStorageKey.REFRESH_TOKEN);
    if (!refreshToken) {
      return;
    }
    const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URI}/auth/logout`, {}, {
      headers: { refreshToken },
    });
    return data;
  };

  const { mutate, isPending } = useMutation({ mutationFn: logout, ...options });

  return {
    logoutMutate: mutate,
    isLoading: isPending,
  };
};

import axios, { AxiosError } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { UsersSorting } from '../types';

type UsersParams = {
  filter: Filter<Record<string, unknown>> | any;
  paging?: { limit: number; offset?: number };
  sorting?: UsersSorting[];
};
type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

export const useUsers = (params: UsersParams, options?: OptionType) => {
  const getUsers = async () => {
    const { data } = await axios.get(process.env.REACT_APP_AUTH_URI + '/users', { params });
    return data;
  };

  return useQuery({
    queryKey: ['managers', params],
    queryFn: getUsers,
    ...options,
  });
};

import React, {
  ReactElement, ReactNode, useContext, useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover, Typography } from 'antd';
import clsx from 'clsx';
import { AppState } from '../../../../../../init/rootReducer';
import { AuthorisationContext } from '../../../../../../contexts';
import { IIconProps } from '../../../../icons/FolderIcon';
import styles from './MenuHead.module.scss';

export interface IMenuHeadProps {
  title: string;
  isActive?: boolean;
  children?: ReactNode;
  renderIcon: (props: IIconProps) => ReactElement;
}

const MenuHead = (props: IMenuHeadProps): ReactElement | null => {
  const {
    renderIcon, title, children,
  } = props;
  const location = useLocation();
  const { checkPermissions } = useContext(AuthorisationContext);
  const { isCollapsed } = useSelector((state: AppState) => state.sidebarReducer);

  const isActive = useMemo(() => {
    if (Array.isArray(children)) {
      return children.some((child) => location.pathname.includes(child.props.to));
    }

    return location.pathname.includes((children as ReactElement)?.props?.to);
  }, [children, location.pathname]);

  if (!children) {
    return null;
  }

  if (Array.isArray(children)) {
    const isPermitted = children
      .some((child) => (!child.props.permissions || child.props.permissions.length === 0)
        || (child.props.permissions && checkPermissions(child.props.permissions)));
    if (!isPermitted) {
      return null;
    }
  }

  const child = children as ReactElement;
  if (child?.props?.permissions && !checkPermissions(child.props.permissions)) {
    return null;
  }

  const contentJSX = (
    <div
      className={
        clsx(styles.container, {
          [styles.containerCollapsed]: isCollapsed,
          [styles.containerCollapsedActive]: isCollapsed && isActive,
        })
      }
    >
      {renderIcon({ isActive: isActive && isCollapsed })}
      {!isCollapsed
      && (
        <Typography.Title
          className={
            clsx(styles.title, styles.addMargin, {
              [styles.active]: isActive,
            })
          }
        >
          {title}
        </Typography.Title>
      )}
    </div>
  );

  if (isCollapsed) {
    return (
      <Popover
        content={children}
        title={title}
        placement="rightTop"
      >
        {contentJSX}
      </Popover>
    );
  }

  return (
    <>
      {contentJSX}
      {!isCollapsed && children}
    </>
  );
};

export default MenuHead;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { Permission } from '@fanckler/processing-auth';
import axios from 'libs/axios';
import { identify } from 'libs/hotjar';
import { queryClient } from 'libs/reactQuery';
import { notification } from 'antd';
import { get } from 'lodash';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { use2FA } from 'hooks';
import {
  useAuthMe,
  useExpiredSessionInterceptor,
  useFingerprint,
  useLogin,
  useLogout,
  useMatchMyRoute,
} from './hooks';
import { LoginData } from './hooks/useAuth';
import LocalStorage, { LocalStorageKey } from '../../utils/localStorage';
import { AuthorisationContext, IAuthorisationContext, LoginParams } from './AuthorisationContext';
import { PageLoader } from '../../components';

interface IAuthorisationProviderProps {
  children: ReactElement;
}

const AuthorisationProvider = ({ children }: IAuthorisationProviderProps): ReactElement => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const samlToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');
  const passwordless = searchParams.get('password');

  const [path, setPath] = useState<string | null>(null);
  const [token, setToken] = useState(LocalStorage.get(LocalStorageKey.ACCESS_TOKEN));
  const [initialLoading, setInitialLoading] = useState<boolean>(Boolean(samlToken || token));
  const [user, setUser] = useState<IUser | null>(null);

  const {
    qrcode,
    onError,
    onSuccess,
    stage,
    setStage,
    prevStage,
    setPrevStage,
  } = use2FA();

  const fingerprint = useFingerprint();

  const isLoginPage = !!(useMatch(Path.LOGIN));
  const isInvoicePage = !!(useMatch(Path.INVOICES_UUID));
  const isNotFetchCurrentUser = !isLoginPage || !isInvoicePage;

  // useRefreshTokenInterceptor(fingerprint, !isNotFetchCurrentUser);

  useEffect(() => {
    if (samlToken && refreshToken) {
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, samlToken);
      LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, refreshToken);
      setToken(samlToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samlToken]);

  const setUserData = (data: IUser | null) => {
    setUser(data);
    if (data) {
      identify({ userId: data.id.toString(), email: data.email, name: data.name });

      if ((window as UnknownType)?.fcWidget) {
        (window as UnknownType).fcWidget.user?.setFirstName(data?.name || '');
        (window as UnknownType).fcWidget.user?.setEmail(data?.email || '');
      }
    }
  };

  const checkPermissions = useCallback((permissions: Permission[]) => {
    if (!user || !user?.group?.isActive) {
      return false;
    }

    return user.group.permissions.some(
      ({ name }) => permissions.some((item) => item === name),
    );
  }, [user]);

  useMatchMyRoute({ user, setPath, checkPermissions });

  const { loginMutate, isLoading } = useLogin({
    onSuccess: (data: LoginData) => {
      onSuccess(data, () => {
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
        LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, data.refreshToken);
        LocalStorage.set(LocalStorageKey.LAST_ACTIVITY, Date.now().toString());
        LocalStorage.set(LocalStorageKey.BUSINESS_LOGO, data.currentUser.rootUnit?.logo || '');

        axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        setUserData(data.currentUser);
        setToken(data.accessToken);
      });
    },
    onError: (e: UnknownType) => {
      onError(e, () => {
        navigate(window.location.pathname);

        notification.error({
          description: e.response.data.message,
          message: e.response.statusText,
        });
      });
    },
  });

  useEffect(() => {
    if (passwordless) {
      loginMutate({
        passwordless: true,
        body: {
          password: passwordless,
        },
      });
    }
  }, [loginMutate, passwordless]);

  const { logoutMutate } = useLogout({
    onError: (e: UnknownType) => {
      notification.error({
        description: e.response.data.message,
        message: e.response.statusText,
      });
    },
  });

  const handleSuccessAuth = useCallback((data: IUser) => {
    setUserData(data);
    setInitialLoading(false);
    LocalStorage.set(LocalStorageKey.LAST_ACTIVITY, Date.now().toString());
    LocalStorage.set(LocalStorageKey.BUSINESS_LOGO, data.rootUnit?.logo || '');
  }, []);

  const handleErrorAuth = useCallback((e: UnknownType) => {
    notification.error({
      message: get(e, ['response', 'data', 'message']) || 'Unknown error',
      description: get(e, ['message']) || 'Unknown error',
    });
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.LAST_ACTIVITY);

    setToken(null);
    setPath(null);
    setInitialLoading(false);
    queryClient.clear();
  }, []);

  const { refetch: refreshUser } = useAuthMe({
    onSuccess: handleSuccessAuth,
    onError: handleErrorAuth,
  }, !!((initialLoading && token) || !isNotFetchCurrentUser));

  const login = useCallback(({ email, password, otpCode, withPasswordless }: LoginParams) => {
    loginMutate({
      passwordless: withPasswordless,
      body: {
        email,
        password,
        fingerprint,
        otpCode,
      },
    });
  }, [fingerprint, loginMutate]);

  const logout = async (options?: { message?: string }) => {
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.REFRESH_TOKEN);
    LocalStorage.remove(LocalStorageKey.LAST_ACTIVITY);
    axios.defaults.headers.Authorization = null;
    logoutMutate();
    setUser(null);
    setPath(null);
    setToken(null);
    queryClient.clear();

    if (options?.message) {
      notification.close('sessionExpiredNotify');
      notification.warn({ message: options?.message, key: 'sessionExpiredNotify' });
    }
  };

  useExpiredSessionInterceptor(logout);

  const contextData: IAuthorisationContext = {
    user,
    setUser,
    path,
    token,
    login,
    logout,
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
    },
    passwordless,
    fingerprint,
    refreshUser,
    checkPermissions,
    loading: isLoading || initialLoading,
  };

  return (
    <AuthorisationContext.Provider value={contextData}>
      {initialLoading ? <PageLoader /> : children}
    </AuthorisationContext.Provider>
  );
};

export default AuthorisationProvider;

import React, { ReactElement, useCallback } from 'react';
import { Checkbox, Input, Radio, Space, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import clsx, { ClassValue } from 'clsx';
import { useTranslate } from '../../hooks';
import { ModalLoader } from '..';
import { DownOutlined } from '@ant-design/icons';
import styles from './AutocompleteSelect.module.scss';

type Item = {
  id: number;
  label: string;
  disabled?: boolean;
};

export interface AutocompleteSelectProps {
  items: Item[];
  loading: boolean;
  searchText: string;
  buttonLabel: string;
  inputPlaceholder: string;
  selectedItems: Array<{ id: number } | number>;
  onSearchTextChanged: (value: string) => void;
  onItemSelect: (id: number, checked: boolean) => void;
  type?: 'radio' | 'checkbox';
  classButton?: ClassValue;
  classLabel?: ClassValue;
}

const AutocompleteSelect = ({
  items,
  loading,
  searchText,
  buttonLabel,
  onItemSelect,
  selectedItems,
  inputPlaceholder,
  type = 'checkbox',
  onSearchTextChanged,
  classButton, classLabel,
}: AutocompleteSelectProps): ReactElement => {
  const { t } = useTranslate();

  const onInputChange = useCallback((event: { target: { value: string } }) => {
    onSearchTextChanged(event.target.value);
  }, [onSearchTextChanged]);

  const onSelect = useCallback((id: number) => (event: CheckboxChangeEvent) => {
    onItemSelect(id, event.target.checked);
  }, [onItemSelect]);

  const renderBody = useCallback(() => (
    <Space direction="vertical" size={20} >
      <Input
        autoFocus
        value={searchText}
        onChange={onInputChange}
        placeholder={inputPlaceholder}
      />
      {loading ? <ModalLoader size="small" /> : (
        <Space direction="vertical" size={14} className={styles.bodyWrapper}>
          {items.map((item) => {
            const checked = Boolean(selectedItems.find((element) => {
              if (typeof element === 'number') {
                return item.id === element;
              } else {
                return item.id === element.id;
              }
            }));

            const props = {
              key: item.id,
              checked: checked,
              disabled: item.disabled,
              onChange: onSelect(item.id),
              className: clsx({ [styles.checked]: checked }),
            };

            return type === 'checkbox'
              ? <Checkbox {...props}>{item.label}</Checkbox>
              : <Radio {...props}>{item.label}</Radio>;
          })}
        </Space>
      )}
      {!loading && !items.length && (
        <Typography.Text type="secondary">{t('nothingFound')}</Typography.Text>
      )}
    </Space>
  ), [
    inputPlaceholder,
    onInputChange,
    selectedItems,
    searchText,
    onSelect,
    loading,
    items,
    type,
    t,
  ]);

  return (
    <Tooltip
      color="white"
      trigger="click"
      placement="bottomLeft"
      title={renderBody()}
      overlayClassName={styles.tooltip}
    >
      <div className={clsx(styles.button, classButton)}>
        <span className={clsx(styles.label, classLabel)}>{buttonLabel}</span>
        <DownOutlined className={styles.arrow} />
      </div>
    </Tooltip>
  );
};

export default AutocompleteSelect;

import React, { CSSProperties, ReactElement } from 'react';
import { Image } from 'antd';
import clsx from 'clsx';
import { useLogo } from './hooks';
import { getLogoByIndex } from './utils';
import styles from './Logo.module.scss';

export type LogoProps = {
  smallIcon?: boolean;
  collapsed?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const Logo = ({ collapsed, smallIcon, className }: LogoProps): ReactElement => {
  const defaultLogo = getLogoByIndex(Number(process.env.REACT_APP_CLIENT), Boolean(smallIcon));
  const logo = useLogo({ defaultLogo });

  return (
    <div className={styles.logoWrapper}>
      <Image
        src={logo}
        preview={false}
        className={clsx(styles.logo, className, { [styles.small]: collapsed })}
      />
      {/* <LogoIcon className={clsx(styles.logo, className, { [styles.small]: collapsed })} /> */}
    </div>
  );
};

import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';

type TranGroupsParams = {
  filter?: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: { field: string, direction: string }[];
};

export const useTranGroups = (
  unitId?: string,
  params?: TranGroupsParams,
) => {
  const getGroups = async () => {
    const url = `${process.env.REACT_APP_CLIENT_HOST}/transaction-categories/group`;
    const { data } = await axios.get(unitId ? url + `?unitId=${unitId}` : url, { params });
    return data;
  };

  return useQuery({
    queryFn: getGroups,
    queryKey: ['transaction-categories', unitId || ''],
  });
};

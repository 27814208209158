import React, { ReactElement } from 'react';
import { Space } from 'antd';
import { IExternalUser, IInvoice, IUnit } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { useTranslate } from 'hooks';
import { TranslateFunc } from 'contexts/TranslateContext/TranslateContext';
import { RelationLink } from 'components';
import { EyeOutlined } from '@ant-design/icons';

export type OwnerIdTdPropsType = {
  client: IExternalUser | null;
  invoice: IInvoice | null;
  unit: IUnit | null;
};

export const OwnerIdTd = ({
  invoice,
  client,
  unit,
}: OwnerIdTdPropsType): ReactElement => {
  const { t } = useTranslate();

  const customerEmailTdJSX = invoice?.customerEmail && (
    <RelationLink path={Path.USERS_INVOICES} filters={{ 'customerEmail': invoice.customerEmail }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <EyeOutlined style={{ fontSize: '17px' }} /> {invoice.customerEmail}
      </Space.Compact>
    </RelationLink>
  );

  const invoiceTdJSX = invoice?.id && (
    <RelationLink path={Path.USERS_INVOICES} filters={{ 'id': invoice.id }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <EyeOutlined style={{ fontSize: '17px' }} /> {`${t('invoice.one')}-${invoice.id}`}
      </Space.Compact>
    </RelationLink>
  );

  const clientTdJSX = client?.clientId && client?.uuid && (
    <RelationLink path={`${Path.USERS_EMPLOYEES_CLIENTS}/${client?.uuid}`} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <EyeOutlined style={{ fontSize: '17px' }} /> {`${t('users.externalUsers.client')}: ${client.name || client.email || client.id}`}
      </Space.Compact>
    </RelationLink>
  );

  const unitTdJSX = unit && (
    <RelationLink path={Path.USERS_TRANSACTIONS} filters={{ 'unit.id': unit.id }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <EyeOutlined style={{ fontSize: '17px' }} /> {`${t('users.units.one')}: ${unit.name || unit.id}`}
      </Space.Compact>
    </RelationLink>
  );

  return <>{(customerEmailTdJSX || invoiceTdJSX) || clientTdJSX || unitTdJSX}</>;
};

export const OwnerIdTdLikeString = ({ invoice, client, unit, t }: OwnerIdTdPropsType & { t: TranslateFunc }) => {

  const customerEmailTdJSX = invoice?.customerEmail;
  const invoiceTdJSX = invoice?.id && `${t('invoice.one')}-${invoice.id}`;
  const clientTdJSX = client?.clientId && client?.uuid && `${t('users.externalUsers.client')}: ${client.name || client.email || client.id}`;
  const unitTdJSX = unit && `${t('users.units.one')}: ${unit.name || unit.id}`;

  return (customerEmailTdJSX || invoiceTdJSX) || clientTdJSX || unitTdJSX;
};

import React, { ReactElement } from 'react';
import { Modal as AntModal } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';

export type ModalType = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement | ReactElement[];
  title?: null | string | ReactElement;
  titleIcon?: null | ReactElement;
  width?: number;
  destroyOnClose?: boolean;
  className?: string;
  afterClose?: () => void;
};
const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  titleIcon,
  width,
  destroyOnClose,
  className,
  afterClose,
}: ModalType): ReactElement => {
  const titleJSX = (
    <>
      {titleIcon && (
        <span className={styles.icon}>
          {titleIcon}
        </span>
      )}
      {title}
    </>
  );

  return (
    <AntModal
      title={titleJSX}
      centered
      open={isOpen}
      onCancel={onClose}
      afterClose={afterClose}
      width={width}
      className={clsx(styles.modal, className)}
      footer={false}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </AntModal>
  );
};
Modal.defaultProps = {
  title: undefined,
  titleIcon: undefined,
  width: undefined,
  destroyOnClose: false,
};

export default Modal;

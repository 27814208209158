import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { Button } from 'antd';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { TranslateContext } from '../../contexts';
import { Error } from '../Error';

export type ErrorBoundaryProps = {
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, unknown> {
  reloadPage = (): void => window.location.reload();

  onBackHomeClick = (resetError: () => void): () => void => () => {
    resetError();
    window.location.replace('/');
  };

  renderExtraButtons = ({ resetError }: { resetError: () => void }): ReactNode => (
    <TranslateContext.Consumer>
      {({ translate }) => (
        <>
          <Button type="primary" onClick={this.onBackHomeClick(resetError)}>{translate('backToMainPage')}</Button>
          <Button type="default" onClick={this.reloadPage}>{translate('reloadPage')}</Button>
        </>
      )}
    </TranslateContext.Consumer>
  );

  renderFallback: FallbackRender = ({ error, resetError }) => (
    <Error
      error={error}
      extra={this.renderExtraButtons({ resetError })}
    />
  );

  render(): ReactNode {
    const { children } = this.props;

    return (
      <Sentry.ErrorBoundary fallback={this.renderFallback}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;

import React, { ReactElement } from 'react';
import { POFileType } from '../../hooks/types';
import { makeKeyItemPO } from 'components/UploadPOForm/utils';
import { PaymentOrderItem } from './components/PaymentOrderItem';
import styles from './index.module.scss';

type PaymentOrderListType = {
  payments: POFileType[];
  onDrop: (key: string) => void;
};

export const PaymentOrderList = ({ payments, onDrop }: PaymentOrderListType): ReactElement => (
  <div className={styles.tasks}>
    {payments.map((item) => (
      <PaymentOrderItem
        item={item}
        key={makeKeyItemPO(item)}
        onDrop={()=>onDrop(makeKeyItemPO(item))}
      />
    ))}
  </div>
);

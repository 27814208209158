import {
  ADD_REQUEST_TO_APPROVE,
  ADD_REQUEST_TO_APPROVE_MASS,
  AddRequestToApproveMassType,
  AddRequestToApproveType,
  REMOVE_REQUEST_FROM_APPROVE,
  RemoveRequestFromApproveType,
} from '../types/Actions';

export const addRequestToApprove = (
  payload: number,
): AddRequestToApproveType => ({
  type: ADD_REQUEST_TO_APPROVE,
  payload,
});

export const addRequestToApproveMass = (
  payload: number[],
): AddRequestToApproveMassType => ({
  type: ADD_REQUEST_TO_APPROVE_MASS,
  payload,
});

export const removeRequestFromApprove = (
  payload: number,
): RemoveRequestFromApproveType => ({
  type: REMOVE_REQUEST_FROM_APPROVE,
  payload,
});

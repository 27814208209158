import axios from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Filter } from 'types/Filter';
import { UnitSorting } from '../types';

type UnitsParams = {
  filter?: Filter<Record<string, unknown>> | any;
  paging?: { limit: number; offset: number };
  sorting?: UnitSorting[];
  all?: boolean;
};

export const useUnits = (params: UnitsParams, uuid?: string) => {
  const getUnits = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/units`, {
      params,
    });
    return data;
  };

  const getUnit = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENT_HOST}/units/${uuid}`);
    return data;
  };

  const {
    data: units,
    refetch: refetchUnits,
    isLoading: isLoadingUnits,
  } = useQuery({
    queryFn: getUnits,
    queryKey: ['units', params || ''],
    enabled: !uuid,
  });

  const {
    data: unit,
    refetch: refetchUnit,
    isLoading: isLoadingUnit,
  } = useQuery({
    queryFn: getUnit,
    queryKey: ['unit', uuid || ''],
    refetchOnMount: true,
    enabled: !!uuid,
  });

  return uuid ? {
    data: unit,
    refetch: refetchUnit,
    isLoading: isLoadingUnit,
  } : {
    data: units,
    refetch: refetchUnits,
    isLoading: isLoadingUnits,
  };
};

import React, { useMemo } from 'react';
import { Space } from 'antd';
import { CurrencyBadge } from 'components/CurrencyBadge';
import styles from './CurrencyNetworkTd.module.scss';

export type CurrencyNetworkTdProps = {
  currency: string;
  network: string;
  textWeight?: number;
};

const STABLE_COINS = ['USDT', 'USDC', 'BNB', 'BUSD', 'DAI', 'ETH', 'BTC'];

export const CurrencyNetworkTd = ({ currency, network, textWeight }: CurrencyNetworkTdProps) => {
  const isStableCoin = useMemo(() => STABLE_COINS.includes(currency), [currency]);
  return (
    <Space.Compact className={styles.wrapper}>
      <CurrencyBadge currency={currency} textWeight={textWeight} />
      {isStableCoin && <span className={styles.network}>({network?.toUpperCase()})</span>}
    </Space.Compact>
  );
};

export const getPayway = (currency: string, network: string) => {
  if (currency === 'TRX' && network === 'TRC') {
    return 'TRX';
  }

  if (currency === 'ETH' && network === 'ERC20') {
    return 'ETH';
  }

  if (currency === 'BTC' && network === 'Bitcoin') {
    return 'BTC';
  }

  return network;
};

import en_GB from 'antd/lib/locale/en_GB';
import uk_UA from 'antd/lib/locale/uk_UA';
import enLocale from 'date-fns/locale/en-US';
import ukLocale from 'date-fns/locale/uk';
import type { ILocaleSettings } from './interfaces/ILocaleSettings';
import { Locale } from './interfaces/Locale';
import 'moment/locale/uk';
import 'moment/locale/en-gb';
import enImage from '../../assets/images/countries/en.png';
import uaImage from '../../assets/images/countries/ua.png';

// Flags images: https://icons8.ru/icon/set/%D1%84%D0%BB%D0%B0%D0%B3%D0%B8/color

export type ILocales = { [K in Locale]: ILocaleSettings };

const locales: ILocales = {
  [Locale.UA]: {
    antdLocale: uk_UA,
    dateFnsLocale: ukLocale,
    image: uaImage,
  },
  [Locale.EN]: {
    antdLocale: en_GB,
    dateFnsLocale: enLocale,
    image: enImage,
  },
};

export default locales;

import { ResultStatusType } from 'antd/es/result';
import type { TranslateFunc } from '../../../contexts/TranslateContext/TranslateContext';

export interface IGetDefaultErrorInfoResult {
  status: ResultStatusType;
  title: string;
  subTitle: string;
}

export default (
  statusCode: ResultStatusType,
  translate: TranslateFunc,
): IGetDefaultErrorInfoResult => {
  switch (statusCode) {
    case 403:
    case '403': {
      return {
        status: '403',
        title: `(403) ${translate('forbidden')}`,
        subTitle: translate('accessToRequestedResourceIsDenied'),
      };
    }
    case 404:
    case '404': {
      return {
        status: '404',
        title: `(404) ${translate('notFound')}`,
        subTitle: translate('requestedResourceNotFound'),
      };
    }
    case 500:
    case '500': {
      return {
        status: '500',
        title: `(500) ${translate('internalServerError')}`,
        subTitle: translate('tryAgainLater'),
      };
    }
    default: {
      return {
        status: 'error',
        title: translate('somethingWrong'),
        subTitle: translate('tryAgainLater'),
      };
    }
  }
};

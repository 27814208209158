import { Font, StyleSheet } from '@react-pdf/renderer';
import InterBlack from 'assets/fonts/inter/Inter-Black.ttf';
import InterBold from 'assets/fonts/inter/Inter-Bold.ttf';
import InterRegular from 'assets/fonts/inter/Inter-Regular.ttf';
import InterSemiBold from 'assets/fonts/inter/Inter-SemiBold.ttf';

Font.register({
  family: 'Inter',
  fonts: [
    { fontWeight: 'normal', src: InterRegular },
    { fontWeight: 'semibold', src: InterSemiBold },
    { fontWeight: 'bold', src: InterBold },
    { fontWeight: 'ultrabold', src: InterBlack },
  ],
});

const header = StyleSheet.create({
  header: {
    gap: 16,
    padding: '20px 50px',
    backgroundColor: '#F6FBFF',
    borderBottom: '1px solid #D7DAE0',
  },
  titleWithLogo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#1A1C21',
    fontWeight: 'semibold',
    fontSize: 28,
  },
  logo: {
    width: 26.37,
    height: 26.37,
  },
  dateWrapper: {
    flexDirection: 'column',
    gap: 5,
  },
  dateTitle: {
    fontSize: 10,
    fontWeight: 'semibold',
  },
  dateValueWrapper: {
    flexDirection: 'row',
    gap: 3,
    fontSize: 10,
  },
  date: {
    fontWeight: 'semibold',
  },
  time: {
    color: '#5E6470',
  },
});

const body = StyleSheet.create({
  body: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
    padding: '40px 50px',
  },
  amountWrapper: {
    flexDirection: 'column',
  },
  amountTitle: {
    fontSize: 10,
  },
  amountValueWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  amountValue: {
    fontSize: 24,
    fontWeight: 'semibold',
  },
  currencyImage: {
    width: 18,
    height: 18,
  },
  currencyTicker: {
    fontSize: 16,
    fontWeight: 'semibold',
    color: '#333333',
  },
});

const table = StyleSheet.create({
  table: {
    fontSize: 10,

    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'semibold',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D7DAE0',
  },
  lastRow: {
    border: 'none',
  },
  idWithLabelCol: {
    flexDirection: 'row',
  },
  idCol: {
    padding: '16px 8px 8px 0px',
  },
  labelCol: {
    padding: '16px 8px 8px 8px',
  },
  valueCol: {
    color: '#5E6470',
    padding: '16px 0px 8px 8px',
  },
  availableAmountWrapper: {
    display: 'flex',
  },
  availableAmountValue: {
    display: 'flex',
  },
  availableAmountCurrencyIcon: {
    display: 'flex',

    width: 12,
    height: 12,
    paddingTop: 28,
  },
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Inter',
    lineHeight: 'normal',
    backgroundColor: '#FFFFFF',
  },
  ...header,
  ...body,
  ...table,
});

export default styles;

export const ADD_REQUEST_TO_APPROVE = 'ADD_REQUEST_TO_APPROVE';
export type AddRequestToApproveType = {
  type: typeof ADD_REQUEST_TO_APPROVE;
  payload: number;
};

export const ADD_REQUEST_TO_APPROVE_MASS = 'ADD_REQUEST_TO_APPROVE_MASS';
export type AddRequestToApproveMassType = {
  type: typeof ADD_REQUEST_TO_APPROVE_MASS;
  payload: number[];
};

export const REMOVE_REQUEST_FROM_APPROVE = 'REMOVE_REQUEST_FROM_APPROVE';
export type RemoveRequestFromApproveType = {
  type: typeof REMOVE_REQUEST_FROM_APPROVE;
  payload: number;
};

export type RequestActionType = AddRequestToApproveType | AddRequestToApproveMassType | RemoveRequestFromApproveType;

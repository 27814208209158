import axios, { AxiosError } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';

export type OptionType = {
  onSuccess?: (e: any, variables?: any) => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>, variables?: any) => void;
};

type CreateVariablesType = { body: any, type?: string };
type UpdateVariablesType = {
  body: any, id: unknown, name?: string, type?: string,
};
type RemoveVariablesType = {
  id: number, name?: string, type?: string,
};
type FreezeVariablesType = {
  id: unknown, isActive: boolean, name?: string, type?: string,
};

const useUnitMutations = (options: OptionType) => {
  const UNIT_URL = `${process.env.REACT_APP_CLIENT_HOST}/units`;

  const create = async ({ body }: CreateVariablesType) => {
    const { data } = await axios.post(`${UNIT_URL}/create`, body);
    return data;
  };

  const update = async ({ body, id }: UpdateVariablesType) => {
    const { data } = await axios.put(`${UNIT_URL}/${id}`, body);
    return data;
  };

  const remove = async ({ id }: RemoveVariablesType) => {
    const { data } = await axios.delete(`${UNIT_URL}/${id}`);
    return data;
  };

  const freeze = async ({ id, isActive }: FreezeVariablesType) => {
    const { data } = await axios.put(`${UNIT_URL}/${id}`, { isActive });
    return data;
  };

  const { mutate: createUnit, isPending: isCreating } = useMutation({ mutationFn: create, ...options });
  const { mutate: updateUnit, isPending: isUpdating } = useMutation({ mutationFn: update, ...options });
  const { mutate: removeUnit, isPending: isRemoving } = useMutation({ mutationFn: remove, ...options });
  const { mutate: freezeUnit, isPending: isFreezing } = useMutation({ mutationFn: freeze, ...options });

  return {
    createUnit, isCreating,
    updateUnit, isUpdating,
    removeUnit, isRemoving,
    freezeUnit, isFreezing,
  };
};

export default useUnitMutations;

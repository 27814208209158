import { SIDEBAR_VISIBLE, SidebarActionType } from './types/Actions';
import { SidebarType } from './types/Reducer';

const initialState: SidebarType = {
  isCollapsed: false,
};

export const sidebarReducer = (
  state = initialState,
  action: SidebarActionType,
): SidebarType => {
  if (!('type' in action)) {
    return state;
  }

  switch (action.type) {
    case SIDEBAR_VISIBLE:
      return {
        ...state,
        isCollapsed: action.payload,
      };
    default:
      return state;
  }
};

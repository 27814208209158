import axios, { AxiosError } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { SortOrder } from 'antd/es/table/interface';
import { IBusiness } from 'interfaces/IBusiness';
import { Filter } from 'types/Filter';
import { BusinessResponseType } from '../types';
import { Sort } from 'hooks';
import { adaptSortOrder } from 'utils';
import { BusinessSortField, BusinessSorting } from '../Businesses';

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

const adaptSort = (
  sortKey: BusinessSortField | string, sortOrder: SortOrder,
): BusinessSorting[] => {
  return [{
    field: sortKey as BusinessSortField,
    direction: adaptSortOrder(sortOrder),
  }];
};

export interface UseBusinessesProps {
  sort?: Sort<BusinessSortField> | null;
  filter?: Filter<IBusiness>;
  withoutPaging?: boolean;
  enabled?: boolean;
  perPage?: number;
  page?: number;
  options?: OptionType;
}

export const useBusinesses = ({
  sort = { field: 'updatedAt', order: 'descend' },
  withoutPaging,
  perPage = 25,
  page = 1,
  enabled,
  filter,
  options,
}: UseBusinessesProps) => {
  const adaptedSort = { sorting: adaptSort(sort?.field || 'updatedAt', 'descend') };

  const getBusinesses = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_AUTH_URI}/root-units`, {
      params: withoutPaging ? {
        ...adaptedSort,
        filter,
      } : {
        paging: { limit: perPage, offset: (page - 1) * perPage },
        ...adaptedSort,
        filter,
      },
    });
    return data;
  };

  return useQuery<BusinessResponseType>({
    queryKey: ['businesses', filter],
    queryFn: getBusinesses,
    enabled,
    ...options,
  });
};

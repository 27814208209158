import axios from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { CreateInvoiceBodyType, OptionType } from './types';

export const useInvoiceMutation = (options?: OptionType) => {
  const createMutation = async (body: CreateInvoiceBodyType) => {
    const { data } = await axios.post(`${process.env.REACT_APP_CLIENT_HOST}/invoices/create`, body);
    return data;
  };

  const {
    mutate: create,
    isPending: isCreateLoading,
  } = useMutation({ mutationFn: createMutation, ...options });

  return {
    create,
    isCreateLoading,
  };
};

export const SET_UNIT_PAGE_TITLE = 'SET_UNIT_PAGE_TITLE';
export const SET_REPLENISHMENT_REQUEST = 'SET_REPLENISHMENT_REQUEST';
export const SET_ROOT_UNIT_ID = 'SET_ROOT_UNIT_ID';

export type SetUnitPageTitle = {
  type: typeof SET_UNIT_PAGE_TITLE;
  payload: string | null;
};

export type SetReplenishmentRequest = {
  type: typeof SET_REPLENISHMENT_REQUEST;
  payload: boolean
};

export type SetRootUnitId = {
  type: typeof SET_ROOT_UNIT_ID;
  payload: number | null;
};

export type UnitActionType = SetUnitPageTitle | SetReplenishmentRequest | SetRootUnitId;

import React, { ReactElement, useState } from 'react';
import { IWallet } from 'types/IWallet';
import { useCurrencyRate } from 'hooks';
import { unifyWalletsStructure } from 'utils';
import { CountUp, CurrencySelect } from 'components';
import styles from './BalanceTd.module.scss';

export type BalanceTdProps = {
  name: string;
  wallets: IWallet[];
};

export const currencyOptions = [
  { value: 'USDT', label: 'USDT' },
  { value: 'USDC', label: 'USDC' },
  // { value: 'BUSD', label: 'BUSD' },
  { value: 'BNB', label: 'BNB' },
  { value: 'DAI', label: 'DAI' },
  { value: 'TRX', label: 'TRX' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'UAH', label: 'UAH' },
  { value: 'GBP', label: 'GBP' },
  { value: 'ILS', label: 'ILS' },
  { value: 'AED', label: 'AED' },
];

export const BalanceTd = ({ name, wallets }: BalanceTdProps): ReactElement => {
  const { walletBalance } = useCurrencyRate();
  const [currency, setCurrency] = useState('USDT');
  const amount = Number(
    walletBalance(unifyWalletsStructure(wallets), currency),
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.name}>{name}</span>
      <div className={styles.balance}>
        <CountUp
          end={amount}
          currency={currency}
          className={styles.amount}
          isRoundDown
        />
        <CurrencySelect
          trimHeight
          wrapperWidth={100}
          currency={currency}
          onSelect={setCurrency}
          options={currencyOptions}
          withoutPopupContainer
        />
      </div>
    </div>
  );
};

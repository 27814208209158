import { useEffect, useState } from 'react';
import useCheckImage, { ImageStatusEnum } from '../../../hooks/useCheckImage';
import { LocalStorage, LocalStorageKey } from 'utils';

export type UseLogoParams = {
  defaultLogo: string;
};

const useLogo = ({ defaultLogo }: UseLogoParams) => {
  const localLogo = LocalStorage.get(LocalStorageKey.BUSINESS_LOGO);

  const [logo, setLogo] = useState(localLogo ? `${process.env.REACT_APP_AUTH_URI}${localLogo}` : defaultLogo);

  const status = useCheckImage({ src: localLogo || '' });

  useEffect(() => {
    if (status === ImageStatusEnum.ERROR) {
      setLogo(defaultLogo);
    }
  }, [defaultLogo, logo, status]);

  return logo as string;
};

export default useLogo;

import React, { ReactElement, useContext, useMemo } from 'react';
import { Tooltip } from 'antd';
import { Locale } from 'contexts/TranslateContext/interfaces/Locale';
import { TranslateContext } from 'contexts';
import { LocaleItem } from './components';
import styles from './LocaleDropdown.module.scss';

const LocaleDropdown = (): ReactElement => {
  const { locales, changeLocale, localeSettings } = useContext(TranslateContext);

  const options = useMemo(() => (
    <div className={styles.container}>
      {Object.entries(locales).map(([locale, { image }]) => (
        <LocaleItem
          withPaddings
          key={locale}
          image={image}
          locale={locale}
          onClick={() => changeLocale(locale as Locale)}
          selected={localeSettings.locale === locale}
        />
      ))}
    </div>
  ), [changeLocale, localeSettings.locale, locales]);

  return (
    <Tooltip
      color="white"
      trigger="click"
      title={options}
      placement="bottomLeft"
      key={localeSettings.locale}
      overlayClassName={styles.tooltip}
    >
      <LocaleItem image={localeSettings.image} locale={localeSettings.locale} />
    </Tooltip>
  );
};

export default LocaleDropdown;

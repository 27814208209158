import { CurrencyEnum } from 'types/Currency';

const generateCurrencyUrl = (currency: CurrencyEnum | 'erc20' | 'trc20'): string => {
  switch (currency) {
    case 'erc20':
    case 'USDC':
    case CurrencyEnum.ETH:
    case CurrencyEnum.ERC20:
      return `${process.env.REACT_APP_ETH_HOST}`;
    case 'trc20':
    case CurrencyEnum.TRX:
    case CurrencyEnum.TRC20:
      return `${process.env.REACT_APP_TRON_HOST}`;
    case CurrencyEnum.BTC:
      return `${process.env.REACT_APP_BTC_HOST}`;
    case CurrencyEnum.BNB:
      return `${process.env.REACT_APP_BNB_HOST}`;
    default:
      return '';
  }
};

export default generateCurrencyUrl;

export enum InvoiceStatusEnum {
  AWAITING_PAYMENT = 'awaiting_payment',
  TRANSFERRED = 'transferred',
  PROCESSING = 'processing',
  IN_FINALIZATION = 'in_finalization',
  PARTIALLY_PAID = 'partially_paid',
  COMPLETED = 'completed',
  OVERPAID = 'overpaid',
  CLOSED = 'closed',
}
